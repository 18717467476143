import {useState} from 'react'
import {ErrorMessage, Form, Field, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import {useEventsStore} from '../core/eventsStore'
import * as Yup from 'yup'
import {KTIcon} from '../../../../_metronic/helpers'

import NumberInput from '../../../../_components/NumberInput'
import {useClientStore} from '../../clients/core/clientStore'
import ClientEditor from './ClientEventEditor'
import {pluralise} from '../../../../_helpers/_helpers'

// event details validation schema with Yup
const validationSchema = Yup.object({
  name: Yup.string().required().label('Event Name'),
  handle: Yup.string()
    .test('handle', 'only alphanumeric values and - allowed', (val) => {
      if (!val) return true
      const pattern = /^[a-zA-Z0-9-]+$/
      return pattern.test(val)
    })
    .label('Event Handle'),
  startDate: Yup.string().label('Start Date'),
  endDate: Yup.string().label('End Date'),
  location: Yup.string().label('Location'),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step: string
}

const BillingDetails = (props: Props) => {
  const {step} = props
  const {currentEvent, saveEvent} = useEventsStore()
  const {clientEvents} = useClientStore()

  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const [sellTickets, setSellTickets] = useState(currentEvent.sellTickets)
  const [billingModel, setBillingModel] = useState(currentEvent.billingModel)

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Account successfully updated')

        // emit
        if (props.onSave) props.onSave(values)

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  const handleSellTickets = (e) => {
    setSellTickets(e.target.checked)
  }

  const handleBillingModel = (e) => {
    setBillingModel(e.target.value)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_event_details'
        aria-expanded='true'
        aria-controls='kt_event_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Billing Settings</h3>
        </div>
      </div>

      <div id='kt_event_details' className='collapse show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-9'>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                            <Field
                              className='form-check-input me-3'
                              type='checkbox'
                              name='sellTickets'
                              onClick={handleSellTickets}
                            />

                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder fs-5 text-dark mb-0'>
                                This event sells tickets at a fee
                              </span>
                              <span className='text-muted fs-6'>
                                Even if some tickets are complimentary, check this box if you intend
                                selling tickets at a fee
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='separator separator-dashed mb-10'></div>
                    <div className='fv-row mb-1'>
                      <div className='row'>
                        <label htmlFor='' className='form-label fw-bolder'>
                          Choose Billing Model
                        </label>
                        <span className='text-gray-400 fs-6 mb-5'>
                          <i className='fas fa-circle-info'>&nbsp;</i>
                          Choose the billing model most suitable for your event.
                        </span>
                      </div>
                    </div>
                    <div className='fv-row'>
                      <div className='row'>
                        <div className='col-lg-6 mb-3'>
                          <Field
                            type='radio'
                            className='btn-check'
                            name='billingModel'
                            value='self'
                            id='sb_billing_model_self'
                            onClick={handleBillingModel}
                          />
                          <label
                            className='btn btn-outline border-2 h-125px btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                            htmlFor='sb_billing_model_self'
                          >
                            <KTIcon iconName='user' iconType='outline' className='fs-3x me-5' />

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                Self Service
                              </span>
                              <span className='text-gray-400 fw-bold fs-6'>
                                Create event for yourself or your organisation
                              </span>
                            </span>
                          </label>
                        </div>

                        <div className='col-lg-6'>
                          <Field
                            type='radio'
                            className='btn-check'
                            name='billingModel'
                            value='managed'
                            id='sb_billing_model_managed'
                            onClick={handleBillingModel}
                          />
                          <label
                            className='btn btn-outline h-125px border-2 btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                            htmlFor='sb_billing_model_managed'
                          >
                            <KTIcon
                              iconName='briefcase'
                              iconType='outline'
                              className='fs-3x me-5'
                            />

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                Managed Service
                              </span>
                              <span className='text-muted fw-bold fs-6'>
                                Create event for a client or a third party.
                              </span>
                            </span>
                          </label>
                        </div>

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='billingModel' />
                        </div>
                      </div>
                    </div>

                    {/* client  */}
                    {billingModel === 'managed' && (
                      <>
                        <div className='fv-row mb-10'>
                          <div className='row'>
                            <div className='col-12'>
                              <ClientEditor />
                            </div>
                          </div>
                        </div>

                        {sellTickets && (
                          <div className='fv-row mb-10'>
                            <div className='row'>
                              <div className='col-12'>
                                <label className='form-label w-100 fw-bolder'>
                                  Your Management Fee (percentgage)
                                </label>

                                <NumberInput name='commission' min={1} max={100} />
                                <KTIcon iconName='information' iconType='solid' className='fs-6' />
                                <span className='text-gray-600 fs-6 mb-5 mt-2'>
                                  This is your management fee to be deducted from each ticket sold.
                                  Note it will be deducted over and above skybookings fees.
                                  Estimates will be provided in the Tickets & Payments section. Also
                                  note that your {pluralise(clientEvents.length, 'client')} will see
                                  this fee.
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {BillingDetails}
