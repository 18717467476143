import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../_metronic/layout/core'
import {PageDataModel, usePageDataStore} from './PageDataStore'
import {FC} from 'react'
import {generateRandomKey} from '../../_helpers/_helpers'

type Props = {
  breadcrumbs: PageDataModel[]
  className?: string
}

const Breadcrumbs: FC<Props> = ({breadcrumbs, className}) => {
  const {config, classes} = useLayout()
  const appPageTitleDirection = config.app?.pageTitle?.direction

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        'page-title d-flex flex-wrap me-3',
        classes.pageTitle.join(' '),
        config.app?.pageTitle?.class,
        {
          'flex-column justify-content-center': appPageTitleDirection === 'column',
          'align-items-center': appPageTitleDirection !== 'column',
        }
      )}
    >
      {breadcrumbs &&
        breadcrumbs.length > 0 &&
        config.app?.pageTitle &&
        config.app?.pageTitle?.breadCrumb && (
          <>
            {config.app?.pageTitle?.direction === 'row' && (
              <span className='h-20px border-gray-300 border-start mx-4'></span>
            )}
            <ul className={`breadcrumb breadcrumb-separator fw-semibold fs-7 my-0 ${className}`}>
              {Array.from(breadcrumbs).map((item, index) => (
                <li key={index} className={clsx('breadcrumb-item')}>
                  <Link className='text-muted text-hover-primary' to={item.link}>
                    {breadcrumbs.length - 1 > index ? (
                      <span className='text-dark text-hover-primary'>{item.object}</span>
                    ) : (
                      <span className='text-dark text-hover-primary'>{item.title}</span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
    </div>
  )
}

export {Breadcrumbs}
