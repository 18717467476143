import {FC, useEffect, useState} from 'react'
import {useEventsStore} from '../events/core/eventsStore'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {GuestModel, useGuestsStore} from './core/guestsStore'
import toast, {Toaster} from 'react-hot-toast'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import {
  daysUntilDate,
  formatMoney,
  formatPhoneNumber,
  partialHideEmail,
  trimCharacters,
} from '../../../_helpers/_helpers'
import clsx from 'clsx'
import GuestCheckInButton from './GuestLIstPartials/GuestCheckInButton'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {GuestListSearch} from './GuestLIstPartials/GuestListSearch'
import {ProgressBar} from 'react-bootstrap'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'

const GuestListPage: FC = () => {
  const {currentEvent, getEvent} = useEventsStore()
  const {eventId} = useParams<{eventId: string}>()
  const {alphabet} = useParams<{alphabet: string}>()
  const [eventLoading, setEventLoading] = useState<boolean>(true)
  const {
    searchGuests,
    getCheckInStats,
    checkInStats,
    alphabets,
    guests,
    resetGuests,
    getAlphabets,
    getByAlphabetLetter,
  } = useGuestsStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>('')
  const [checkpointFilter] = useState<string | undefined>()
  const [loadingCheckInStats, setLoadingCheckInStats] = useState<boolean>(true)

  const navigate = useNavigate()

  // SETUP PAGE
  useEffect(() => {
    if (!eventId) return

    // load checkin stats
    refreshCheckInStats()

    // setup rest of the page

    if (alphabet && !currentEvent.id) {
      // 1. get current event
      setEventLoading(true)
      setIsLoading(true)
      getEvent(eventId)
        .then(async () => {
          // 2. get guests by alphabet
          await getAlphabetGuests(alphabet)
        })
        .finally(() => {
          setEventLoading(false)
          setIsLoading(false)
        })
    } else if (!alphabet) {
      setIsLoading(true)
      resetGuests()
      getAlphabets(eventId).finally(() => {
        setIsLoading(false)
      })

      if (!currentEvent.id) {
        setEventLoading(true)
        getEvent(eventId).finally(() => {
          setEventLoading(false)
        })
      }
    }

    // eslint-disable-next-line
  }, [alphabet])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `${trimCharacters(currentEvent.name, 12)}`,
        title: `${trimCharacters(currentEvent.name, 12)}`,
        link: `/events/${eventId}`,
      },
      {
        object: 'Guest List',
        title: `Guest List`,
        link: `/events/${eventId}/guests`,
      },
    ]

    if (guests.length > 0) {
      crumbs.push({
        object: selectedAlphabet,
        title: selectedAlphabet,
        link: `/events/${eventId}/guests`,
      })
    }

    setBreadcrumbs(crumbs)
    setCurrentPage('Guest List')

    // eslint-disable-next-line
  }, [eventLoading, guests])

  const handleAlphabetClick = async (alphabet: string) => {
    getAlphabetGuests(alphabet)
  }

  const handleBackClick = () => {
    resetGuests()
    setSelectedAlphabet('')
    navigate(`/events/${eventId}/guests`)
  }

  const getAlphabetGuests = async (alphabet: string) => {
    if (!eventId) return
    try {
      setSelectedAlphabet(alphabet)
      setIsLoading(true)
      await getByAlphabetLetter(eventId, alphabet)
      navigate(`/events/${eventId}/guests/${alphabet}`)
      setIsLoading(false)
    } catch (error) {
      toast.error('There was a problem fetching guests')
      setIsLoading(false)
    }
  }

  const handleSearch = async (searchTerm: string) => {
    if (!eventId) return
    if (!searchTerm) return

    try {
      setIsLoading(true)
      setSelectedAlphabet(searchTerm)
      const response = await searchGuests(eventId, searchTerm)
      navigate(`/events/${eventId}/guests/${searchTerm}`)
      setIsLoading(false)

      if (response.length === 0) {
        toast.error(`No guests found under '${searchTerm}'`)
      }
    } catch (e) {
      toast.error('There was a problem fetching guests')
      setIsLoading(false)
    }
  }

  const handleSearchReset = () => {
    // reset search
    resetGuests()
    navigate(`/events/${eventId}/guests`)
  }

  const handleGuestCheckIn = (guest: GuestModel) => {
    // reload the guest bookings after checkin
    if (currentEvent.id) {
      getAlphabetGuests(selectedAlphabet)

      // refresh checkin stats
      refreshCheckInStats()
    }
  }

  const refreshCheckInStats = () => {
    if (!eventId) return

    setLoadingCheckInStats(true)
    getCheckInStats(eventId)
      .finally(() => {
        setLoadingCheckInStats(false)
      })
      .finally(() => {
        setLoadingCheckInStats(false)
      })
  }

  return (
    <>
      <Toaster />
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column align-items-center w-100'>
          <div className='d-flex justify-content-between mb-10 w-100'>
            <div className='d-flex flex-column align-items-center flex-grow-1'>
              <div className='d-flex flex-column align-items-center w-100 flex-grow-1'>
                <GuestListSearch onSearch={handleSearch} onResetSearch={handleSearchReset} />

                <div className='d-flex w-100 w-lg-450px mt-2 px-10'>
                  <ProgressBar
                    className='w-100 h-2px bg-secondary'
                    now={checkInStats.checkInPCT}
                    min={0}
                    max={100}
                    variant='success'
                  />
                </div>
                <div className='d-flex text-muted'>
                  {`${checkInStats.totalCheckedInTickets} of ${checkInStats.totalTickets} checked, ${checkInStats.checkInPCT}%`}
                </div>
              </div>

              <div className='d-flex mt-3 align-items-center justify-content-center flex-column'>
                {guests.length > 0 && (
                  <button className='btn btn-link btn-color-primary p-0' onClick={handleBackClick}>
                    <KTIcon iconName='left' className='me-2' iconType='outline' />
                    Back to Alphabets
                  </button>
                )}
              </div>
              {/* {!isMobileDevice && (
                <div className='d-flex flex-rows align-items-end'>
                  <GuestListFilter onFilter={handleFilter} isLoading={isLoading} />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* ALPHABETS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {guests.length === 0 ? (
            <>
              {alphabets.length > 0 ? (
                <>
                  <div className='d-flex justify-content-center flex-wrap'>
                    {alphabets.map((alphabet, index) => (
                      <div key={index} className='d-flex flex-row'>
                        <button
                          className='btn btn-secondary w-75px h-75px w-lg-100px h-lg-100px symbol fw-bolder symbol-circle border-1 fs-2x me-5 mb-5'
                          onClick={() => handleAlphabetClick(alphabet)}
                        >
                          {alphabet}
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {alphabets.length === 0 && (
                    <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
                      <div className='d-flex justify-content-center align-items-center flex-column'>
                        <div className='d-flex'>
                          <KTIcon
                            className='svg-icon-3x svg-icon-primary mb-5 '
                            iconName='flaticon-safebox-1'
                          />
                          <span className='fs-2x fw-bolder text-dark'>
                            Oh no! You do not have any guests at the moment.
                          </span>
                        </div>
                        <div className='fs-4 text-muted text-center'>
                          When patrons book your event, their names will be automatically added to
                          this guest list.
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {isMobileDevice() ? (
                <div className='d-flex flex-column align-items-start w-100'>
                  {guests.map((guest: GuestModel, index) => (
                    <>
                      {guest.tickets.map((ticket, index) => (
                        <div
                          className='w-100 card border-1 border border-secondary card-custom mb-5'
                          key={index}
                        >
                          {/* ALPHABET and name */}
                          <div className='card-header d-flex p-5'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px me-5'>
                                {/* first letter */}
                                <span
                                  className={clsx(`symbol-label text-white fw-bolder`, {
                                    'bg-success': ticket.status === 'active',
                                    'bg-danger': ticket.status === 'checked',
                                    'bg-secondary': ticket.status === 'pending',
                                    'bg-dark':
                                      ticket.status === 'cancelled' || ticket.status === 'locked',
                                  })}
                                >
                                  {guest.name.charAt(0)}
                                </span>
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary fs-3'>
                                  {trimCharacters(guest.name, 15)}
                                </span>
                                <div className='d-flex flex-column'>
                                  {guest.phone.toString().length > 5 ? (
                                    <span className='me-2'>
                                      {formatPhoneNumber(guest.phone, true)}
                                    </span>
                                  ) : (
                                    <span className='me-2'>{partialHideEmail(guest.email)}</span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* TICKET NAME */}
                            <div className='d-flex text-end min-w-lg-200px flex-column pt-4 mb-5'>
                              <span className='fw-semibold d-block fs-6'>
                                {formatMoney(ticket.value, currentEvent.currency, 0)}
                              </span>
                            </div>
                          </div>

                          <div className='card-body  p-5'>
                            <div className='d-flex flex-column align-items-center'>
                              <span className='me-2 fs-4 fw-semibold mb-3'>
                                {ticket.ticket.name}
                              </span>

                              {/* SHOW SHARED WITH */}
                              {ticket.sentTo ? (
                                <div className='d-flex flex-column'>
                                  <span className='text-muted text-hover-primary mb-3 d-block fs-7'>
                                    {'Shared with '}
                                    {ticket.sentTo?.name},{' '}
                                    {ticket.sentTo.phone ? (
                                      formatPhoneNumber(ticket.sentTo.phone.toString(), true)
                                    ) : (
                                      <>{ticket.sentTo.email}</>
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className='d-flex flex-column'>
                                  <span className='text-muted fw-bold text-hover-primary d-block fs-7'>
                                    Not Shared
                                  </span>
                                </div>
                              )}

                              {/* CHECKPOINTS SETTINGS */}
                              <div className='text-start d-flex flex-wrap flex-grow-1 align-items-center mb-5'>
                                {ticket.ticket.checkpointSettings?.map(
                                  (checkpointSetting: any, index) => {
                                    const checkpoint = ticket.ticket?.checkpoints?.find(
                                      (cp: any) => {
                                        if (cp._id === checkpointSetting.checkpoint) {
                                          return true
                                        } else {
                                          return false
                                        }
                                      }
                                    )

                                    // count checks where checkpoint is the same as the current checkpoint
                                    const checks = ticket.checks.filter(
                                      (check) => check.checkpoint === checkpointSetting.checkpoint
                                    ).length

                                    // if checkpoint is in checks
                                    const checked = checks < checkpointSetting.qty ? false : true

                                    return (
                                      <span
                                        key={index}
                                        className={clsx('badge badge-md fw-bolder p-3 me-2 mb-2', {
                                          'badge-light-danger': checked,
                                          'badge-light-success': !checked,
                                        })}
                                      >
                                        {`${checkpoint?.name} (${checks}/${checkpointSetting.qty})`}
                                      </span>
                                    )
                                  }
                                )}
                              </div>
                            </div>

                            {/* ACTION */}
                            <div className='text-end d-flex flex-column'>
                              <GuestCheckInButton
                                onGuestCheckedIn={handleGuestCheckIn}
                                ticket={ticket}
                                guest={guest}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              ) : (
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Guest Tickets</th>
                        <th className='min-w-140px'>Ticket</th>
                        <th className='min-w-120px'>Shared With</th>
                        <th className='min-w-120px'>Checkpoints</th>
                        <th className='min-w-100px text-end'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {guests.map((guest: GuestModel, index) => (
                        <>
                          {guest.tickets.map((ticket, index) => (
                            <tr key={ticket.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    {/* first letter */}
                                    <span
                                      className={clsx(`symbol-label text-white fw-bolder`, {
                                        'bg-success': ticket.status === 'active',
                                        'bg-danger': ticket.status === 'checked',
                                        'bg-secondary': ticket.status === 'pending',
                                        'bg-dark':
                                          ticket.status === 'cancelled' ||
                                          ticket.status === 'locked',
                                      })}
                                    >
                                      {guest.name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <Link
                                      to={`/bookings/${ticket.booking}/tickets`}
                                      target='_blank'
                                      className='text-dark fw-bold text-hover-primary fs-3'
                                    >
                                      {guest.name}
                                      <KTIcon
                                        iconType='outline'
                                        iconName='exit-right-corner text-primary fw-bold ms-3'
                                      />
                                    </Link>
                                    {guest.phone.toString().length > 5 && (
                                      <span className='text-dark'>
                                        {formatPhoneNumber(guest.phone, true)}
                                      </span>
                                    )}
                                    {guest.email && (
                                      <span className='text-dark'>
                                        {partialHideEmail(guest.email)}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-5'>
                                  {ticket.ticket.name}
                                </span>
                                <div className='d-flex'>
                                  <span className='badge badge-secondary fw-bold me-2'>
                                    {daysUntilDate(new Date(ticket.dateCreated), true) + ' ago'}
                                  </span>
                                  <span className='badge badge-secondary fw-bold d-block'>
                                    {formatMoney(ticket.value, currentEvent.currency, 0)}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {ticket.sentTo ? (
                                  <div className='d-flex flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary d-block fs-5'>
                                      {ticket.sentTo?.name}
                                    </span>
                                    <span className='text-dark'>
                                      {ticket.sentTo.phone ? (
                                        formatPhoneNumber(ticket.sentTo.phone.toString(), true)
                                      ) : (
                                        <>{ticket.sentTo.email}</>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <div className='d-flex flex-column'>
                                    <span className='text-muted fw-bold text-hover-primary d-block fs-5'>
                                      Not Shared
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td className='text-start'>
                                {ticket.ticket.checkpointSettings?.map(
                                  (checkpointSetting: any, index) => {
                                    // 1. CHECK IF CHECKPOINT FILTER IS SET AND SKIP IF NOT MATCHING
                                    const skip =
                                      checkpointFilter &&
                                      checkpointFilter !== checkpointSetting.checkpoint

                                    //2. GET CURRENT CHECKPOINT (POPULATED)
                                    const checkpoint = ticket.ticket?.checkpoints?.find(
                                      (cp: any) => {
                                        if (cp._id === checkpointSetting.checkpoint) {
                                          return true
                                        } else {
                                          return false
                                        }
                                      }
                                    )

                                    // count checks where checkpoint is the same as the current checkpoint
                                    const checks = ticket.checks.filter(
                                      (check) => check.checkpoint === checkpointSetting.checkpoint
                                    ).length

                                    // if checkpoint is in checks
                                    const checked = checks < checkpointSetting.qty ? false : true

                                    return (
                                      <>
                                        {!skip && (
                                          <>
                                            <span
                                              key={index}
                                              className={clsx('badge fw-bolder me-2', {
                                                'badge-light-danger': checked,
                                                'badge-light-success': !checked,
                                              })}
                                            >
                                              {`${checkpoint?.name} (${checks}/${checkpointSetting.qty})`}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    )
                                  }
                                )}
                              </td>
                              <td className='text-end'>
                                <GuestCheckInButton
                                  onGuestCheckedIn={handleGuestCheckIn}
                                  checkpointFilter={checkpointFilter}
                                  ticket={ticket}
                                  guest={guest}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default GuestListPage
