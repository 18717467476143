import {Field} from 'formik'
import React from 'react'
import Flatpickr from 'react-flatpickr'
const DateInput = (props: any) => {
  const {name, ...rest} = props
  return (
    <Field
      name={name}
      className='form-control form-control-lg '
      {...rest}
      options={{
        altFormat: 'j F, Y',
        dateFormat: 'd M Y H:i',
      }}
      component={({field, form, ...props}) => {
        return (
          <Flatpickr
            value={field.value}
            onChange={(date) => form.setFieldValue(field.name, date[0])}
            {...props}
          />
        )
      }}
    />
  )
}
export default DateInput
