import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'

import Swal from 'sweetalert2'
import clsx from 'clsx'
import {RegistrationModel, initialRegistrationValues, useAuthStore} from '../../core/authStore'
import {registrationSchema} from '../../core/regHelpers'
import {Step0} from './steps/Step0'

const RegistrationWizard = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(registrationSchema[0])

  const navigate = useNavigate()
  const {register} = useAuthStore()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(registrationSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    let isBusy = false
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      if (stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1) {
        // submit form
        actions.setSubmitting(true)
        isBusy = true
        const regValues: RegistrationModel = {
          ...initialRegistrationValues,
          ...values,
        }
        register(regValues)
          .then((response) => {
            if (response === true) {
              stepper.current?.goNext()
            } else {
              Swal.fire({
                title: 'Something went wrong.  Please try again or contact admin',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
              })
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            isBusy = false
          })
      } else {
        stepper.current.goNext()
      }
    } else {
      // reset the form
      actions.resetForm()

      // navigate to the login page
      navigate('/auth/login')
    }

    setCurrentSchema(registrationSchema[stepper.current.currentStepIndex - 1])
    if (!isBusy) actions.setSubmitting(false)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex d-none d-md-block justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 0*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>0</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Getting Started</h3>

                  <div className='stepper-desc fw-semibold'>Welcome to skybookings</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 0*/}

            {/* begin::Step 1*/}
            <div className='stepper-item ' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Account Settings</h3>

                  <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>User Details</h3>
                  <div className='stepper-desc fw-semibold'>Setup your details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Payout Details</h3>
                  <div className='stepper-desc fw-semibold'>Your banking details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Verification Docs</h3>
                  <div className='stepper-desc fw-semibold'>
                    Upload account verification documents
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Completed</h3>
                  <div className='stepper-desc fw-semibold'>You are almost there</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 5*/}

            {/* begin: cancel button */}
            <div className='cancel-button mt-5 w-100'>
              <button
                onClick={() => navigate('/auth/login')}
                className='btn btn-light-danger w-100'
              >
                Cancel
              </button>
            </div>
            {/* end: cancel button */}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* end::Aside*/}
      <div className='card d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik
          validationSchema={currentSchema}
          initialValues={{...initialRegistrationValues}}
          onSubmit={submitStep}
        >
          {(form) => {
            return (
              <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step0 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step4 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step5 />
                </div>

                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      disabled={
                        // reached the last slide
                        stepper.current?.currentStepIndex === stepper.current?.totalStepsNumber
                      }
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type='submit'
                      className={clsx('btn btn-lg me-3', {
                        'btn-success':
                          stepper.current?.currentStepIndex === stepper.current?.totalStepsNumber,
                        'btn-primary':
                          stepper.current?.currentStepIndex !== stepper.current?.totalStepsNumber,
                      })}
                      disabled={form.isSubmitting || !form.isValid}
                    >
                      {/* add spinner */}
                      {form.isSubmitting ? (
                        <span className='indicator-label'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          &nbsp;Please wait...
                        </span>
                      ) : (
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex === stepper.current?.totalStepsNumber!
                            ? 'Continue'
                            : stepper.current?.currentStepIndex !==
                                stepper.current?.totalStepsNumber! - 1 && 'Continue'}

                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totalStepsNumber! - 1 && 'Submit'}

                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totalStepsNumber && (
                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                          )}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {RegistrationWizard}
