import axios from 'axios'

export const PAYOUTS_ENDPOINT = `/payouts`

export function _postPayout(args) {
  return axios.post(`${PAYOUTS_ENDPOINT}/`, args)
}

export function _patchPayout(id, args) {
  return axios.patch(`${PAYOUTS_ENDPOINT}/${id}/`, args)
}

export function _patchPayoutReverse(id, args) {
  return axios.patch(`${PAYOUTS_ENDPOINT}/${id}/reverse`, args)
}

export function _patchPayoutHold(id, args) {
  return axios.patch(`${PAYOUTS_ENDPOINT}/${id}/hold`, args)
}

export function _patchPayoutPaid(id, args) {
  return axios.patch(`${PAYOUTS_ENDPOINT}/${id}/paid`, args)
}

export function _patchPayoutCancel(id, args) {
  return axios.patch(`${PAYOUTS_ENDPOINT}/${id}/cancel`, args)
}

export function _getPayouts(query) {
  return axios.get(`${PAYOUTS_ENDPOINT}/`, {params: {...query}})
}

export function _getAdminPayouts(query) {
  return axios.get(`${PAYOUTS_ENDPOINT}/admin`, {params: {...query}})
}

export function _postAdminPayouts() {
  return axios.post(`${PAYOUTS_ENDPOINT}/admin`)
}
