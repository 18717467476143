import {PatronBookingsPage} from '../bookings/PatronBookingsPage'

const PatronPage = () => {
  return (
    <div>
      <PatronBookingsPage />
    </div>
  )
}
export {PatronPage}
