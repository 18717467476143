import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {EventDetails} from './EventEditorPartials/1-EventDetails'
import {useEventsStore} from './core/eventsStore'
import clsx from 'clsx'
import {BookingsSettings} from './EventEditorPartials/3-BookingsSettings'
import {EventAppearance} from './EventEditorPartials/4-EventAppearance'
import {OrganiserDetails} from './EventEditorPartials/6-OrganiserDetails'
import {EventNotifications} from './EventEditorPartials/5-EventNotifications'
import {BillingDetails} from './EventEditorPartials/2-BillingDetails'
import {TicketsEditor} from './EventEditorPartials/7-Tickets'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {eventStatusLabel} from '../../../_helpers/_templateHelpers'
import {KTIcon} from '../../../_metronic/helpers'
import {EventStatusSwitcher} from './EventEditorPartials/EventStatusSwitcher'
import EventColorCode from '../../../_components/EventColorCode'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'
import {trimCharacters} from '../../../_helpers/_helpers'

type StepperProps = {
  label: string
  description: string
  completed: boolean
  current: boolean
  step: number
  disabled?: boolean
  onClick?: (index: number) => void
}

const Stepper = (props: StepperProps) => {
  const {label, description, step, current, completed, disabled} = props

  const HandleStepClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (props.onClick) props.onClick(step - 1)
  }

  return (
    <>
      <div
        className={clsx('stepper-item', {
          current: current === true,
          completed: completed === true,
        })}
        data-kt-stepper-element='nav'
      >
        {/* begin::Wrapper*/}
        <div className='stepper-wrapper'>
          {/* begin::Icon*/}
          <div className={clsx('stepper-icon w-40px h-40px', {'bg-light': disabled === true})}>
            <i className='stepper-check fas fa-check'></i>
            <span className={clsx('stepper-number', {'text-secondary': disabled === true})}>
              {step}
            </span>
          </div>
          {/* end::Icon*/}

          {/* begin::Label*/}
          <div className='stepper-label'>
            <h3 className='stepper-title'>
              <Link
                onClick={HandleStepClick}
                className={clsx('stepper-number', {'text-secondary': disabled === true})}
                to='#'
              >
                {label}
              </Link>
            </h3>

            <div
              className={clsx('stepper-desc fw-semibold', {'text-secondary': disabled === true})}
            >
              {description}
            </div>
          </div>
          {/* end::Label*/}
        </div>
        {/* end::Wrapper*/}

        {/* begin::Line*/}
        <div className='stepper-line h-20px'></div>
        {/* end::Line*/}
      </div>
    </>
  )
}

const EventEditor = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const panelRef1 = useRef<HTMLDivElement>(null)
  const panelRef2 = useRef<HTMLDivElement>(null)
  const panelRef3 = useRef<HTMLDivElement>(null)
  const panelRef4 = useRef<HTMLDivElement>(null)
  const panelRef5 = useRef<HTMLDivElement>(null)
  const panelRef6 = useRef<HTMLDivElement>(null)
  const panelRef7 = useRef<HTMLDivElement>(null)
  // const panelRef8 = useRef<HTMLDivElement>(null)

  const {currentEvent, setCurrentEvent, unsetCurrentEvent, saveEvent} = useEventsStore()
  const navigate = useNavigate()
  const {eventId} = useParams<{eventId: string}>()
  const [isNewEvent, setIsNewEvent] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSavingStatus, setIsSavingStatus] = useState(false)
  const cardRef = useRef<HTMLDivElement | null>(null)
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  const steps = [
    {label: 'Basic Details', description: 'Setup event details', step: 1},
    {label: 'Billing Settings', description: 'Setup billing and fees', step: 2},
    {label: 'Booking Experience', description: 'Change patrons book experience', step: 3},
    {label: 'Content & Appearance', description: 'Setup what patrons see', step: 4},
    {label: 'Organiser Details', description: 'Setup how patrons get support', step: 5},
    {label: 'Notifications', description: 'Setup custom notifications', step: 6},
    {label: 'Tickets & Payments', description: 'Setup tickets and payment options', step: 7},
    // {label: 'Affiliate Rules', description: 'Affiliate Rules', step: 8},
  ]

  useEffect(() => {
    // load event
    if (eventId && !currentEvent.id && !isLoaded) {
      setIsNewEvent(false)
      setIsLoading(true)

      // get the current event
      setCurrentEvent(eventId, false).finally(() => {
        setIsLoading(false)
        setIsLoaded(true)
      })
    } else if (!eventId) {
      unsetCurrentEvent()
      setIsNewEvent(true)
    }
    // handle panel scroll
    const handleScroll = () => {
      // handle aside scroll
      const card = cardRef.current

      if (card) {
        const rect = card.getBoundingClientRect()

        // if window scroll position is greater than the top of the card
        if (window.scrollY > rect.top) {
          card.style.position = 'fixed'
          card.style.top = '0'
        } else {
          card.style.position = 'relative'

          // remove the top position
          card.style.top = 'unset'
        }
      }

      // handle panel scroll
      if (
        panelRef1.current &&
        panelRef2.current &&
        panelRef3.current &&
        panelRef4.current &&
        panelRef5.current &&
        panelRef6.current &&
        panelRef7.current
        // panelRef8.current
      ) {
        const rect1 = panelRef1.current.getBoundingClientRect()
        const rect2 = panelRef2.current.getBoundingClientRect()
        const rect3 = panelRef3.current.getBoundingClientRect()
        const rect4 = panelRef4.current.getBoundingClientRect()
        const rect5 = panelRef5.current.getBoundingClientRect()
        const rect6 = panelRef6.current.getBoundingClientRect()
        const rect7 = panelRef7.current.getBoundingClientRect()
        // const rect8 = panelRef8.current.getBoundingClientRect()

        // set active step if rectable is in view and less than 20% from top after 1 second

        // calculate 20% of the window height
        const twentyPercent = window.innerHeight * 0.3

        if (rect1.top < twentyPercent && rect1.top > 0) {
          setActiveStep(0)
        } else if (rect2.top < twentyPercent && rect2.top > 0) {
          setActiveStep(1)
        } else if (rect3.top < twentyPercent && rect3.top > 0) {
          setActiveStep(2)
        } else if (rect4.top < twentyPercent && rect4.top > 0) {
          setActiveStep(3)
        } else if (rect5.top < twentyPercent && rect5.top > 0) {
          setActiveStep(4)
        } else if (rect6.top < twentyPercent && rect6.top > 0) {
          setActiveStep(5)
        } else if (rect7.top < twentyPercent && rect7.top > 0) {
          setActiveStep(6)
        }
        // else if (rect8.top < twentyPercent && rect8.top > 0) {
        //   setActiveStep(7)
        // }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent, activeStep, isNewEvent])

  // Setup Breadcrumbs
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `Event`,
        title: `${trimCharacters(currentEvent.name, 20)}`,
        link: `/events/${currentEvent.id}`,
      },
      {
        object: 'Editor',
        title: `Editor`,
        link: `/events/${currentEvent.id}/edit`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Event Editor')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent])

  // handle save event
  const handleSaveEvent = (event) => {
    if (event.id && isNewEvent === true) {
      setCurrentEvent(event.id, false).finally(() => {
        setIsNewEvent(false)
        navigate(`/events/${event.id}/edit`)
      })
    }
  }

  // handle step click
  const handleStepClick = (index: number) => {
    // set scroll position of the panel.  Set top to 150px
    if (
      panelRef1.current &&
      panelRef2.current &&
      panelRef3.current &&
      panelRef4.current &&
      panelRef5.current &&
      panelRef6.current &&
      panelRef7.current
      // panelRef8.current
    ) {
      const panels = [
        panelRef1.current,
        panelRef2.current,
        panelRef3.current,
        panelRef4.current,
        panelRef5.current,
        panelRef6.current,
        panelRef7.current,
        // panelRef8.current,
      ]

      // scroll to panel index and set top to 150px
      panels[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })

      // set timeout to set active step after 1 second and then cancel it

      const timeout = setTimeout(() => {
        setActiveStep(index)
      }, 2000)

      clearTimeout(timeout)
    }
  }

  // handle switch status
  const handleSwitchStatus = (status: string) => {
    if (status !== currentEvent.status) {
      setIsSavingStatus(true)
      saveEvent({status}).finally(() => {
        setIsSavingStatus(false)
      })
    }
  }
  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_event_editor_stepper'
          >
            {/* begin::Aside */}
            <div className='card bg-transparent flex-wrap border-0 shadow-none d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
              {/* begin::Wrapper*/}
              <div ref={cardRef} className='card-body p-0 w-xl-300px w-xxl-400px'>
                {/* begin: page title */}
                <div className='page-title d-flex flex-column'>
                  <div className='d-flex'>
                    <h3 className='fs-3'>{currentEvent.id ? currentEvent.name : 'New Event'}</h3>
                  </div>

                  {!isNewEvent && (
                    <>
                      <div className='d-flex flex-column '>
                        <div className='d-flex'>
                          <EventColorCode event={currentEvent} className='mt-1' />
                          <span className='d-flex w-100 mb-3'>
                            {currentEvent.id ? eventStatusLabel(currentEvent) : ''}
                          </span>
                        </div>
                        <div className='d-flex'>
                          <Link
                            to={`/event/${currentEvent.id}`}
                            target='_blank'
                            className='display-inline d-block btn btn-sm btn-secondary me-5'
                          >
                            <KTIcon iconName='eye' iconType='outline' className='me-2 fs-3' />
                            Preview Event
                          </Link>

                          {/* create popup to switch status */}
                          <EventStatusSwitcher
                            onSwitchStatus={handleSwitchStatus}
                            isLoading={isSavingStatus}
                            toggleBtnClass={clsx(
                              'btn-sm',
                              {'btn-success': currentEvent.status === 'active'},
                              {'btn-info': currentEvent.status === 'paused'},
                              {'btn-danger': currentEvent.status === 'archived'},
                              {'btn-primary': currentEvent.status === 'draft'}
                            )}
                            toggleBtnLabel={`status: ${currentEvent.status}`}
                          />
                          {/* <div className='btn btn-sm btn-secondary'>
                            <KTIcon iconName='settings-4' iconType='outline' className='me-2' />
                            Switch Status
                          </div> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* end: page title */}
                <div className='separator border-secondary my-7'></div>
                {/* begin::Nav*/}
                <div className='stepper-nav d-none d-lg-block'>
                  {steps.map((step, index) => (
                    <Stepper
                      key={`${index}`}
                      label={step.label}
                      description={step.description}
                      step={step.step}
                      completed={false}
                      current={activeStep === step.step - 1}
                      onClick={handleStepClick}
                      disabled={isNewEvent === true && step.step > 1}
                    />
                  ))}
                </div>
                {/* end::Nav*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Aside */}

            {/* start: panels */}
            <div className='d-flex flex-row-fluid flex-center editor-panels rounded'>
              {/* begin: panels */}
              <div className='p-0 py-lg-20 w-100 px-lg-9'>
                <div ref={panelRef1}>
                  <EventDetails step='1' onSave={handleSaveEvent} />
                </div>
                {isNewEvent === false && (
                  <>
                    <div ref={panelRef2}>
                      <BillingDetails step='2' />
                    </div>
                    <div ref={panelRef3}>
                      <BookingsSettings step='3' />
                    </div>
                    <div ref={panelRef4}>
                      <EventAppearance step='4' />
                    </div>
                    <div ref={panelRef5}>
                      <OrganiserDetails step='5' />
                    </div>
                    <div ref={panelRef6}>
                      <EventNotifications step='6' />
                    </div>
                    <div ref={panelRef7}>
                      <TicketsEditor step='7' />
                    </div>

                    {/* <div ref={panelRef8}>
                      <AffiliateSettings step='8' />
                    </div> */}
                  </>
                )}
              </div>
            </div>
            {/* end:  panels */}
          </div>
        </>
      )}
    </>
  )
}

export default EventEditor
