/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {EventModel} from '../core/eventsStore'
import {
  formatNumber,
  getCurrencySymbol,
  pluralise,
  trimCharacters,
} from '../../../../_helpers/_helpers'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {eventStatusLabel} from '../../../../_helpers/_templateHelpers'

type Props = {
  event: EventModel
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const EventCard: FC<Props> = ({
  event,
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [bookingsPCT, setBookingsPCT] = useState<number>(0)

  // RENDER
  useEffect(() => {
    refreshChart()

    // calculate bookings percentage
    const pct = Math.floor(
      (event.stats.bookingsTicketsCount / (event.stats.ticketsTotalQty || 1)) * 100
    )
    setBookingsPCT(pct)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  return (
    <div className='p-2 p-xl-5'>
      <Link
        to={`/events/${event.id}`}
        className={`card card-flush d-flex h-100 min-h-235px ${className} border-1 shadow-none border-secondary`}
      >
        <div className='card-header d-flex pt-5'>
          <div className='card-title pe-7 d-flex'>
            <div className='d-flex'>
              <div className='me-5'>
                <div
                  style={{
                    backgroundImage: `url(${event.artwork})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'black',
                  }}
                  className='w-50px h-50px symbol symbol-50px'
                />
              </div>
              <div className='flex-column w-100'>
                <div className='d-flex align-items-center'>{trimCharacters(event.name, 45)}</div>
                <div
                  className={clsx(
                    'position-absolute translate-middle mb-6 bg-secondary rounded-circle border border-4 border-white h-20px w-20px',
                    {
                      'bg-success': event.canBook.canBook === true,
                      'bg-danger': event.canBook.canBook === false,
                    }
                  )}
                  style={{top: '35px', right: '15px'}}
                ></div>

                <span
                  className='text-gray-400 pt-1 fw-semibold fs-6'
                  dangerouslySetInnerHTML={{__html: eventStatusLabel(event)}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-body d-flex'>
          <div className='d-flex align-items-center flex-column w-100  '>
            <div className='d-flex  fs-6 opacity-75 w-100 mt-auto mb-2'>
              <span className='me-3 fs-7 fw-bold'>
                {event.stats.bookingsTicketsCount}
                {event.sellTickets
                  ? pluralise(event.stats.bookingsTicketsCount, ' Confirmed Ticket')
                  : pluralise(event.stats.bookingsTicketsCount, ' Confirmed Space')}
              </span>

              {event.sellTickets && (
                <span className='me-3 fs-7 text-muted'>
                  {getCurrencySymbol(event.currency) +
                    ' ' +
                    formatNumber(event.stats.bookingsValue, 0)}
                </span>
              )}

              <span className='badge badge-light-primary ms-auto'>{bookingsPCT}% Confirmed</span>
            </div>

            <div className='h-8px mx-3 w-100 bg-secondary bg-opacity-50 rounded'>
              <div
                className='bg-black rounded h-8px'
                role='progressbar'
                style={{width: `${bookingsPCT}%`}}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
  drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 100 / 150)
  drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
}

export {EventCard}
