import {Field} from 'formik'
import clsx from 'clsx'
import {accountsUsersStore} from '../app/modules/settings/core/accountsUsersStore'
import {eventsStore} from '../app/modules/events/core/eventsStore'
import {isHandle} from '../_helpers/_helpers'

const EventHandleInput = (props: any) => {
  const {name, exclude, inputGroupText, ...rest} = props

  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        // if user is logged in, ignore the handle associated with the logged in account
        const {getAccount} = accountsUsersStore()
        const {handleExists} = eventsStore()
        const account = getAccount()

        const handleHandleExists = async (handle: string) => {
          try {
            return handleExists(handle)
          } catch (e) {
            return false
          }
        }

        const handleChange = (event: any) => {
          // set touched
          form.setFieldTouched(field.name, true, true)

          // set field value
          form.setFieldValue(field.name, event.target.value)
        }

        const handleBlur = async (event) => {
          if (!meta.error) {
            if (!isHandle(event.target.value)) {
              form.setFieldError(field.name, 'This handle is not valid.  Please try another one.')
              return
            }

            // make sure handle is not the same as current account handle (if logged in)
            if (account !== undefined) {
              if (/*handle !== event.target.value && */ exclude !== event.target.value) {
                form.setSubmitting(true)
                form.setFieldTouched(field.name, true, true)

                // check if handle exists
                const exists = await handleHandleExists(event.target.value)

                if (exists) {
                  form.setFieldError(
                    field.name,
                    'This handle is not available.  Please try another one.'
                  )
                }
                form.setSubmitting(false)
              }
            } else {
              form.setFieldTouched(field.name, true, true)
              form.setSubmitting(true)

              // check if handle exists
              if (await handleHandleExists(event.target.value)) {
                form.setFieldError(
                  field.name,
                  'This handle is not available.  Please try another one.'
                )
              }
              form.setSubmitting(false)
            }
          }
        }

        return (
          // input group with bootstrap
          <div className='input-group'>
            <span className='input-group-text'>{!inputGroupText ? '@' : inputGroupText}</span>

            <input
              type='text'
              name={field.name}
              id={field.name}
              {...field}
              {...rest}
              className={clsx('form-control ', {'is-invalid': meta.error && meta.touched === true})}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        )
      }}
    </Field>
  )
}

export default EventHandleInput
