import {Route, Routes} from 'react-router-dom'

import {BookingsList} from './BookingsPagePartials/BookingsList'

export function BookingsPage() {
  return (
    <Routes>
      <Route index element={<BookingsList />} />
    </Routes>
  )
}
