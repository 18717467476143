import {CheckpointSettingsModel} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {Field} from 'formik'
import clsx from 'clsx'

type Props = {
  name: string
  checkpointSetting: CheckpointSettingsModel
  readonly?: boolean
}
const CheckpointSettingsItem: React.FC<Props> = (props: any) => {
  const {name, checkpointSetting, readonly} = props

  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        const handleIncrement = () => {
          let newValue
          if (!field.value) {
            newValue = {...checkpointSetting, qty: checkpointSetting.qty + 1}
          } else {
            newValue = {...field.value, qty: field.value.qty + 1}
          }

          form.setFieldValue(name, newValue)
        }

        const handleDecrement = () => {
          let newValue
          if (!field.value) {
            newValue = {...checkpointSetting, qty: checkpointSetting.qty - 1}
          } else {
            newValue = {...field.value, qty: field.value.qty - 1}
          }
          if (newValue.qty >= 0) {
            form.setFieldValue(name, newValue)
          }
        }

        return (
          <div
            className={clsx('badge badge-light border badge-lg fw-bold me-3 mb-3 px-10 ', {
              'badge-light-primary border-primary': field.value
                ? field.value.qty
                : checkpointSetting.qty,
            })}
          >
            {/* decremeent button */}
            <button
              type='button'
              onClick={handleDecrement}
              className='btn btn-icon btn-sm btn-active-color-danger'
            >
              <KTIcon iconName='minus-circle' iconType='solid' />
            </button>
            {/* display settings */}
            {checkpointSetting.checkpoint.name} x{' '}
            {field.value ? field.value.qty : checkpointSetting.qty}
            {/* create a structure that matches the chepointSEttingsItem */}
            <input type='hidden' name={`${name}.id`} />
            <input type='hidden' name={`${name}.checkpoint`} />
            <input type='hidden' name={`${name}.qty`} />
            {/* incremenet button */}
            <button
              type='button'
              onClick={handleIncrement}
              className='btn btn-icon btn-sm btn-active-color-primary'
            >
              <KTIcon iconName='plus-circle' iconType='solid' />
            </button>
          </div>
        )
      }}
    </Field>
  )
}

export {CheckpointSettingsItem}
