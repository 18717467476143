import axios from "axios"

export const EVENTS_ENDPOINT = `/events`

export function _getEventStatement(eventId, query) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/statement`, { params: { ...query } })
}

export function _getEventBalance(eventId: string) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/balance`)
}

export function _syncEventStatement(eventId: string) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/sync-statement`)
}