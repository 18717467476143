import {ErrorMessage, Field, Form, Formik} from 'formik'
import {Modal} from 'react-bootstrap'
import {
  BookingTransactionModel,
  initialBookingTransaction,
  useBookingStore,
} from '../core/bookingsStore'
import {FC, useState} from 'react'
import SubmitButton from '../../../../_components/SubmitButton'
import NumberInput from '../../../../_components/NumberInput'
import {KTIcon} from '../../../../_metronic/helpers'
import toast, {Toaster} from 'react-hot-toast'
import * as Yup from 'yup'
import {TransactionModel, useTransactionStore} from '../../transactions/core/transactionsStore'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'

type Props = {
  onTransactionAdded?: () => void
}

// yup validation schema
const validationSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
  description: Yup.string().required('Description is required'),
  gateway: Yup.string(),
})

const ManualBookingPaymentButton: FC<Props> = ({onTransactionAdded}) => {
  const [showModal, setShowModal] = useState(false)
  const {currentBooking} = useBookingStore()
  const {postTransaction} = useTransactionStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {selectedAccountsUsers, adminAccount} = useAccountsUsersStore()

  const handleSubmit = async (values: BookingTransactionModel) => {
    // can't save anything without booking
    if (currentBooking.id === undefined) return

    setIsSubmitting(true)

    try {
      // prepare transaction payload
      const payload: TransactionModel = {
        ...values,
        booking: currentBooking.id,
        event: currentBooking.event.id || '0',
        patron: currentBooking.patron.id || '0',
        gateway: 'manual',
        status: 'complete',
        description: values.type + ': ' + values.description,
      }

      // if we've logged in as admin set the account
      if (adminAccount) {
        payload.account = selectedAccountsUsers.account.id
      }

      // save transaction
      await postTransaction(payload)

      // tell someone
      if (onTransactionAdded) onTransactionAdded()
    } catch (e) {
      toast.error('Failed to add payment')
    }

    setIsSubmitting(false)
    setShowModal(false)
  }

  return (
    <>
      <Toaster position='top-center' />
      <button onClick={() => setShowModal(true)} className='btn btn-sm btn-light-info'>
        {isSubmitting ? (
          <span
            className='spinner-border spinner-border-sm me-1'
            role='status'
            aria-hidden='true'
          ></span>
        ) : (
          <KTIcon className='svg-icon-2' iconName='plus' />
        )}
        Add Payment
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Manual Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              ...initialBookingTransaction,
            }}
            onSubmit={handleSubmit}
          >
            {(form) => {
              return (
                <Form>
                  <div className='fv-row mb-10 row'>
                    <div className='col'>
                      <label className='form-label'>Transaction Type</label>

                      <Field as='select' className='form-select' name='type' disabled>
                        <option value='cash'>Payment</option>
                        <option value='eft'>Refund</option>
                        <option value='card'>Adjustment</option>
                      </Field>
                      <ErrorMessage name='gateway' />
                    </div>
                  </div>
                  <div className='fv-row mb-10 row'>
                    <div className='col '>
                      <label className='form-label required'>Booking Description</label>
                      <Field
                        type='text'
                        className='form-control form-control-lg '
                        name='description'
                      />
                      <ErrorMessage className='text-danger' name='description' />
                    </div>
                  </div>

                  {/* Transaction amount */}
                  <div className='fv-row mb-10'>
                    <div className='col '>
                      <label className='form-label required'>Transaction Amount</label>
                      <NumberInput name='amount' />
                      <ErrorMessage className='text-danger' name='amount' />
                    </div>
                  </div>

                  {/* transaction status hidden */}
                  <input type='hidden' name='booking' value={currentBooking?.id} />
                  <input type='hidden' name='status' />
                  <input type='hidden' name='type' />

                  <SubmitButton
                    isValid={form.isValid}
                    label='Add Payment'
                    isSubmitting={isSubmitting}
                    className='btn btn-primary w-100 mb-5'
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ManualBookingPaymentButton}
