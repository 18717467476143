import {PageLink} from '../../../../_metronic/layout/core'
import {RegistrationWizard} from './RegistrationWizard/RegistrationWizard'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Wizards',
    path: '/crafted/pages/wizards/horizontal',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function RegistrationWizardWrapper() {
  return (
    <>
      <RegistrationWizard />
    </>
  )
}
