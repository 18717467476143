import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import AccountsSearchBar from '../../../../app/pages/accounts/Partials/AccountsSearchBars'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const Search = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  return (
    <>
      {/* begin::Menu toggle */}
      <div className='app-navbar-item ms-1 ms-md-4'>
        <a
          href='#'
          className={clsx('btn btn-icon btn-sm', toggleBtnClass)}
          style={{border: `1px solid ${getCSSVariableValue('--bs-secondary')}`}}
          data-kt-menu-trigger={menuTrigger}
          data-kt-menu-attach='parent'
          data-kt-menu-placement={menuPlacement}
        >
          <KTIcon
            iconName='magnifier'
            iconType='outline'
            className={clsx('theme-light-hide', toggleBtnIconClass)}
          />
        </a>
        {/* begin::Menu toggle */}

        {/* begin::Menu */}
        <div
          className='menu px-5 menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-275px'
          data-kt-menu='true'
        >
          <AccountsSearchBar />
        </div>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {Search}
