import {ErrorMessage, Field, Form, Formik} from 'formik'
import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import SubmitButton from '../../../../_components/SubmitButton'
import NumberInput from '../../../../_components/NumberInput'
import {KTIcon} from '../../../../_metronic/helpers'
import toast, {Toaster} from 'react-hot-toast'
import * as Yup from 'yup'
import {TransactionModel, initialTransaction} from '../../transactions/core/transactionsStore'
import {useTransactionStore} from '../../transactions/core/transactionsStore'
import {formatMoney, pick} from '../../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import {useStatementStore} from '../core/statementsStore'
import {EventModel} from '../../events/core/eventsStore'
import {useEventStatementStore} from '../../events/core/eventStatementStore'

type Props = {
  onTransactionAdded?: () => void
  event?: EventModel
  className?: string
  btnLabel?: string
  btnIcon?: string
  modalTitle?: string
}

// yup validation schema
const validationSchema = Yup.object().shape({
  // amount must not be 0
  amount: Yup.number()
    .required('Amount is required')
    .test('is-zero', 'Amount must not be 0', (value) => value !== 0),
  description: Yup.string().required('Description is required'),
})

const AdjustStatementButton: FC<Props> = ({
  onTransactionAdded,
  event,
  className = 'btn btn-sm btn-light-primary',
  btnLabel = 'Adjust Statement',
  btnIcon = 'cheque',
  modalTitle = 'Add Manual Transaction',
}) => {
  const [showModal, setShowModal] = useState(false)

  const {balance, getBalance} = useStatementStore()
  const {eventBalance} = useEventStatementStore()
  const {adminAccount, getAccount} = useAccountsUsersStore()
  const {getStatements} = useStatementStore()
  const {postTransaction} = useTransactionStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (values: TransactionModel) => {
    // can't save anything without booking
    if (!values.amount) return

    const {account} = selectedAccountsUsers
    if (account == null) return

    setIsSubmitting(true)

    try {
      // prepare transaction payload
      const val: TransactionModel = {
        ...values,
        amount: values.amount,
        type: 'adjustment',
        status: 'complete',
        account: account.id,
      }

      // add event if available
      if (event && event.id) val.event = event.id

      // only pick required fields
      const payload = pick(val, [
        'limitToEvent',
        'account',
        'event',
        'amount',
        'description',
        'type',
        'status',
      ])

      // save transaction
      await postTransaction(payload)
      await getStatements(selectedAccountsUsers.account.id, {page: 1, limit: 10})

      // tell someone
      if (onTransactionAdded) onTransactionAdded()
    } catch (e) {
      toast.error('There was a problem adding your transaction. Please try again.')
    }

    setIsSubmitting(false)
    setShowModal(false)
  }

  // LOAD BALANCE
  useEffect(() => {
    if (!event) {
      getBalance(selectedAccountsUsers.account.id)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Toaster position='top-center' />
      <button onClick={() => setShowModal(true)} className={className}>
        {isSubmitting ? (
          <span
            className='spinner-border spinner-border-sm me-1'
            role='status'
            aria-hidden='true'
          ></span>
        ) : (
          <KTIcon iconType='outline' iconName={btnIcon} />
        )}
        {btnLabel}
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              ...initialTransaction,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(form) => {
              // set default limitToEvent value if event is available
              if (event && !form.touched.limitToEvent) {
                form.setTouched({limitToEvent: true})
                form.setFieldValue('limitToEvent', true)
              }

              return (
                <Form>
                  {/* account balance */}
                  <div className='fv-row mb-10'>
                    <div className='col '>
                      <label className='form-label mb-0'>Statement Balance</label>
                      <div className='account-balance fw-bolder fs-1 text-primary'>
                        {formatMoney(
                          event ? eventBalance : balance,
                          getAccount()?.currency || 'ZAR',
                          0
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Transaction amount */}
                  <div className='fv-row mb-10'>
                    <div className='col '>
                      <label className='form-label mb-0 required'>Transaction Amount</label>

                      <span className='text-muted fs-7 mb-2 d-block'>
                        {' '}
                        Use negative values to deduct from the balance
                      </span>
                      <NumberInput name='amount' />
                      <span className='text-danger mb-2'>
                        <ErrorMessage className='text-danger' name='amount' />
                      </span>
                    </div>
                  </div>

                  <div className='fv-row mb-2'>
                    <div className='col '>
                      <label className='form-label required'>Description</label>
                      <Field name='description' className='form-control' />
                      <span className='text-danger mb-5'>
                        <ErrorMessage className='text-danger' name='description' />
                      </span>
                    </div>
                  </div>

                  {/* USER */}
                  <div className='fv-row mb-10'>
                    <div className='col '>
                      <label className='form-label text-muted'>
                        Description will be recorded under{' '}
                        <span className='badge badge-secondary mx-1'>
                          {selectedAccountsUsers.user.firstName}
                        </span>{' '}
                        on behalf of{' '}
                        <span className='badge badge-secondary mx-1'>
                          {selectedAccountsUsers.account.name}
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* RESTRICT TRANSACTION TO EVENT */}
                  {event && (
                    <div className='fv-row mb-10'>
                      <div className='col '>
                        {/* checkbox */}

                        <label className='form-check-label ms-2 text-dark'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex'>
                              <Field
                                type='checkbox'
                                disabled={adminAccount ? false : true}
                                name='limitToEvent'
                                className='form-check-input me-3'
                              />
                            </div>
                            <div className='d-flex'>
                              <p className='text-start p-0 m-0'>
                                Only record transaction against <b>{event.name}'s</b> statement.
                                Transaction will not affect the main account statement
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}

                  <SubmitButton
                    isValid={form.isValid}
                    label={`Add ${
                      form.values.amount ? formatMoney(form.values.amount) : ' Adjustment'
                    }`}
                    isSubmitting={isSubmitting}
                    className='btn btn-primary w-100 mb-5'
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {AdjustStatementButton}
