import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {pick} from '../../../../_helpers/_helpers'
import {AccountModel} from '../../settings/core/accountsUsersStore'
import {
  _getAdminPayouts,
  _getPayouts,
  _patchPayoutCancel,
  _patchPayoutHold,
  _patchPayoutPaid,
  _patchPayoutReverse,
  _postAdminPayouts,
  _postPayout,
} from './payoutsApi'

export type PayoutModel = {
  account?: AccountModel
  id?: string
  amount: number
  status: string
  notes: string
  dateCreated?: Date
  dateUpdated?: Date
  statementBalance?: number
}

export type Pagination = {
  limit: number
  page: number
  totalPages: number
  totalResults: number
  status?: string
  sortBy?: string
}

export const initialPayout = {
  amount: 0,
  status: 'pending',
  notes: '',
  statementBalance: 0,
}

export const initialPagination: Pagination = {
  limit: 10,
  page: 1,
  totalPages: 1,
  totalResults: 0,
}

type PayoutsStore = {
  pending: number
  pagination: Pagination
  payouts: PayoutModel[]
  adminPayouts: PayoutModel[]
  postPayout: (payout: PayoutModel) => Promise<PayoutModel>
  patchSettlement: (action, id: string, payout: Partial<PayoutModel>) => Promise<PayoutModel>
  getPayouts: (account: string, query: Partial<Pagination>) => Promise<void>
  getAdminPayouts: (query: Partial<Pagination>) => Promise<void>
  postAdminPayouts: () => Promise<PayoutModel>
}

const createStore: StateCreator<PayoutsStore> = (set, get) => ({
  pending: 0,
  pagination: {...initialPagination},
  payouts: [],
  adminPayouts: [],

  postPayout: async (payout: PayoutModel) => {
    // post payout
    const payload = pick(payout, ['amount', 'status', 'notes'])
    const response = await _postPayout(payload)

    // update payouts
    const {payouts} = get()
    const newPayouts = [...payouts, response.data]
    set({payouts: newPayouts})
    return response.data
  },

  patchSettlement: async (action, id) => {
    // patch payout
    let payload: any = {}
    let response: any = {}
    switch (action) {
      case 'reverse':
        payload.notes = 'Reversed payout'
        response = await _patchPayoutReverse(id, payload)

        break
      case 'hold':
        payload.notes = 'Payout on hold pending investigation'
        response = await _patchPayoutHold(id, payload)

        break
      case 'paid':
        payload.notes = 'Payout has been paid'
        response = await _patchPayoutPaid(id, payload)

        break
      case 'cancel':
        payload.notes = 'Payout has been cancelled'
        response = await _patchPayoutCancel(id, payload)

        break
    }

    // UPDATE PAYOUT
    const {adminPayouts} = get()
    const newPayouts = adminPayouts.map((payout) => {
      if (payout.id === id) {
        return {
          ...payout,
          ...response.data,
        }
      }
      return payout
    })

    set({adminPayouts: newPayouts})
    return response.data
  },

  getPayouts: async (account: string, query: Partial<Pagination>) => {
    const {pagination} = get()

    // get payouts
    const q = pick({...pagination, ...query, account}, [
      'event',
      'patron',
      'booking',
      'status',
      'type',
      'limit',
      'page',
      'account',
    ])

    // if status or type are empty remove them from the query
    const response = await _getPayouts(q)
    const newPayouts: PayoutModel[] = response.data.results.map((payout) => {
      const nPayout: PayoutModel = {
        ...initialPayout,
        id: payout._doc._id,
        account: payout._doc.account,
        amount: payout._doc.amount,
        status: payout._doc.status,
        notes: payout._doc.notes,
        dateCreated: payout._doc.dateCreated,
        dateUpdated: payout._doc.dateUpdated,
        statementBalance: payout._doc.statementBalance,
      }

      return nPayout
    })

    const newPagination = {
      ...pagination,
      ...q,
      totalResults: response.data.totalResults,
      totalPages: response.data.totalPages,
    }
    // update currentBookings
    set({
      pending: response.data.totalPending,
      payouts: newPayouts,
      pagination: newPagination,
    })
  },

  getAdminPayouts: async (query: Partial<Pagination>) => {
    const {pagination} = get()

    // get payouts
    const q = pick({...pagination, ...query}, [
      'event',
      'patron',
      'booking',
      'status',
      'type',
      'limit',
      'page',
    ])

    // if status or type are empty remove them from the query
    const response = await _getAdminPayouts(q)
    const newPayouts: PayoutModel[] = response.data.results.map((payout) => {
      const nPayout = {
        ...initialPayout,
        id: payout._doc._id,
        account: payout._doc.account,
        amount: payout._doc.amount,
        status: payout._doc.status,
        notes: payout._doc.notes,
        dateCreated: payout._doc.dateCreated,
        dateUpdated: payout._doc.dateUpdated,
        statementBalance: payout._doc.statementBalance,
      }

      return nPayout
    })

    const newPagination = {
      ...pagination,
      ...q,
      totalResults: response.data.totalResults,
      totalPages: response.data.totalPages,
    }
    // update currentBookings
    set({
      pending: response.data.totalPending,
      adminPayouts: newPayouts,
      pagination: newPagination,
    })
  },

  postAdminPayouts: async () => {
    // post payout
    const response = await _postAdminPayouts()

    return response.data
  },
})

export const payoutsStore = create(devtools(createStore))
export const usePayoutsStore = payoutsStore
