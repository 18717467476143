/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {initialAppSettings} from '../../../modules/settings/core/appSettingsStore'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  className: string
}

const rows: Array<{description: string; link: string; label: string}> = [
  {
    description: 'WhatsApp',
    link: `https://wa.me/${initialAppSettings.sbPhone}`,
    label: `+${initialAppSettings.sbPhone}`,
  },
  {
    description: 'Email Support',
    link: `mailto:${initialAppSettings.sbEmail}`,
    label: `${initialAppSettings.sbEmail}`,
  },
]

const QuickLinks = ({className}: Props) => (
  <div className={`card card-flush border-1 card-border shadow-none ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bolder'>Support</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex align-items-start flex-stack'>
            <div className='d-flex flex-column'>
              <a href={row.link} target='blank' className='text-dark fw-semibold fs-6 me-2'>
                {row.description}
              </a>
              <span className='text-muted'>{row.label}</span>
            </div>

            <a href={row.link} rel='noreferrer' target='_blank' className='text-muted fw-normal'>
              <KTIcon
                iconType='outline'
                iconName='exit-right-corner'
                className='ms-2 fw-bold text-dark'
              />
            </a>
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {QuickLinks}
