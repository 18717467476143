import axios from "axios"
import { GuestModel } from "./guestsStore"

export const CHECKS_ENDPOINT = `/checks`

export type CheckApiModel = {
    bookingTicket: string,
    checkpoint: string,
    scanner?: string,
}

export function _postCheck(check: CheckApiModel) {
    return axios.post(CHECKS_ENDPOINT, check)
}
