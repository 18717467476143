import {useEffect, FC} from 'react'
import {ErrorMessage, Field, FormikContextType, useFormikContext} from 'formik'
import {PasswordMeterComponent} from '../../../../../../_metronic/assets/ts/components'
import EmailInput from '../../../../../../_components/EmailInput'

const Step2: FC = () => {
  // formik form setup
  const {touched, errors}: any = useFormikContext()

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>User Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out For more information please{' '}
          <a href='https://wa.me/27736898968'>WhatsApp</a> Us.
        </div>
      </div>

      <div className='fv-row row mb-10'>
        <div className='col-md-6 col-12'>
          <label className='form-label required'>First Name</label>

          <Field name='userFirstName' className='form-control form-control-lg  ' />
          {/* {touched.userFirstName && errors.userFirstName && (
            <div className='text-danger mt-2'>
              <ErrorMessage name='userFirstName' />
            </div>
          )} */}
        </div>
        <div className='col-md-6 col-12'>
          <label className='form-label required'>Last Name</label>

          <Field name='userLastName' className='form-control form-control-lg  ' />
          {/* {touched.userLastName && errors.userLastName && (
            <div className='text-danger mt-2'>
              <ErrorMessage name='userLastName' />
            </div>
          )} */}
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Display Name</span>
        </label>

        <Field name='userDisplayName' className='form-control form-control-lg  ' />
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='userDisplayName' />
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Email Address</label>

        <EmailInput name='userEmail' className='form-control form-control-lg  ' />

        <div className='text-danger mt-2'>
          <ErrorMessage name='userEmail' />
        </div>
      </div>

      {/* begin::Form group Password */}
      <div className='fv-row mb-10' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <Field
              type='password'
              placeholder='Password'
              name='userPassword'
              autoComplete='off'
              className='form-control form-control-lg  '
            />
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='userPassword' />
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <Field
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          name='userPasswordConfirm'
          className='form-control form-control-lg  '
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='userPasswordConfirm' />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-check form-check-inline' htmlFor='acceptTerms'>
          <Field className='form-check-input' name='acceptTerms' type='checkbox' id='acceptTerms' />
          <span>
            I Accept the{' '}
            <a
              href='https://skybookings.net/terms-of-use/'
              target='_blank'
              rel='noopener noreferrer'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='acceptTerms' />
        </div> */}
      </div>
      {/* end::Form group */}
    </div>
  )
}

export {Step2}
