import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers'
import {useEventsStore} from '../core/eventsStore'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  toggleBtnIcon?: string
  toggleBtnLabel?: string
  menuPlacement?: string
  menuTrigger?: string
  onSwitchStatus?: (status: string) => void
  isLoading?: boolean
}

const EventStatusSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-3',
  toggleBtnIcon = 'setting-3',
  toggleBtnLabel = 'Status',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
  onSwitchStatus,
  isLoading = false,
}: Props) => {
  const {currentEvent} = useEventsStore()
  const switchStatus = (status: string) => {
    onSwitchStatus && onSwitchStatus(status)
  }

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        {isLoading ? (
          <span className='spinner-border spinner-border-sm align-middle ms-2 me-2' />
        ) : (
          <KTIcon iconName={toggleBtnIcon} iconType='outline' className={toggleBtnIconClass} />
        )}

        {toggleBtnLabel}
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentEvent.status === 'draft'})}
            onClick={() => switchStatus('draft')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentEvent.status === 'draft' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Draft</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentEvent.status === 'active'})}
            onClick={() => switchStatus('active')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentEvent.status === 'active' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Active</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentEvent.status === 'paused'})}
            onClick={() => switchStatus('paused')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentEvent.status === 'paused' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Paused</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentEvent.status === 'archived'})}
            onClick={() => switchStatus('archived')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentEvent.status === 'archived' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Archived</span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {EventStatusSwitcher}
