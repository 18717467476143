/* eslint-disable jsx-a11y/anchor-is-valid */
import {useDashboardsStore} from '../core/dashboardStore'
import {formatMoney} from '../../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {Link} from 'react-router-dom'

type Props = {
  className?: string
}

const WalletCard = ({className}: Props) => {
  const {dashboardData} = useDashboardsStore()
  const {getCurrentAccount} = useAccountsUsersStore()

  return (
    <div
      className={`card card-flush border-0 bg-gray-200 bg-hover-gray-400 card-border shadow-none ${className}`}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='card-title d-flex flex-column'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {formatMoney(
                dashboardData?.walletStats?.totalBalance,
                getCurrentAccount()?.currency,
                0,
                true
              )}
            </span>
            <Link to={'/account'} className='text-dark pt-1 fw-semibold fs-6'>
              Account Balance
            </Link>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column align-items-start justify-content-end pe-0'>
        {dashboardData?.walletStats?.totalPendingPayouts > 0 ? (
          <>
            <div className='fs-6 fw-bold badge badge-warning text-gray-800 mb-2'>
              Pending Payout:{' '}
              {formatMoney(
                dashboardData?.walletStats?.totalPendingPayouts,
                getCurrentAccount()?.currency,
                0
              )}
            </div>
          </>
        ) : (
          <>
            <span className='fs-6 fw-normal text-muted text-gray-800 d-block mb-2'>
              Available for Payout:{' '}
              {formatMoney(
                dashboardData?.walletStats?.totalPayable,
                getCurrentAccount()?.currency,
                0
              )}
            </span>
          </>
        )}
      </div>
    </div>
  )
}
export {WalletCard}
