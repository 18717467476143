/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {BookingModel, useBookingStore} from './core/bookingsStore'
import {BookingItem} from './BookingItem'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useEventsStore} from '../events/core/eventsStore'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'

export function PatronBookingsPage() {
  const {bookings, getBookings, getMoreBookings, pagination} = useBookingStore()
  const {currentEvent, getEvent} = useEventsStore()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingMore, setIsLoadingMore] = React.useState(false)
  const {eventId} = useParams<{eventId: string}>()

  useEffect(() => {
    if (bookings.length === 0) {
      setIsLoading(true)
      let params: any = {limit: 10, page: 1}
      if (eventId) {
        params = {...params, event: eventId}
        if (currentEvent.id !== eventId) {
          getEvent(eventId)
        }
      }
      getBookings(params).finally(() => setIsLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadMore = async () => {
    setIsLoadingMore(true)
    await getMoreBookings({page: pagination.page + 1})
    setIsLoadingMore(false)
  }
  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <div className='flex-column d-flex'>
              <h3 className='fw-bolder my-2 fs-4'>
                {eventId ? `${currentEvent.name}` : 'My Bookings'}
              </h3>
              <span className='fs-6 text-gray-400 fw-bold ms-1 d-block'>
                Showing&nbsp;{bookings.length}
                {' of '}
                {pagination.totalResults}
              </span>
            </div>
          </div>

          <div className='row g-6 g-xl-9'>
            {bookings.map((booking: BookingModel) => (
              <div key={booking.id} className='col-md-6 col-xl-4'>
                <BookingItem booking={booking} />
              </div>
            ))}
            {pagination.page < pagination.totalPages && (
              <div className='col-md-6 col-xl-4 min-h-150px '>
                <div className='d-flex rounded border-dashed border-1 h-100 bg-light align-items-center justify-content-center'>
                  <button onClick={handleLoadMore} className='btn btn-secondary' type='button'>
                    {isLoadingMore ? (
                      <span
                        className='spinner-border spinner-border-sm me-1'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    ) : (
                      <KTIcon iconName='right' className='fs-5 text-success' />
                    )}
                    Get More Bookings
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>
              Showing {bookings.length} of {pagination.totalResults} bookings
            </div>
          </div>
        </>
      )}
    </>
  )
}
