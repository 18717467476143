import {useField} from 'formik'
import React, {useState, useRef, useEffect, FC} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import the Quill styles

// setup props
type Props = {
  name: string
  placeholder?: string
  suggestions?: string[]
}

const QuillEditor: FC<Props> = (props: Props) => {
  const [field, meta, helpers] = useField(props)
  const customSuggestions = props.suggestions ? props.suggestions : []
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestionsPosition, setSuggestionsPosition] = useState({top: 0, left: 0})
  const [search, setSearch] = useState('')

  const quillRef = useRef<any>(null)
  const quillSuggestionsRef = useRef<any>(null)

  const handleChange = (content: string) => {
    helpers.setValue(content)
    handleSuggestions(content)
  }

  const handleSuggestions = (content: string) => {
    if (quillRef.current === null) return

    // Get the cursor position
    const editor = quillRef.current.getEditor()

    if (!editor) return
    const typeCursor = editor.getSelection()?.index

    // get top and left position of the cursor
    if (!showSuggestions) {
      setSuggestionsPosition(quillRef.current.getEditor().getBounds(typeCursor))

      // make sure there's no overflow on the right
      if (suggestionsPosition.left + 200 > window.innerWidth) {
        setSuggestionsPosition({top: suggestionsPosition.top, left: window.innerWidth - 200})
      }
    }

    // Get the text after {{
    const searchTerm = Array.from(content.matchAll(/\{\{([a-zA-Z0-9]+)(?![^<]*\}\})/g)).map(
      (match) => match[1]
    )[0]

    if (searchTerm) {
      setSearch(searchTerm)
      // Filter suggestions based on the search term
      const fsus = customSuggestions.filter((suggestion) => {
        return suggestion.toLowerCase().includes(searchTerm.toLowerCase())
      })
      setFilteredSuggestions(fsus)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
      setFilteredSuggestions([])
      setSearch('')
    }
  }

  const handleSuggestionClick = (suggestion: string) => {
    if (quillRef.current === null) return

    const cursorPosition = quillRef.current.lastDeltaChangeSet.ops[0].retain
    if (cursorPosition === undefined) return

    if (search) {
      // search for searchTerm in editor and replace it with suggestion
      const editorContent = quillRef.current.getEditor().getText()

      // search editorContent and find index where text is '{{' but assert that there is no corresponding '}}' after it
      const regex = /\{\{(?!.*\}\})/g
      const matches: any = Array.from(editorContent.matchAll(regex))[0]

      if (!matches) return

      const index = matches.index

      const length = `{{${search}`.length
      const editor = quillRef.current.getEditor()

      editor.deleteText(index, length)
      editor.insertText(index, `{{${suggestion}}}`)
      editor.setSelection(index + suggestion.length + 4)
      editor.focus()

      // Clear suggestions after inserting
      setShowSuggestions(false)

      setFilteredSuggestions([])
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    // close if clicked outside quill suggestions
    if (quillSuggestionsRef.current && !quillSuggestionsRef.current.contains(event.target)) {
      setShowSuggestions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div style={{position: 'relative'}}>
      <ReactQuill
        className='myQuillEditor'
        ref={quillRef}
        value={field.value}
        onChange={handleChange}
      />
      {/* show available suggestions */}

      <div className='d-flex mt-2 flex-wrap'>
        {customSuggestions.map((suggestion, index) => (
          <span key={index} className='badge badge-light-primary mx-1 my-1'>
            {`{{${suggestion}}}`}
          </span>
        ))}
      </div>
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul
          ref={quillSuggestionsRef}
          className='quill-suggestions'
          style={{
            border: '1px solid #ccc',
            position: 'absolute',
            top: suggestionsPosition.top + 75,
            left: suggestionsPosition.left - 50,
            backgroundColor: '#fff',
            zIndex: 10,
            listStyleType: 'none',
            margin: 0,
            padding: '5px 0',
            width: '200px',
          }}
        >
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default QuillEditor
