import React, {FC} from 'react'
import {ErrorMessage} from 'formik'
import {FileInput} from '../../../../../../_components/FileInput'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Verification Docs</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          This information is to verify your account. For more information please{' '}
          <a href='https://wa.me/27736898968'>WhatsApp</a> Us.
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Upload ID</label>
        <div className='form-group'>
          <div className='form-file'>
            <FileInput name='accountFileId' className='form-file-input form-control' />
          </div>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='accountFileId' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Upload Bank Confirmation Letter</label>
        <div className='form-group'>
          <div className='form-file'>
            <FileInput name='accountFileBankLetter' className='form-file-input form-control' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='accountFileBankLetter' />
          </div>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Upload Proof of Residence</label>
        <div className='form-group'>
          <div className='form-file'>
            <FileInput name='accountFileProofOfAddress' className='form-file-input form-control' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='accountFileProofOfAddress' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-stack'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>Verification notice</label>
          <div className='fs-6 text-gray-400'>
            Your personal information will be processed and verified within 48 hours to ensure that
            you are the owner of the account. All payouts will be withheld until your account is
            verified. But you can use your account in the meantime.
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
