import axios from "axios"

export const DASHBOARD_ENDPOINT = `/dashboard`


export function _getDashboard(accountId) {
    return axios.get(`${DASHBOARD_ENDPOINT}/${accountId}`)
}

export function _getAdminDashboard() {
    return axios.get(`${DASHBOARD_ENDPOINT}/admin`)
}