/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {useAccountsUsersStore} from '../../../../app/modules/settings/core/accountsUsersStore'
import {useAuthStore} from '../../../../app/modules/auth/core/authStore'
import {KTIcon} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {selectedAccountsUsers, adminAccount, switchUserToAdmin} = useAccountsUsersStore()
  const {logout, deselectAccount} = useAuthStore()
  const navigate = useNavigate()

  const handleSwitchAccount = () => {
    // get account from search results
    switchUserToAdmin()
    navigate('/dashboard')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {adminAccount ? (
        <div className='menu-item px-5 my-1 '>
          <button
            onClick={handleSwitchAccount}
            type='button'
            className='btn-link w-100 text-dark bg-warning rounded text-bolder btn menu-link px-5'
          >
            <KTIcon className='menu-icon text-dark ms-5 fs-5' iconName='left' iconType='solid' />
            Back to Admin
          </button>
        </div>
      ) : (
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {selectedAccountsUsers?.user.displayName}
                {selectedAccountsUsers.account.isVerified && (
                  <KTIcon iconName='verify' iconType='solid' className='fs-2 text-dark ms-2' />
                )}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {selectedAccountsUsers?.user.email}
              </a>
            </div>
          </div>
        </div>
      )}

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/settings' className='menu-link px-5'>
          <KTIcon className='menu-icon fs-5' iconName='user-edit' iconType='outline' />
          Account Settings
        </Link>
      </div>

      {/* seperator */}
      <div className='separator my-2'></div>

      {!adminAccount && (
        <div className='menu-item px-5 my-1'>
          <a onClick={deselectAccount} className='menu-link px-5'>
            <KTIcon className='menu-icon fs-5' iconName='setting-3' iconType='outline' />
            Switch Account
          </a>
        </div>
      )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <KTIcon className='menu-icon fs-5' iconName='key' iconType='outline' />
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
