import {useState} from 'react'
import {ErrorMessage, Form, Field, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import EventHandleInput from '../../../../_components/EventHandleInput'
import MapInput from '../../../../_components/MapInput'
import {useEventsStore} from '../core/eventsStore'
import * as Yup from 'yup'
import {KTIcon} from '../../../../_metronic/helpers'
import DateInput from '../../../../_components/DateInput'
import toast, {Toaster} from 'react-hot-toast'

// event details validation schema with Yup
const validationSchema = Yup.object({
  name: Yup.string().required().label('Event Name'),
  handle: Yup.string()
    .required()
    .test('handle', 'only alphanumeric values and - allowed', (val) => {
      if (!val) return true
      const pattern = /^[a-zA-Z0-9-]+$/
      return pattern.test(val)
    })
    .label('Event Handle'),

  // test that start date is less than end date
  startDate: Yup.string()
    .test('startDate', 'Start Date must be less than End Date', function (value) {
      if (!value) return true
      const {endDate} = this.parent
      return new Date(value) <= new Date(endDate)
    })
    .label('Start Date'),

  endDate: Yup.string()
    .test('endDate', 'End Date must be greater than Start Date', function (value) {
      if (!value) return true
      const {startDate} = this.parent
      return new Date(value) >= new Date(startDate)
    })
    .label('End Date'),

  location: Yup.string().label('Location'),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (event: any) => void
  step?: string
}

const EventDetails = (props: Props) => {
  const {step} = props
  const {currentEvent, saveEvent} = useEventsStore()
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const [copyStatus, setCopyStatus] = useState<string>('')

  const appUrl = process.env.REACT_APP_PUBLIC_URL + '/'

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Account successfully updated')

        // emit
        if (props.onSave) props.onSave(response)

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  const handleCopyToClipboard = (content: string) => {
    //  copy to clipboard
    setCopyStatus('copying')

    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.success('Copied to Clipboard')
        setCopyStatus('copied')
      })
      .catch((err) => {
        toast.error('Failed to copy to clipboard')
        setCopyStatus('failed')
      })
      .finally(() => {
        // set timeout to reset copy status
        setTimeout(() => {
          setCopyStatus('')
        }, 2000)
      })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Toaster />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_event_details'
        aria-expanded='true'
        aria-controls='kt_event_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Basic Details</h3>
        </div>
      </div>

      <div id='kt_event_details' className='collapse show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-9'>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col-12 mb-10'>
                          <label className='form-label required'>Event Name</label>
                          <Field name='name' className='form-control form-control-lg ' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='col-12 mb-10'>
                          <label className='form-label required'>Event URL</label>
                          <span className='text-gray-400 fs-6 ms-3'>
                            You can make this the same as your social media handle or hashtag
                          </span>
                          <div className='d-flex'>
                            <EventHandleInput
                              inputGroupText={`${appUrl}`}
                              exclude={currentEvent.handle}
                              name='handle'
                              className='form-control form-control-lg '
                            />
                            {/* copy button */}
                            <button
                              type='button'
                              className='btn-icon btn btn-lg btn-secondary ms-3'
                              onClick={() => handleCopyToClipboard(appUrl + form.values.handle)}
                            >
                              {copyStatus === '' && (
                                <KTIcon
                                  iconName='copy'
                                  iconType='outline'
                                  className='svg-icon svg-icon-2'
                                />
                              )}

                              {copyStatus === 'copying' && (
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              )}

                              {copyStatus === 'copied' && (
                                <KTIcon
                                  iconName='check-circle'
                                  iconType='solid'
                                  className='svg-icon fs-3 svg-icon-2 text-success'
                                />
                              )}

                              {copyStatus === 'failed' && (
                                <KTIcon
                                  iconName='cross-circle'
                                  iconType='solid'
                                  className='svg-icon fs-3 svg-icon-2 text-danger'
                                />
                              )}
                            </button>
                          </div>
                          <div className='text-danger mt-2'>
                            {form.errors.handle && form.touched.handle && (
                              <div className='text-danger'>{String(form.errors.handle)}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-label required'>Start Date and Time</label>

                          <DateInput name='startDate' data-enable-time />
                          {form.errors.startDate && (
                            <div className='text-danger'>{String(form.errors.startDate)}</div>
                          )}
                        </div>

                        <div className='col-lg-6'>
                          <label className='form-label required'>End Date and Time</label>
                          <DateInput name='endDate' data-enable-time />
                          {form.errors.endDate && (
                            <div className='text-danger'>{String(form.errors.endDate)}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <label className='form-label'>Location</label>
                      <span className='text-gray-400 fs-6 ms-3'>
                        Leave the location blank if it is not yet confirmed. This is suitable for
                        the 'Save The Date' Stage of your events
                      </span>

                      <div className='d-flex flex-row'>
                        <MapInput name='location' zoom={13} />
                      </div>
                      {/* Show location on go */}

                      <div className='text-danger mt-2'>
                        <ErrorMessage name='location' />
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EventDetails}
