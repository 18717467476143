import {FC} from 'react'

type Props = {
  isSubmitting: boolean
  isValid?: boolean
  label: string
  submittingLabel?: string
  className?: string
  disabled?: boolean
}

const SubmitButton: FC<Props> = ({
  isSubmitting,
  isValid = true,
  label = 'Submit',
  submittingLabel = 'Please wait...',
  className = 'btn-primary me-4',
  disabled = false,
}: Props) => {
  //   const {isSubmitting, label, className, submittingLabel} = props

  return (
    <button
      type='submit'
      disabled={isSubmitting || (!isValid && true) || disabled}
      className={`btn ${className}`}
    >
      <span className='indicator-label'>
        {isSubmitting && (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        )}
        &nbsp;
        {isSubmitting ? submittingLabel : label}
      </span>
    </button>
  )
}

export default SubmitButton
