import {useEffect, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {AccountModel, useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {formatMoney, trimCharacters} from '../../../_helpers/_helpers'
import {useAccountsStore} from './core/accountsStore'
import {useNavigate} from 'react-router-dom'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'

const AccountsPage = () => {
  const {query, pagination, accounts, getAccounts} = useAccountsStore()
  const {getCurrentAccount, switchAdminToUser} = useAccountsUsersStore()
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const [accountsChecked, setAccountsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getAccounts(query).finally(() => {
      setIsLoading(false)
      setAccountsChecked(true)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: PageDataModel[] = [
      {
        object: 'Accounts',
        title: 'Accounts',
        link: '/admin/accounts',
      },
    ]
    setCrumbs(crumbs)
  }, [accounts])

  const handlePaginationChange = (page: number) => {
    setIsLoading(true)
    getAccounts({...query, page}).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSwitchAccount = (account: AccountModel) => {
    // get account from search results
    switchAdminToUser(account)
    navigate('/dashboard')
  }

  return (
    <>
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex justify-content-between mb-10'>
            <div className='d-flex flex-column'>
              <span className='fs-3 fw-bold'>
                Showing {accounts.length} of {pagination.totalResults} Accounts
              </span>
              <span className='fs-5 text-muted'>{`showing page ${pagination.page} of ${pagination.totalPages}`}</span>
            </div>
          </div>
        </div>
      </div>

      {/* ACCOUNTS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {accountsChecked === true && accounts.length === 0 ? (
            <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='d-flex'>
                  <KTIcon
                    className='svg-icon-3x svg-icon-primary mb-5 '
                    iconName='flaticon-safebox-1'
                  />
                  <span className='fs-2x fw-bolder text-dark'>No accounts have been made yet.</span>
                </div>
                <div className='fs-5 text-muted text-center'>
                  You can request a account by clicking the button below.
                </div>
              </div>
            </div>
          ) : (
            <>
              {isMobileDevice() ? (
                <div className='d-flex w-100 flex-column'>
                  {accounts.map((account) => (
                    <div
                      className='card card-custom card-stretch mb-2'
                      onClick={() => handleSwitchAccount(account)}
                    >
                      <div className='card-header px-5 w-100 d-flex'>
                        <div className='card-title w-100 d-flex'>
                          <div className='d-flex w-100'>
                            <div className='symbol symbol-45px me-5'>
                              <span className='symbol-label bg-dark text-light fw-bolder'>
                                {account.name.charAt(0).toUpperCase()}
                              </span>
                            </div>

                            <div className='d-flex flex-column flex-grow-1 justify-content-center'>
                              <h2 className='card-label fs-5 d-flex align-items-center'>
                                <span className='fw-bolder me-1'>
                                  {trimCharacters(account.name, 18)}
                                </span>
                                {account.isVerified && (
                                  <KTIcon
                                    iconName='verify'
                                    iconType='solid'
                                    className='fs-1 text-dark'
                                  />
                                )}
                              </h2>
                              <div className='d-flex'>
                                <span className='text-muted me-2 my-2 fs-7'>@{account.handle}</span>
                                {/* account balance */}
                                <span className='badge badge-light-dark me-2 my-2 fs-7'>
                                  {formatMoney(
                                    account.stats?.walletStats.totalBalance,
                                    getCurrentAccount()?.currency,
                                    0
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className='symbol symbol-45px mt-1 d-flex align-items-center justify-content-end'>
                              {/* circle showing number of active events */}
                              <div className='symbol-label bg-light-primary text-primary fw-bolder fs-3'>
                                {account.stats?.eventsBookingsCard.totalEvents}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='ps-5 text-start'>Account Name</th>
                      <th className='ps-5'>Events</th>
                      <th className='text-end'>Numbers</th>
                      <th className='text-end pe-10'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.map((account) => (
                      <tr key={account.id}>
                        {/* account name */}
                        <td className='ps-5'>
                          <div className='d-flex text-start justify-content-start'>
                            <div
                              onClick={() => handleSwitchAccount(account)}
                              className='cursor-pointer symbol symbol-35'
                              data-kt-menu-trigger="{default: 'click'}"
                              data-kt-menu-attach='parent'
                              data-kt-menu-placement='bottom-end'
                            >
                              {account.image && account.image.length > 10 ? (
                                <img src={account.image} alt='' />
                              ) : (
                                <span className='symbol-label bg-dark text-light fw-bolder'>
                                  {account.name.charAt(0).toUpperCase()}
                                </span>
                              )}
                            </div>
                            <span
                              className={clsx('badge badge-circle w-10px h-10px me-5', {
                                'badge-success': account.status === 'complete',
                                'badge-warning': account.status === 'pending',
                                'badge-info': account.status === 'holding',
                                'badge-danger': account.status === 'cancelled',
                              })}
                            />
                            <div className='d-flex flex-column'>
                              <div className='d-flex align-items-start'>
                                <button
                                  type='button'
                                  className='btn btn-link text-dark fw-bolder py-0 me-2'
                                >
                                  {account.name}
                                  {account.isVerified && (
                                    <KTIcon
                                      iconName='verify'
                                      iconType='solid'
                                      className='fs-1 text-dark'
                                    />
                                  )}
                                </button>
                              </div>

                              {/* handle and plan */}
                              <div className='d-flex me-2'>
                                <span className='me-2'>@{account.handle || 'no handle'}</span>
                                <span
                                  className={clsx('fw-normal badge ', {
                                    'badge-light-success': account.plan === 'independent',
                                    'badge-light-warning': account.plan === 'agency',
                                    'badge-light-primary': account.plan === 'skybookings',
                                    'badge-light-danger': account.plan === 'government',
                                    'badge-light-secondary': account.plan === 'nonprofit',
                                  })}
                                >
                                  {account.plan}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>

                        {/* STATS */}
                        <td className='ps-5'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column'>
                              <span className='fw-bolder'>
                                Total Events: {account.stats?.eventsBookingsCard.totalEvents}
                              </span>
                              <span className='fw-normal'>
                                This week's bookings:
                                {account.stats?.eventsBookingsCard.countBookings}
                              </span>
                            </div>
                          </div>
                        </td>

                        {/* NUMBERS */}

                        <td className='text-end'>
                          <div className='d-flex flex-column'>
                            <span className='fw-bold'>
                              Payout Request:{' '}
                              {formatMoney(
                                account.stats?.walletStats.totalPendingPayouts,
                                account?.currency || 'ZAR',
                                2
                              )}
                            </span>
                            <span className='text-muted'>
                              Account Balance:{' '}
                              {formatMoney(
                                account.stats?.walletStats.totalBalance,
                                getCurrentAccount()?.currency,
                                2
                              )}
                            </span>
                          </div>
                        </td>

                        {/* ACTION */}
                        <td className='text-end pe-10'>
                          <button
                            type='button'
                            onClick={() => handleSwitchAccount(account)}
                            className='btn btn-sm btn-secondary btn-bg-light btn-active-color-primary btn-sm'
                          >
                            Switch to Account
                            <KTIcon iconName='right' iconType='outline' className='ms-1' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {accounts.length < pagination.totalResults && (
                <div className='my-10'>
                  <PaginationControl
                    page={pagination.page}
                    total={pagination.totalResults}
                    limit={pagination.limit}
                    changePage={handlePaginationChange}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default AccountsPage
