import clsx from 'clsx'
import {Field} from 'formik'
import {useAuthStore} from '../app/modules/auth/core/authStore'

const EmailInput = (props: any) => {
  const {name, exclude} = props
  const {emailExists} = useAuthStore()
  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        return (
          <input
            type='email'
            className={clsx('form-control', {'is-invalid': meta.error})}
            {...field}
            {...props}
            onBlur={async (event) => {
              // if the email is the same as the exclude value, don't check
              if (exclude && event.target.value === exclude) return

              // set the form as touched
              form.setFieldTouched(name, true)
              form.setSubmitting(true)

              // check if email exists
              const response = await emailExists(event.target.value)
              if (response === true) {
                form.setFieldError(
                  field.name,
                  'This email address is already in use. Please enter another email address.'
                )
              }
              form.setSubmitting(false)
            }}
          />
        )
      }}
    </Field>
  )
}
export default EmailInput
