import {Route, Routes, Navigate} from 'react-router-dom'
import {PatronPage} from '../modules/patrons/PatronPage'
import {GuestLayout} from '../../_metronic/layout/GuestLayout'
import {SingleBookingPage} from '../modules/bookings/SingleBookingPage'

const PatronRoutes = () => {
  return (
    <Routes>
      <Route element={<GuestLayout />}>
        {/* redirect to patron page after successful patron login  */}
        <Route path='auth/*' element={<Navigate to='/patron' />} />

        {/* ALL ROUTE INCLUDE patron/ ) */}
        <Route path='bookings/:bookingId/*' element={<SingleBookingPage />} />
        <Route path='patron' element={<PatronPage />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PatronRoutes}
