import {FC, useEffect, useState} from 'react'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {useEventsStore} from '../events/core/eventsStore'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {Button, Modal, Toast} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import SubmitButton from '../../../_components/SubmitButton'
import * as Yup from 'yup'
import {initialScanner, useScannersStore} from './core/scannerStore'
import {useNavigate, useParams} from 'react-router-dom'
import {trimCharacters} from '../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import clsx from 'clsx'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {ScannerOptions} from './Partials/ScannerOptions'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'

const validationSchema = Yup.object({
  name: Yup.string().required('Scanner name is required'),
})

const ScannersPage: FC = () => {
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {getEvent, currentEvent, checkpoints, getCheckpoints} = useEventsStore()
  const {
    currentScanner,
    scanners,
    unsetCurrentScanner,
    setCurrentScanner,
    deleteScanner,
    getScanners,
    saveScanner,
    pagination,
  } = useScannersStore()
  const {users, getUsers} = useAccountsUsersStore()
  const {eventId} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!eventId) return

    if (eventId) {
      setIsLoading(true)
      getUsers(currentEvent.account)
      getCheckpoints(eventId, {limit: 100, page: 1})
      getScanners(eventId, {limit: 100, page: 1}).finally(() => {
        setIsLoading(false)
      })
      getEvent(eventId).then(() => {})
    }

    // eslint-disable-next-line
  }, [])

  // set breadcrumbs
  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `${trimCharacters(currentEvent.name, 12)}`,
        title: `${trimCharacters(currentEvent.name, 12)}`,
        link: `/events/${eventId}`,
      },
      {
        object: 'Scanners',
        title: `Scanners`,
        link: `/events/${eventId}/scanners`,
      },
    ]

    setBreadcrumbs(crumbs)
    setCurrentPage('Scanners')

    // eslint-disable-next-line
  }, [currentEvent])

  const handleSubmit = async (values, action) => {
    if (!eventId) return

    setIsSubmitting(true)

    try {
      const scanner = {
        ...values,
        user: values.user.id || values.user,
        checkpoint: values.checkpoint.id || values.checkpoint,
      }
      await saveScanner(eventId, scanner)
      setShow(false)
    } catch (err) {
      toast.error('There was an error when saving the scanner')
    }

    setIsSubmitting(false)
  }

  const handleDelete = async (scannerId) => {
    if (!eventId || !scannerId) return

    try {
      setCurrentScanner(scannerId)
      await deleteScanner(eventId, scannerId)
      unsetCurrentScanner()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = async (scannerId) => {
    if (!eventId || !scannerId) return

    setCurrentScanner(scannerId)
    setShow(true)
  }

  const handleScanTickets = async (scannerId) => {
    if (!eventId || !scannerId) return

    // open scanner in new tab
    navigate(`/event/${eventId}/scanners/${scannerId}/scan`, {replace: true})
  }

  return (
    <>
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          <div className='d-flex mb-10'>
            <div className='d-flex flex-grow-1 align-items-start flex-column'>
              <h3 className='fs-3 fw-bold'>{scanners.length} Scanners</h3>
              <p>
                Pg {pagination.page} of {pagination.totalPages}
              </p>
              {/* <span className='fs-5 text-muted'>{`showing page ${pagination.page} of ${pagination.totalPages}`}</span> */}
            </div>
            <div className='d-flex flex-rows align-items-end'>
              <button
                onClick={() => {
                  setShow(true)
                }}
                className='btn btn-light-info'
              >
                <KTIcon iconName='plus-circle' iconType='outline' className='me-2' />
                Add Scanner
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* LIST OF SCANNERS */}

      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {' '}
          {scanners.length > 0 ? (
            <>
              {isMobileDevice() ? (
                <>
                  {scanners.map((scanner, index) => (
                    <div key={index} className='card mb-5'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <div className='d-flex'>
                            <h3>{scanner.name}</h3>
                          </div>
                          <div className='d-flex me-2 p-3'>
                            <span
                              className={clsx('badge', {
                                'badge-light-success': scanner.isActive,
                                'badge-light-danger': !scanner.isActive,
                              })}
                            >
                              {scanner.isActive ? 'Active' : 'Disabled'}
                            </span>
                          </div>
                        </div>
                        <div className='card-toolbar d-flex flex-grow-1 justify-content-end'>
                          <ScannerOptions
                            onDeleteScanner={handleDelete}
                            onEditScanner={handleEdit}
                            scanner={scanner}
                            eventId={eventId || ''}
                          />
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-row flex-wrap mb-5'>
                            <div className='d-flex me-2 p-3'>
                              <KTIcon iconName='user' iconType='outline' className='me-2 fs-2' />
                              <span>{scanner.user.displayName}</span>
                            </div>
                            <div className='d-flex me-2 p-3'>
                              <KTIcon
                                iconName='check-circle'
                                iconType='outline'
                                className='me-2 fs-2'
                              />
                              <span>{scanner.checkpoint.name}</span>
                            </div>
                            {scanner.checksCount > 0 && (
                              <div className='d-flex me-2 p-3'>
                                <KTIcon iconName='check' iconType='outline' className='me-2' />
                                <span>{scanner.checksCount} Checks</span>
                              </div>
                            )}
                          </div>
                          <div className='d-flex flex-row w-100'>
                            <button
                              className='btn btn-light-info w-100'
                              onClick={() => {
                                handleScanTickets(scanner.id)
                              }}
                            >
                              <KTIcon iconName='barcode' iconType='outline' className='me-2' />
                              Scan Tickets
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className=''>Scanner Name</th>
                          <th className=''>User</th>
                          <th className=''>Checkpoint</th>
                          <th className=''>Status</th>
                          <th className='text-start'>Progress</th>
                          <th className='text-end'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {scanners.map((scanner, index) => (
                          <tr key={`scanner-${index}`}>
                            <td>{scanner.name}</td>
                            <td>{scanner.user.displayName}</td>
                            <td>{scanner.checkpoint.name}</td>
                            <td className='text-start'>
                              <span
                                className={clsx('badge', {
                                  'badge-light-success': scanner.isActive,
                                  'badge-light-danger': !scanner.isActive,
                                })}
                              >
                                {scanner.isActive ? 'Active' : 'Disabled'}
                              </span>
                            </td>
                            <td className='text-start'>
                              <span className='badge badge-light-primary'>
                                {scanner.checksCount} Checks
                              </span>
                            </td>
                            <td className='text-end'>
                              <ScannerOptions
                                onDeleteScanner={handleDelete}
                                onEditScanner={handleEdit}
                                scanner={scanner}
                                eventId={eventId || ''}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  <div className='d-flex pt-10 px-10'>
                    <KTIcon
                      className='svg-icon-3x svg-icon-primary mb-5 '
                      iconName='flaticon-safebox-1'
                    />
                    <h3 className='fs-2x fw-bolder text-center text-dark'>
                      You are at the right place to start scanning tickets.
                    </h3>
                  </div>
                  <div className='fs-4 text-muted text-center p-5'>
                    First setup your scanners and assign them to checkpoints and users. You can
                    register as many scanners as you want. This is a security measure that allows
                    you to track who is scanning tickets and how many they have scanned.
                  </div>
                  <div className='d-flex flex-row p-5 '>
                    <button
                      className='btn btn-info'
                      onClick={() => {
                        setShow(true)
                      }}
                    >
                      Add Scanner
                    </button>
                    {/* <button
                onClick={() => navigate('/bookings/create')}
                className='btn btn-primary btn-lg'
              >
                Create Booking
              </button> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* MODAL */}

      <Modal
        show={show}
        className='modal-md'
        centered
        onHide={() => {
          setShow(false)
        }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={currentScanner || initialScanner}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {(form) => {
            const handleChangeCheckpoint = (e) => {
              form.setFieldValue(
                'checkpoint',
                e.target.value.id ? e.target.value.id : e.target.value
              )
            }
            const handleChangeUser = (e) => {
              form.setFieldValue('user', e.target.value.id ? e.target.value.id : e.target.value)
            }

            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Add Scanner</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className='fv-row mb-10'>
                    <label className='form-label required'>Scanner Name</label>

                    <Field name='name' className='form-control border form-control-lg' />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='name' />
                    </div>
                  </div>

                  <div className='fv-row mb-10'>
                    <label className='fs-6 fw-bold form-label required'>User</label>
                    <span className='text-muted fs-7 fw-bold'>
                      Select a user to assign the scanner to
                    </span>

                    <Field
                      as='select'
                      name='user'
                      className='form-select border form-select-lg'
                      value={form.values.user.id}
                      onChange={handleChangeUser}
                    >
                      <option value=''>Select User</option>
                      {users.map((user, index) => (
                        <option key={index} value={user.id}>
                          {user.displayName}
                        </option>
                      ))}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='user' />
                    </div>
                  </div>

                  <div className='fv-row mb-10'>
                    <label className='fs-6 fw-bold form-label required'>Checkpoint</label>
                    <span className='text-muted fs-7 fw-bold'>
                      Select checkpoint associated with this scanner
                    </span>
                    <Field
                      as='select'
                      name='checkpoint'
                      className='form-select border form-select-lg'
                      value={form.values.checkpoint.id}
                      onChange={handleChangeCheckpoint}
                    >
                      <option key={'u-qwer'} value=''>
                        Select Checkpoint
                      </option>
                      {checkpoints.map((checkpoint, index) => (
                        <option key={index} value={checkpoint.id}>
                          {checkpoint.name}
                        </option>
                      ))}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='checkpoint' />
                    </div>
                  </div>

                  <div className='fv-row mt-10 mb-10'>
                    <div className='row'>
                      <div className='col'>
                        <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                          <Field
                            className='form-check-input me-3'
                            type='checkbox'
                            name='isActive'
                          />

                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fs-5 text-dark mb-0'>Enable Scanner</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    Close
                  </Button>

                  <SubmitButton
                    isSubmitting={isSubmitting}
                    isValid={form.isValid}
                    label='Save Scanner'
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default ScannersPage
