import React, {useState} from 'react'
import {ErrorMessage, Form, Field, Formik, FormikValues} from 'formik'
import clsx from 'clsx'

import SubmitButton from '../../../../_components/SubmitButton'
import {useEventsStore} from '../core/eventsStore'
import * as Yup from 'yup'
import DateInput from '../../../../_components/DateInput'

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const BookingsSettings = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent} = useEventsStore()

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Account successfully updated')

        // emit
        if (props.onSave) props.onSave(values)

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_bookings_settings'
        aria-expanded='true'
        aria-controls='kt_bookings_settings'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step}. Bookings Experience</h3>
        </div>
      </div>

      <div id='kt_bookings_settings' className='show'>
        <Formik initialValues={currentEvent} onSubmit={handleSubmit} enableReinitialize>
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-9'>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                            <Field
                              className='form-check-input me-3'
                              type='checkbox'
                              name='issueTickets'
                            />

                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder fs-5 text-dark mb-0'>
                                Issue Smart Tickets
                              </span>
                              <span className='text-muted fs-6'>
                                Issue scannable QR Code tickets when patrons successfully book for
                                tickets
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                            <Field
                              className='form-check-input me-3'
                              type='checkbox'
                              name='autoApprove'
                            />

                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder fs-5 text-dark mb-0'>
                                Automatically Approve Bookings
                              </span>
                              <span className='text-muted fs-6'>
                                If checked new bookings will be automatically approved. Else, they
                                will be set to pending and require manual approval.
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {BookingsSettings}
