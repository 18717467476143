import {FC, useState, useEffect} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {WithChildren} from '../../../../_metronic/helpers'
import {useAuthStore} from './authStore'
import {usePatronStore} from '../../patrons/core/patronStore'
import {useClientStore} from '../../clients/core/clientStore'

const AuthInit: FC<WithChildren> = ({children}) => {
  // const {auth, logout, setCurrentUser, saveAccountsUsers, setCurrentAccountsUsers} = useAuth()
  const {reload} = useAuthStore()
  const {loadPatronFromToken} = usePatronStore()
  const {loadClientFromToken} = useClientStore()
  const [userChecked, setUserChecked] = useState(false)
  const [patronChecked, setPatronChecked] = useState(false)

  useEffect(() => {
    // Checks if token is still valid and tries to refresh it.  If all fails, it will logout the user.
    reload().finally(() => setUserChecked(true))
    loadPatronFromToken().finally(() => setPatronChecked(true))
    loadClientFromToken()
  }, [reload, loadPatronFromToken, loadClientFromToken])

  return !userChecked || !patronChecked ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit}
