import {useEffect, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {
  daysUntilDate,
  formatDateDMYHI,
  formatMoney,
  trimCharacters,
} from '../../../_helpers/_helpers'

import {RequestPayoutButton} from '../../modules/transactions/partials/RequestPayoutButton'
import {
  initialPayout,
  PayoutModel,
  usePayoutsStore,
} from '../../modules/transactions/core/payoutsStore'
import {useStatementStore} from '../../modules/transactions/core/statementsStore'
import toast, {Toaster} from 'react-hot-toast'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {Modal} from 'react-bootstrap'

const options = [
  {
    action: 'paid',
    label: 'Mark as Paid',
    icon: 'check-circle',
    iconColor: 'success',
  },

  {
    action: 'hold',
    label: 'Put On Hold',
    icon: 'minus-circle',
    iconColor: 'warning',
  },

  {
    action: 'reverse',
    label: 'Reverse Payout',
    icon: 'arrow-circle-left',
    iconColor: 'info',
  },

  {
    action: 'cancel',
    label: 'Cancel Payout',
    icon: 'cross-circle',
    iconColor: 'danger',
  },
]

const AdminSettlementsPage = () => {
  const {pending, pagination, adminPayouts, postAdminPayouts, patchSettlement, getAdminPayouts} =
    usePayoutsStore()
  const {getBalance} = useStatementStore()
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const [payoutsChecked, setPayoutsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingPayouts, setIsGettingPayouts] = useState(false)
  const [payoutLoading, setPayoutLoading] = useState(false)
  const [currentPayout, setCurrentPayout] = useState<PayoutModel | null>(null)
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return
    setIsLoading(true)
    Promise.all([
      getAdminPayouts(pagination),
      getBalance(selectedAccountsUsers.account.id),
    ]).finally(() => {
      setIsLoading(false)
      setPayoutsChecked(true)
    })

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: PageDataModel[] = [
      {
        object: 'Settlements',
        title: 'Settlements',
        link: '/admin/settlements',
      },
    ]
    setCrumbs(crumbs)
  }, [adminPayouts])

  const handlePayoutAdded = () => {
    setIsLoading(true)
    getAdminPayouts(pagination).finally(() => {
      setIsLoading(false)
    })
  }

  const handlePaginationChange = (page: number) => {
    setIsLoading(true)
    getAdminPayouts({...pagination, page}).finally(() => {
      setIsLoading(false)
    })
  }

  const handlePayoutAction = async (payout: PayoutModel, action: string) => {
    if (!payout.id) return false

    // SETUP STATUSES
    setPayoutLoading(true)
    setCurrentPayout(payout)

    // DO ACTION
    try {
      await patchSettlement(action, payout.id, {status: action})
      await getAdminPayouts({...pagination, page: 1})

      setPayoutLoading(false)
      setCurrentPayout(null)
    } catch (err) {
      setPayoutLoading(false)
      setCurrentPayout(null)
    }

    // close modal
    setShowModal(false)
  }

  const handleShowModal = (payout) => {
    setCurrentPayout(payout)
    setShowModal(true)
  }

  const handlePostPayouts = async () => {
    try {
      setIsGettingPayouts(true)
      await postAdminPayouts()
      await getAdminPayouts({...pagination, page: 1})
      setIsGettingPayouts(false)
    } catch (err) {
      toast.error('There was a problem fetching payouts')
      setIsGettingPayouts(false)
    }
  }

  return (
    <>
      <Toaster />
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex justify-content-between mb-10'>
            <div className='d-flex flex-column'>
              <div className='account-balance fw-bolder fs-1 text-warning text-start'>
                {formatMoney(pending, selectedAccountsUsers.account.currency, 0)}
              </div>
              <span className='fs-5 text-muted'>{`showing ${adminPayouts.length} of ${pagination.totalResults}`}</span>
            </div>

            <div className='d-flex'>
              {/* get payouts */}
              <div className='d-flex flex-column'>
                <button
                  className='btn btn-light btn-active-warning btn-sm me-3'
                  onClick={handlePostPayouts}
                >
                  {isGettingPayouts ? (
                    <span
                      className='spinner-border spinner-border-sm me-2'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <KTIcon className='fs-3 mb-2 ' iconType='outline' iconName='cheque' />
                  )}
                  Get Payouts
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PAYOUTS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {payoutsChecked === true && adminPayouts.length === 0 ? (
            <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='d-flex'>
                  <KTIcon
                    className='svg-icon-3x svg-icon-primary mb-5 '
                    iconName='flaticon-safebox-1'
                  />
                  <span className='fs-2x fw-bolder text-dark'>No payouts have been made yet.</span>
                </div>
                <div className='fs-5 text-muted text-center'>
                  You can request a payout by clicking the button below.
                </div>
                <div className='d-flex flex-row p-5 '>
                  <RequestPayoutButton onPayoutAdded={handlePayoutAdded} />
                </div>
              </div>
            </div>
          ) : (
            <>
              {isMobileDevice() ? (
                <div className='d-flex w-100 flex-column'>
                  {adminPayouts.map((payout) => (
                    <div
                      className='card card-custom card-stretch mb-2 w-100'
                      onClick={() => handleShowModal(payout)}
                    >
                      <div className='card-header px-5 w-100 d-flex'>
                        <div className='card-title w-100 d-flex'>
                          <div className='d-flex align-items-center w-100'>
                            <div className='symbol symbol-45px me-5'>
                              <span
                                className={clsx('symbol-label text-light fw-bolder', {
                                  'bg-success': payout.status === 'complete',
                                  'bg-warning': payout.status === 'pending',
                                  'bg-info': payout.status === 'holding',
                                  'bg-danger': payout.status === 'cancelled',
                                })}
                              >
                                {payout.account?.name.charAt(0).toUpperCase()}
                              </span>
                            </div>

                            <div className='d-flex flex-column flex-grow-1 justify-content-center'>
                              <h2 className='card-label fs-5 d-flex align-items-center'>
                                <span className='fw-bolder me-1'>
                                  {trimCharacters(payout.account ? payout.account.name : '', 20)}
                                </span>
                                {payout.account?.isVerified && (
                                  <KTIcon
                                    iconName='verify'
                                    iconType='solid'
                                    className='fs-1 text-dark'
                                  />
                                )}
                              </h2>
                              <div className='d-flex flex-column'>
                                <span className='text-muted me-2 mt-2 fs-7'>
                                  Created {formatDateDMYHI(payout?.dateCreated?.toString())}
                                </span>
                                {payout.status === 'complete' && (
                                  <span className='text-muted me-2 fs-7'>
                                    Paid {formatDateDMYHI(payout.dateCreated?.toString())} ago
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className='d-flex flex-column align-items-end'>
                              <span className='fs-3'>
                                {formatMoney(payout.amount, payout.account?.currency, 0)}
                              </span>
                              <span
                                className={clsx('badge', {
                                  'badge-light-success': payout.status === 'complete',
                                  'badge-light-warning': payout.status === 'pending',
                                  'badge-light-info': payout.status === 'holding',
                                  'badge-light-danger': payout.status === 'cancelled',
                                })}
                              >
                                {payout.status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='ps-5'>Request Date</th>
                      <th className='ps-5'>Updated Date</th>
                      <th>Accounts & Request</th>
                      <th className='text-end'>Balances</th>
                      <th className='text-end pe-10'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminPayouts.map((payout) => (
                      <tr key={payout.id}>
                        <td className='ps-5'>
                          <div className='d-flex align-items-center'>
                            <span
                              className={clsx('badge badge-circle w-10px h-10px me-5', {
                                'badge-success': payout.status === 'complete',
                                'badge-warning': payout.status === 'pending',
                                'badge-info': payout.status === 'holding',
                                'badge-danger': payout.status === 'cancelled',
                              })}
                            />
                            <div className='d-flex flex-column'>
                              <span className='fw-bolder'>
                                {daysUntilDate(payout.dateCreated, true)} ago
                              </span>
                              <span className='fw-normal'>
                                {formatDateDMYHI(payout?.dateCreated?.toString())}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className='ps-5'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column'>
                              <span className='fw-bolder'>
                                {daysUntilDate(payout.dateUpdated, true)} ago
                              </span>
                              <span className='fw-normal'>
                                {formatDateDMYHI(payout?.dateUpdated?.toString())}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex flex-column'>
                            <span className='fw-bold'>
                              Account: {payout.account?.name || 'Unknown'}
                              <span
                                className={clsx('ms-2 badge', {
                                  'badge-success': payout.status === 'complete',
                                  'badge-warning': payout.status === 'pending',
                                  'badge-info': payout.status === 'holding',
                                  'badge-danger': payout.status === 'cancelled',
                                })}
                              >
                                {payout.status}
                              </span>
                            </span>
                            <span>{payout.notes}</span>
                          </div>
                        </td>

                        <td className='text-end'>
                          <div className='d-flex flex-column'>
                            <span className='fw-bold'>
                              Payout Request:{' '}
                              {formatMoney(
                                payout.amount,
                                selectedAccountsUsers.account.currency,
                                2
                              )}
                            </span>
                            <span className='text-muted'>
                              Account Balance:{' '}
                              {formatMoney(
                                payout?.statementBalance,
                                selectedAccountsUsers.account.currency,
                                2
                              )}
                            </span>
                          </div>
                        </td>

                        <td className='text-end pe-5'>
                          {payout.status !== 'cancelled' && (
                            <button
                              className='btn btn-secondary btn-sm'
                              onClick={() => handleShowModal(payout)}
                            >
                              <KTIcon iconName='abstract-14' iconType='solid' />
                              Option
                            </button>
                          )}
                          {/* Add this button */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {/* table  */}

              {adminPayouts.length < pagination.totalResults && (
                <div className='my-10'>
                  <PaginationControl
                    page={pagination.page}
                    total={pagination.totalResults}
                    limit={pagination.limit}
                    changePage={handlePaginationChange}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {/*PAYOUT ACTION MODAL */}
      <Modal size='sm' centered={true} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{currentPayout?.account?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column mb-5'>
            <div className='d-flex justify-content-between'>
              <span className='fw-bold'>Payout Request</span>
              <span className='fw-normal'>
                {formatMoney(currentPayout?.amount, selectedAccountsUsers.account.currency, 2)}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='fw-bold'>Account Balance</span>
              <span className='fw-normal'>
                {formatMoney(
                  currentPayout?.statementBalance,
                  selectedAccountsUsers.account.currency,
                  2
                )}
              </span>
            </div>
          </div>
          <div className='d-flex w-100 flex-column'>
            {options.map((option, index) => {
              let showOption = false
              switch (currentPayout?.status) {
                case 'complete':
                  showOption = option.action === 'reverse'
                  break
                case 'pending':
                  showOption =
                    option.action === 'paid' ||
                    option.action === 'hold' ||
                    option.action === 'cancel'
                  break
                case 'holding':
                  showOption = option.action === 'paid' || option.action === 'cancel'
                  break
                case 'cancelled':
                  showOption = false
                  break
                default:
                  showOption = false
              }

              return (
                <>
                  {showOption && (
                    <div
                      className={clsx(`btn btn-${option.iconColor} w-100 mb-2`, {
                        active: currentPayout?.status === option.action,
                      })}
                      onClick={() =>
                        handlePayoutAction(
                          currentPayout ? currentPayout : {...initialPayout},
                          option.action
                        )
                      }
                    >
                      <div className='d-flex justify-content-center'>
                        {payoutLoading ? (
                          <span
                            className='spinner-border spinner-border-sm mt-1 me-2'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <span className='menu-icon' data-kt-element='icon'>
                            <KTIcon
                              iconName={option.icon}
                              iconType='solid'
                              className={`fs-1 me-1`}
                            />
                          </span>
                        )}

                        <span className='menu-title'>{option.label}</span>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminSettlementsPage
