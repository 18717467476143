import axios from "axios"

export const PATRONS_ENDPOINT = `/patrons`

export type CreatePatronType = {
    name: string,
    phone: number,
    email: string,
    isBanned: boolean,
}
export function _postPatron(eventId, args: CreatePatronType) {
    return axios.post(`${PATRONS_ENDPOINT}/${eventId}`, args)
}

export type UpdatePatronType = {
    name: string,
    phone: number,
    email: string,
    isBanned: boolean,
}
export const initialUpdatePatronType: UpdatePatronType = {
    name: '',
    phone: 0,
    email: '',
    isBanned: false,
}

export function _patchPatron(id, args: Partial<UpdatePatronType>) {
    return axios.patch(`${PATRONS_ENDPOINT}/${id}/`, args)

}

export function _getPatronMe() {
    return axios.get(`${PATRONS_ENDPOINT}/me`)
}

export function _searchPatron(search) {
    return axios.get(`${PATRONS_ENDPOINT}/search/`, { params: { ...search } })
}

export function _deletePatron(id) {
    return axios.delete(`${PATRONS_ENDPOINT}/${id}/`)
}


export function _createPatronOTP(type, data) {
    return axios.post(`${PATRONS_ENDPOINT}/otp/`, {
        [type]: data
    })
}

export function _verifyPatronOTP(OTP: number) {
    return axios.post(`${PATRONS_ENDPOINT}/otp/verify/`, {
        OTP
    })
}

