import {Field} from 'formik'

const NumberInput = (props: any) => {
  const {name, min, max, ...rest} = props

  return (
    // input group
    <Field name={name}>
      {({field, form, meta}: any) => {
        const handleIncrement = () => {
          if (field.value < max) {
            form.setFieldValue(name, field.value + 1)
            // setValue(value  1)
          }
        }

        const handleDecrement = () => {
          if (field.value > min) {
            form.setFieldValue(name, field.value - 1)
            // setValue(value + 1)
          }
        }

        return (
          <div className='input-group'>
            <input
              {...field}
              {...rest}
              min={min}
              max={max}
              name={name}
              type='number'
              value={field.value}
              className='form-control'
            />
            <button type='button' className='btn btn-secondary ' onClick={handleDecrement}>
              -
            </button>
            <button type='button' className='btn btn-secondary' onClick={handleIncrement}>
              +
            </button>
          </div>
        )
      }}
    </Field>
  )
}

export default NumberInput
