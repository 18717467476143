import React, {useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import * as Yup from 'yup'
import 'react-quill/dist/quill.snow.css' // import styles
import {useEventsStore} from '../core/eventsStore'
import 'react-autocomplete-input/dist/bundle.css'
import NumberInput from '../../../../_components/NumberInput'
import QuillEditor from '../../../../_components/QuillEditor'

// write validation schema with yup
const validationSchema = Yup.object().shape({
  pendingNotification: Yup.string().required('Pending notification is required'),
  pendingEmail: Yup.string().required('Pending email is required'),

  completedNotification: Yup.string().required('completed notification is required'),
  completedEmail: Yup.string().required('Completed email is required'),

  cancelledNotification: Yup.string().required('Cancelled notification is required'),
  cancelledEmail: Yup.string().required('Cancelled notification is required'),

  beforeNotification: Yup.string().required('Reminder notification is required'),
  beforeEmail: Yup.string().required('Reminder notification is required'),
  beforeDays: Yup.number(),

  afterNotification: Yup.string().required('Thank you notification is required'),
  afterEmail: Yup.string().required('Thank you notification is required'),
  afterDays: Yup.number(),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const EventNotifications = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent} = useEventsStore()

  const placeholders = [
    'bookingName',
    'eventName',
    'eventHandle',
    'eventStartDate',
    'eventEndDate',
    'organiserName',
    'organiserEmail',
    'organiserPhone',
    'organiserWebsite',
    'bookingLink',
    'eventDate',
    'skybookingsPhoneLink',
    'skybookingsEmailLink',
  ]

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Event successfully updated')

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_event_notifications'
        aria-expanded='true'
        aria-controls='kt_event_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Notifications</h3>
        </div>
      </div>

      <div id='kt_event_notifications' className='show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  <div className='card-body border-top p-9'>
                    <div className='accordion accordion-flush' id='accordionFlushExample'>
                      {/* start:: notifications */}
                      <div className='accordion-item mb-3'>
                        <h2 className='accordion-header' id='flush-headingOne'>
                          <button
                            className='accordion-button collapsed bg-light fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseOne'
                            aria-expanded='false'
                            aria-controls='flush-collapseOne'
                          >
                            <div className='d-flex flex-column'>
                              <span className='fw-bold'>#1 Pending Booking</span>
                              <span className='text-muted fs-7 mt-2'>
                                This notification will be sent when an event is set as pending
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id='flush-collapseOne'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingOne'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>

                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='pendingNotification'
                                  />
                                  <ErrorMessage name='pendingNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Notification</label>
                                  <QuillEditor name='pendingEmail' suggestions={placeholders} />

                                  <ErrorMessage name='pendingEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end:: notifictions */}

                      {/* start:: Completed */}
                      <div className='accordion-item mb-3'>
                        <h2 className='accordion-header' id='flush-headingTwo'>
                          <button
                            className='accordion-button collapsed bg-light fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseTwo'
                            aria-expanded='false'
                            aria-controls='flush-collapseTwo'
                          >
                            <div className='d-flex flex-column'>
                              <span className='fw-bold'>#2 Completed Booking</span>
                              <span className='text-muted fs-7 mt-2'>
                                This notification will be sent when an event is set as completed
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id='flush-collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingTwo'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>
                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='completedNotification'
                                  />
                                  <ErrorMessage name='completedNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Notification</label>
                                  <QuillEditor name='completedEmail' suggestions={placeholders} />

                                  <ErrorMessage name='completedEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end:: Completed */}

                      {/* start:: Cancelled */}
                      <div className='accordion-item mb-3'>
                        <h2 className='accordion-header' id='flush-headingThree'>
                          <button
                            className='accordion-button collapsed bg-light fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseThree'
                            aria-expanded='false'
                            aria-controls='flush-collapseThree'
                          >
                            <div className='d-flex flex-column'>
                              <span className='fw-bold'>#3 Cancelled Booking</span>
                              <span className='text-muted fs-7 mt-2'>
                                This notification will be sent when an event is set as cancelled
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id='flush-collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingThree'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>

                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='cancelledNotification'
                                  />
                                  <ErrorMessage name='cancelledNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Notification</label>
                                  <QuillEditor name='cancelledEmail' suggestions={placeholders} />

                                  <ErrorMessage name='cancelledEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end:: cancelled */}

                      {/* start:: Aide Memoire */}
                      <div className='accordion-item mb-3'>
                        <h2 className='accordion-header' id='flush-headingFour'>
                          <button
                            className='accordion-button collapsed bg-light fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseFour'
                            aria-expanded='false'
                            aria-controls='flush-collapseFour'
                          >
                            <div className='d-flex flex-column'>
                              <span className='fw-bold'>#4 Reminder (Aide Memoire)</span>
                              <span className='text-muted fs-7 mt-2'>
                                This notification will be sent before the event
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id='flush-collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingFour'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    When to send reminder:
                                    <span className='text-muted fw-normal ms-3'>
                                      The number of days before the event to send the reminder
                                    </span>
                                  </label>
                                  <NumberInput name='beforeDays' min={1} max={10} />
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>
                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='beforeNotification'
                                  />
                                  <ErrorMessage name='beforeNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Notification</label>
                                  <QuillEditor name='beforeEmail' suggestions={placeholders} />

                                  <ErrorMessage name='beforeEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end:: Aide Memoire */}

                      {/* start:: Thank you Message */}
                      <div className='accordion-item mb-3'>
                        <h2 className='accordion-header' id='flush-headingThree'>
                          <button
                            className='accordion-button collapsed bg-light fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseFive'
                            aria-expanded='false'
                            aria-controls='flush-collapseFive'
                          >
                            <div className='d-flex flex-column'>
                              <span className='fw-bold'>#5 Thank you message</span>
                              <span className='text-muted fs-7 mt-2'>
                                This notification will be sent after the event.
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          id='flush-collapseFive'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingThree'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    When to send thank you message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>
                                  <NumberInput name='afterDays' min={0} max={10} />
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms, whatsapp and throughout the app
                                    </span>
                                  </label>
                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='afterNotification'
                                  />
                                  <ErrorMessage name='afterNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Notification</label>
                                  <QuillEditor name='afterEmail' suggestions={placeholders} />

                                  <ErrorMessage name='afterEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end:: Thank you message */}
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EventNotifications}
