import {FC} from 'react'
import {EventModel} from '../app/modules/events/core/eventsStore'

type Props = {
  event: EventModel
  size: number
}

const EventThumbnail: FC<Props> = ({event, size}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${event.artwork})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'black',
      }}
      className={`w-${size}px h-${size}px symbol symbol-${size}px me-3`}
    />
  )
}
export default EventThumbnail
