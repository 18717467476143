import {AsyncTypeahead, RenderMenuItemChildren} from 'react-bootstrap-typeahead'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useAccountsStore} from '../core/accountsStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {Toaster} from 'react-hot-toast'

const AccountsSearchBar = () => {
  // create page like google with a search bar using bs5 flex
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<RenderMenuItemChildren[]>([])
  const {searchResults, searchAccounts} = useAccountsStore()
  const {switchAdminToUser} = useAccountsUsersStore()
  const navigate = useNavigate()

  const handleChange = async (selected: any) => {
    // get account from search results
    const account = searchResults.find((account) => account.id === selected[0].id)
    if (!account) return false

    switchAdminToUser(account)
    navigate('/dashboard')
  }

  const handleSearch = async (query: string) => {
    try {
      setIsLoading(true)

      const e = await searchAccounts(query)

      const optionItems: any = e.map((account) => {
        return {
          id: account.id,
          organiser: account.handle,
          login: account.name,
          artwork: account.image,
        }
      })

      setOptions(optionItems)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const filterBy = () => true
  return (
    <>
      <Toaster />

      <div className='d-flex flex-column w-100'>
        <AsyncTypeahead
          className='min-w-lg-250px w-100 account-search-bar'
          filterBy={filterBy}
          id='search-bar-list'
          isLoading={isLoading}
          labelKey='login'
          minLength={3}
          onChange={handleChange}
          onSearch={handleSearch}
          options={options}
          placeholder='Search accounts by name or handle'
          renderMenuItemChildren={(option: any) => (
            <>
              <div className='d-flex bg-hover-light'>
                <div
                  style={{
                    backgroundImage: `url(${option.artwork})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'black',
                  }}
                  className='w-50px h-50px me-5 symbol symbol-50px'
                />
                <div className='d-flex flex-column'>
                  <span className='fs-5 fw-bold text-dark'>{option.login}</span>
                  <span className='fs-7 text-muted'>by {option.organiser}</span>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </>
  )
}
export default AccountsSearchBar
