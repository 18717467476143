/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {ResetPassword} from './components/ResetPassword'
import {ForgotPassword} from './components/ForgotPassword'
import {VerifyEmail} from './components/VerifyEmail'
import {Login} from './components/Login'
import {RegistrationWizardWrapper} from './components/RegistrationWizardWrapper'
import clsx from 'clsx'
import {SelectAccount} from './components/SelectAccount'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {AcceptUserInvite} from './components/AcceptUserInvite'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center justify-content-start flex-column flex-column-fluid p-0 pb-lg-20 m-5'>
        {/* begin::Wrapper */}
        <div
          className={clsx('rounded p-5 p-lg-15 mx-auto', {
            'w-100': isMobileDevice(),
          })}
        >
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='patron' element={<Login />} />
        <Route path='client' element={<Login />} />
        {/* <Route path='registration' element={<Registration />} /> */}
        <Route path='registration' element={<RegistrationWizardWrapper />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='verify-email' element={<VerifyEmail />} />
        <Route path='select-account' element={<SelectAccount />} />
        <Route path='accept-invite' element={<AcceptUserInvite />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
