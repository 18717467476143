import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { UserModel, userInitialValues } from "../../settings/core/accountsUsersStore"
import { _deleteScanner, _getScanner, _getScanners, _patchScanner, _postScanner } from "./scannersApi"
import { CheckpointModel, initialCheckpoint } from "../../events/core/eventsStore"
import { pick } from "../../../../_helpers/_helpers"
import { CheckApiModel, _postCheck } from "../../guests/core/checksApi"

export type ScannerModel = {
    id: string
    name: string,
    user: UserModel,
    event: string,
    isActive: boolean,
    checkpoint: CheckpointModel,
    checksCount: number
}

export type Pagination = {
    alphabet: string,
    page: number,
    limit: number,
    totalPages: number,
    totalResults: number,
}

export const initialPagination: Pagination = {
    alphabet: 'A',
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
}

export const initialScanner: ScannerModel = {
    id: '',
    name: '',
    user: { ...userInitialValues },
    event: '',
    isActive: false,
    checkpoint: { ...initialCheckpoint },
    checksCount: 0
}


type ScannersStore = {
    currentScanner: ScannerModel,
    scanners: ScannerModel[],
    pagination: Pagination,

    getScanner: (eventId: string, scannerId: string) => Promise<void>,
    getScanners: (eventId: string, query: any) => Promise<void>,
    saveScanner: (eventId: string, scanner: ScannerModel) => Promise<void>,
    postScanner: (eventId: string, scanner: ScannerModel) => Promise<void>,
    patchScanner: (eventId: string, scanner: Partial<ScannerModel>) => Promise<void>,
    deleteScanner: (eventId: string, scannerId: string) => Promise<void>,
    setCurrentScanner: (scannerId: string) => void,
    unsetCurrentScanner: () => void
    postCheck: (eventId: string, scannerId: string, ticketId: string) => Promise<any>
}


const createStore: StateCreator<ScannersStore> = (set, get) => ({
    currentScanner: { ...initialScanner },
    scanners: [],
    pagination: { ...initialPagination },
    saveScanner: async (eventId, scanner: ScannerModel) => {
        if (!scanner.id) {

            get().postScanner(eventId, scanner)
        }
        else {
            get().patchScanner(eventId, scanner)
        }
    },
    deleteScanner: async (eventId: string, scannerId: string) => {

        await _deleteScanner(eventId, scannerId)
        const scanners = get().scanners.filter(s => s.id !== scannerId)
        set({ scanners })

    },

    postScanner: async (eventId, scanner: ScannerModel) => {

        // post scanner
        const payload = pick(scanner, ['name', 'isActive', 'user', 'checkpoint'])
        const r = await _postScanner(eventId, payload)

        // add scanners
        set({ scanners: [...get().scanners, r.data] })
    },

    patchScanner: async (eventId: string, scanner: Partial<ScannerModel>) => {
        try {
            const payload = pick(scanner, ['name', 'isActive', 'user', 'checkpoint'])
            const response = await _patchScanner(eventId, scanner.id, payload)
            set({ currentScanner: { ...get().currentScanner, ...response.data } })

            // update scanners 
            const scanners = get().scanners.map(s => s.id === scanner.id ? { ...s, ...response.data } : s)
            set({ scanners })

        } catch (error) {
            console.error(error)
        }
    },
    getScanner: async (eventId: string, scannerId) => {
        try {
            const { data } = await _getScanner(eventId, scannerId)
            set({ currentScanner: data })
        } catch (error) {
            console.error(error)
        }
    },
    getScanners: async (eventId: string, query) => {
        try {
            const { data } = await _getScanners(eventId, query)
            set({ scanners: data.results })
        } catch (error) {
            console.error(error)
        }
    },

    setCurrentScanner: (scannerId: string) => {

        const { scanners } = get();
        const scanner = scanners.find(s => s.id === scannerId)
        set({ currentScanner: scanner })
    },

    unsetCurrentScanner: () => {
        set({ currentScanner: { ...initialScanner } })
    },

    postCheck: async (checkpointId: string, scannerId: string, ticketId: string) => {
        // post check
        const payload: CheckApiModel = {
            bookingTicket: ticketId,
            checkpoint: checkpointId,
            scanner: scannerId
        }

        // post check
        const r = await _postCheck(payload)

        // returns a populated booking ticket
        return r.data
    }
})

export const scannersStore = create(devtools(createStore))
export const useScannersStore = scannersStore