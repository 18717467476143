import React, {FC} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const Step5: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Welcome to Skybookings</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Your account was successfully created. If you need more info, please
          <Link to='/auth/login' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </Link>
          .
        </div>
      </div>

      <div className='mb-0'>
        <div className='notice mb-10 d-flex bg-light-info rounded border-info border border-dashed p-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-info me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Activate your account</h4>
              <div className='fs-6 text-gray-800'>
                Please check your emails for an activation link. Click on the link to activate your
                account. If you did not receive it, please check your spam folder. Alternatively,
                you can sign in and follow the prompts to resend your activation link.
              </div>
            </div>
          </div>
        </div>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Account Verification Notice</h4>
              <div className='fs-6 text-gray-600'>
                Verification is different from activation. We are reviewing the files you uploaded
                and ensuring that your account complies with FICA (Financial Intelligence Centre
                Act). This process can take up to 48 hours. During the verification process some
                functionality may be limited. If you need more info, please
                <a href='/dashboard' className='fw-bolder'>
                  {' '}
                  Create Team Platform
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step5}
