import {ErrorMessage, Form, Formik} from 'formik'
import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import SubmitButton from '../../../../_components/SubmitButton'
import NumberInput from '../../../../_components/NumberInput'
import {KTIcon} from '../../../../_metronic/helpers'
import toast, {Toaster} from 'react-hot-toast'
import * as Yup from 'yup'
import {formatMoney} from '../../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import {useStatementStore} from '../../transactions/core/statementsStore'
import {initialPayout, PayoutModel, usePayoutsStore} from '../core/payoutsStore'
import clsx from 'clsx'

type Props = {
  onPayoutAdded?: () => void
  disabled?: boolean
}

// yup validation schema
const validationSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
})

const RequestPayoutButton: FC<Props> = ({onPayoutAdded, disabled}) => {
  const [showModal, setShowModal] = useState(false)
  const {balance, getBalance} = useStatementStore()
  const {getAccount} = useAccountsUsersStore()
  const {postPayout} = usePayoutsStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {selectedAccountsUsers} = useAccountsUsersStore()

  const handleSubmit = async (values: PayoutModel) => {
    // can't save anything without booking

    setIsSubmitting(true)

    try {
      // prepare payout payload
      const payload: PayoutModel = {
        ...values,
        amount: values.amount,
        status: 'pending',
        notes: 'Adhoc Payout request',
      }

      // save payout
      await postPayout(payload)

      // tell someone
      if (onPayoutAdded) onPayoutAdded()
    } catch (e) {
      toast.error('Failed to request payout')
    }

    setIsSubmitting(false)
    setShowModal(false)
  }

  // LOAD BALANCE
  useEffect(() => {
    getBalance(selectedAccountsUsers.account.id)

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Toaster position='top-center' />
      <button
        onClick={() => setShowModal(true)}
        disabled
        className={clsx('btn btn-sm', {
          'btn-light-info': disabled === false,
          'btn-secondary': disabled === true,
        })}
      >
        {isSubmitting ? (
          <span
            className='spinner-border spinner-border-sm me-1'
            role='status'
            aria-hidden='true'
          ></span>
        ) : (
          <KTIcon className='svg-icon-2' iconName='plus' />
        )}
        Request Payout
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Request Payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              ...initialPayout,
              amount: balance,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(form) => {
              return (
                <Form>
                  <div className='fv-row mb-5'>
                    {/* max amount withdrawable */}
                    <div className='col'>
                      <label className='fw-bolder form-label'>
                        Max Payout you can withdraw is{' '}
                        {formatMoney(balance, getAccount()?.currency || 'ZAR', 0)}
                      </label>
                    </div>
                  </div>

                  {/* Payout amount */}
                  <div className='fv-row mb-10'>
                    <div className='col '>
                      <label className='form-label required'>Payout Amount</label>
                      <NumberInput name='amount' />
                      <ErrorMessage className='text-danger' name='amount' />
                    </div>
                  </div>

                  {/* payout status hidden */}
                  <input type='hidden' name='type' value='payout' />

                  <SubmitButton
                    isValid={form.isValid}
                    label='Request Payout'
                    isSubmitting={isSubmitting}
                    className='btn btn-primary w-100 mb-5'
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {RequestPayoutButton}
