// src/utils/analytics.js
import ReactGA from 'react-ga4'
import {appSettingsStore} from '../app/modules/settings/core/appSettingsStore'

export const GA_EVENT_EVENT_VIEW_PAGE = 'event_view_page'
export const GA_EVENT_BOOKING_VIEW_PAGE = 'booking_view_page'
export const GA_EVENT_BOOKING_IDENTIFY_PATRON = 'booking_identify_patron'
export const GA_EVENT_BOOKING_SELECT_TICKET = 'booking_select_ticket'
export const GA_EVENT_BOOKING_BOOKING_CONFIRMED = 'booking_confirmed'
export const GA_EVENT_BOOKING_PAYMENT_COMPLETE = 'booking_payment_complete'

const initializeGA = () => {
  const measurementId = appSettingsStore.getState().appSettings.gaMeasurementId
  ReactGA.initialize(measurementId)
}

const trackPageView = (path) => {
  ReactGA.send({hitType: 'pageview', page: path})
}

export type GaEventType = {
  category?: string | 'event' | 'booking' | 'conversion'
  eventId?: string
  accountId?: string
  patronId?: string
  value?: number
}

const trackEvent = (eventName: string, eventData: GaEventType) => {
  const args: any = {...eventData}
  ReactGA.event(eventName, args)
}

export {initializeGA, trackPageView, trackEvent}
