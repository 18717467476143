import {useBookingStore} from '../core/bookingsStore'
import {Link} from 'react-router-dom'

import {formatNumber, getCurrencySymbol} from '../../../../_helpers/_helpers'
import {useEventsStore} from '../../events/core/eventsStore'
import clsx from 'clsx'
import {bookingStatusLabel} from '../../../../_helpers/_templateHelpers'

const BookingsTable = () => {
  const {bookings} = useBookingStore()
  const {currentEvent} = useEventsStore()

  return (
    <>
      {/* begin::Table */}
      <div className='table-responsive'>
        <div className='text-gray-600 fw-bold'>
          {/* end::Table head */}
          {/* begin::Table body */}
          <div className='d-flex flex-column'>
            {bookings.length > 0 &&
              bookings.map((booking) => {
                const tiv = (booking.stats && booking.stats.ticketsValue) || 0
                const trv = (booking.stats && booking.stats.transactionsValue) || 0

                const isPaid = tiv > trv ? true : false

                return (
                  <Link
                    key={booking.id}
                    to={`/bookings/${booking.id}/`}
                    className='bg-hover-light px-0 border-1 border-bottom  d-flex py-5'
                  >
                    {/* START:: SOMETHING */}
                    <div className='bg-hover d-flex text-start p-0 flex-column flex-grow-1'>
                      <div className='mb-2 d-flex'>
                        <div className='symbol symbol-50px me-5'>
                          {/* first letter */}
                          <span
                            className={clsx(`symbol-label text-white fw-bolder`, {
                              'bg-success': booking.status === 'complete',
                              'bg-danger': booking.status === 'cancelled',
                              'bg-warning': booking.status === 'pending',
                            })}
                          >
                            {booking.patron.name ? booking.patron.name.charAt(0) : 'A'}
                          </span>
                        </div>

                        <div className='d-flex flex-column justify-content-start'>
                          <div className='d-flex align-items-start'>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-4 me-3'>
                              {booking.patron.name}
                            </span>
                          </div>
                          <span
                            className='text-start fw-normal text-muted'
                            dangerouslySetInnerHTML={{__html: bookingStatusLabel(booking)}}
                          />
                        </div>
                      </div>
                    </div>
                    {/* END:: STATUS */}

                    {/* START:: VALUE */}

                    {currentEvent.sellTickets && (
                      <>
                        <div className='text-end d-flex flex-column min-w-100px flex-grow-1 '>
                          <span className='fs-6 text-dark fw-semifolder mb-2'>
                            {getCurrencySymbol(currentEvent.currency) +
                              ' ' +
                              formatNumber(booking.stats.ticketsValue)}
                          </span>
                          {/* paid */}
                          {isPaid ? (
                            <span className='text-mute fw-normal fs-7 d-block'>
                              {' Paid: '}
                              {getCurrencySymbol(currentEvent.currency) +
                                ' ' +
                                formatNumber(booking.stats.transactionsValue)}
                            </span>
                          ) : (
                            <span className='text-mute fw-normal fs-7 d-block'>Paid in full</span>
                          )}
                        </div>
                      </>
                    )}

                    {/* END:: VALUE */}
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export {BookingsTable}
