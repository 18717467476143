/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatMoney, formatNumber, pluralise} from '../../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import clsx from 'clsx'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  height?: number
  showPCT?: boolean
}

const AnalyticsTicketsProgressCards: FC<Props> = ({className, showPCT = true, height}) => {
  const [bookedPct, setBookedPct] = useState(0)

  const {currentEvent, tickets, ticketsPagination, getTickets} = useEventsStore()
  const {getCurrentAccount} = useAccountsUsersStore()

  useEffect(() => {
    if (!currentEvent.id) return

    // get pie data

    getTickets(currentEvent?.id, {...ticketsPagination, page: 1, limit: 99})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  // UPDATE CHART DATA
  useEffect(() => {
    if (!currentEvent.id) return

    // calculate bookings percentage
    const pct = Math.floor(
      (currentEvent.stats.bookingsTicketsCount / currentEvent.stats.ticketsTotalQty) * 100
    )
    setBookedPct(pct)
  }, [currentEvent, tickets])

  return (
    <>
      {currentEvent.stats.bookingsCount > 0 ? (
        <>
          <div className={`card border-0 bg-transparent shadow-none card-flush ${className}`}>
            <div className='card-header my-5 p-0 d-flex flex-wrap align-items-start'>
              {/* sales process for each ticket */}
              <div className='card-title d-flex'>
                {showPCT && (
                  <div className='d-flex'>
                    <span className='display-5 fw-bolder me-3 pe-3 border-end '>{bookedPct}%</span>
                  </div>
                )}
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-1 fw-bolder text-dark me-2 lh-1 ls-n2'>
                      {currentEvent.sellTickets
                        ? formatMoney(
                            currentEvent.stats.bookingsValue,
                            getCurrentAccount()?.currency,
                            0,
                            true
                          ) + ' in sales'
                        : formatNumber(currentEvent.stats.bookingsTicketsCount, 0, true) +
                          pluralise(currentEvent.stats.bookingsTicketsCount, ' Ticket') +
                          ` Issued`}
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span className='text-muted pt-1 fw-semibold fs-6'>{`from ${currentEvent.stats.bookingsTicketsCount} confirmed tickets. `}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-body p-0'>
              {/* max height and vertifical scroll */}
              <div
                className={height ? 'v-scrollable' : ''}
                style={{height: height ? `${height}px` : 'auto'}}
              >
                {tickets.map((ticket, index) => {
                  const pct = Math.floor((ticket.bookedTickets / ticket.qty) * 100)

                  return (
                    <div key={ticket?.id} className='d-flex align-items-start mb-3 w-100'>
                      <div className='symbol symbol-50px me-5'>
                        <span
                          className={clsx(`symbol-label fw-bolder`, {
                            'bg-secondary': ticket.canBook.canBook === true,
                            'bg-danger text-white': ticket.canBook.canBook === false,
                          })}
                        >
                          {pct}%
                        </span>
                      </div>
                      <div className='d-flex flex-column flex-grow-1'>
                        <span className='fw-bold'>
                          {ticket.name}
                          {ticket.canBook.canBook === false && (
                            <span className='badge badge-light-danger'>
                              {ticket.canBook.reason}
                            </span>
                          )}
                        </span>
                        <div className='d-flex fw-semibold d-flex w-100 text-muted '>
                          <span className='d-flex me-2'>
                            {formatNumber(ticket.bookedTickets, 0)} tickets issued
                            {currentEvent.sellTickets &&
                              ` for ${formatMoney(
                                ticket.bookedTickets * ticket.price,
                                getCurrentAccount()?.currency,
                                0
                              )}`}
                          </span>

                          {/* can't book reason */}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='card w-100 w-lg-450px min-h-200 bg-transparent mt-3 border-1 border-dashed border-shadow-0'>
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No bookings yet</span>
            <span className='text-gray-400 fs-6'>Bookings and sales data will appear here.</span>
          </div>
        </div>
      )}
    </>
  )
}

export {AnalyticsTicketsProgressCards}
