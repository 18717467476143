import {useEffect, useState} from 'react'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {formatDateDMYHI, formatMoney, pick} from '../../../_helpers/_helpers'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import {KTIcon} from '../../../_metronic/helpers'
import {useEventStatementStore} from './core/eventStatementStore'
import {useParams} from 'react-router-dom'
import {useEventsStore} from './core/eventsStore'
import toast, {Toaster} from 'react-hot-toast'
import {AdjustStatementButton} from '../transactions/partials/AdjustStatementButton'
import {useClientStore} from '../clients/core/clientStore'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'

const EventStatementPage = () => {
  const {eventBalance, eventStatement, getEventStatement, syncEventStatement} =
    useEventStatementStore()
  const {selectedAccountsUsers, adminAccount} = useAccountsUsersStore()
  const {currentEvent, getEvent} = useEventsStore()
  const {isClientLoggedIn} = useClientStore()
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const eventStatementStore = useEventStatementStore()

  const [sPagination, setSPagination] = useState(eventStatementStore.pagination)
  const {eventId} = useParams<{eventId: string}>()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  useEffect(() => {
    if (!eventId) return

    if (currentEvent.id !== eventId) {
      getEvent(eventId).finally(() => {})
    }

    // set is loading etc.
    setIsLoading(true)

    // load page data
    Promise.all([
      getEvent(eventId),
      getEventStatement(eventId, pick(sPagination, ['limit', 'page'])),
    ]).finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line
  }, [eventId])

  // update pagination when transactions or statements change or payouts
  useEffect(() => {
    setSPagination(eventStatementStore.pagination)
  }, [eventStatementStore.pagination])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `Event`,
        title: `${currentEvent.name}`,
        link: `/events/${currentEvent.id}`,
      },
      {
        object: 'Statement',
        title: 'Statement',
        link: `events/${eventId}/statement`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Statement')

    // eslint-disable-next-line
  }, [eventStatement])

  const handleStatementPaginationChange = async (page: number) => {
    if (!currentEvent.id) return
    setIsLoading(true)
    await getEventStatement(currentEvent.id, {...sPagination, page})
    setIsLoading(false)
  }

  const handleSyncStatement = async () => {
    try {
      if (!currentEvent.id) return
      setSyncing(true)
      await syncEventStatement(currentEvent.id)
      setSyncing(false)
    } catch (err) {
      toast.error('Could not sync statement')
    }
  }

  return (
    <>
      <Toaster />
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex justify-content-between mb-10'>
            <div className='d-flex flex-column'>
              <span className='fs-3 fw-bold'>
                {eventStatement.length} of {sPagination.totalResults} Entries
              </span>
              <div className='d-flex'>
                <span className='fs-5 text-muted'>{`Pg ${sPagination.page} of ${sPagination.totalPages}`}</span>
                {adminAccount &&
                  currentEvent.stats.bookingsCount > 0 &&
                  sPagination.totalResults === 0 && (
                    <button
                      className='btn btn-sm btn-link text-danger ms-5'
                      onClick={handleSyncStatement}
                    >
                      {syncing && (
                        <span
                          className='spinner-border spinner-border-sm me-2'
                          role='status'
                        ></span>
                      )}
                      Sync Statement
                    </button>
                  )}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              {!isClientLoggedIn && (
                <div className='d-flex me-5 align-items-center'>
                  <AdjustStatementButton
                    event={currentEvent}
                    onTransactionAdded={() => handleStatementPaginationChange(1)}
                    className='btn btn-light btn-active-info btn-sm'
                    btnLabel={isMobileDevice() ? '' : 'Add Transaction'}
                    btnIcon='plus-circle'
                  />
                </div>
              )}

              <div className='account-balance fw-bolder fs-1 text-primary text-end'>
                {formatMoney(eventBalance, selectedAccountsUsers.account.currency, 0)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TRANSACTIONS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* STATEMENTS */}
          <div className='tab-pane fade show active' id='tab_statement'>
            {eventStatement.length === 0 ? (
              <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  <div className='d-flex'>
                    <KTIcon
                      className='svg-icon-3x svg-icon-primary mb-5 '
                      iconName='flaticon-safebox-1'
                    />
                    <span className='fs-2x fw-bolder text-dark'>No statement entries yet. </span>
                  </div>
                  <div className='fs-5 text-muted text-center'>
                    As soon as patrons make bookings, transactions will be recorded here.
                  </div>
                </div>
              </div>
            ) : (
              <>
                {isMobileDevice() ? (
                  <>
                    {eventStatement.map((statement) => (
                      <div
                        key={statement.id}
                        className='d-flex flex-column p-3 border-bottom border-secondary mb-3'
                      >
                        <div className='d-flex justify-content-between mb-2'>
                          <div className='dates fw-bolder'>
                            {formatDateDMYHI(
                              statement.dateCreated?.toString() || new Date().toString()
                            )}
                          </div>
                          <div className='balance fw-bolder'>
                            <div className='d-flex text-end justify-content-end'>
                              {formatMoney(
                                statement.balance,
                                selectedAccountsUsers.account.currency,
                                2
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='description d-flex flex-fill'>
                            {statement.description}
                          </div>
                          <div
                            className='text-end d-flex justify-content-end'
                            style={{minWidth: '130px'}}
                          >
                            <div className='d-flex justify-content-end'>
                              {statement.amount > 0 ? (
                                <KTIcon
                                  iconName='double-up'
                                  className='svg-icon-2x text-success me-1'
                                />
                              ) : (
                                <KTIcon
                                  iconName='double-down'
                                  className='svg-icon-2x text-danger me-1'
                                />
                              )}
                              {formatMoney(
                                statement.amount,
                                selectedAccountsUsers.account.currency,
                                2
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                    <thead>
                      <tr className='fw-bolder fs-6 text-gray-800'>
                        <th className='w-175px'>Date</th>
                        <th className=''>Description</th>
                        <th className='text-end w-115px'>Amount</th>
                        <th className='text-end w-115px'>balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventStatement.map((statement) => (
                        <tr key={statement.id}>
                          {/* DATE */}
                          <td>
                            <div className='d-flex align-items-center'>
                              {formatDateDMYHI(
                                statement.dateCreated?.toString() || new Date().toString()
                              )}
                            </div>
                          </td>

                          {/* DESCRIPTION */}
                          <td>{statement.description}</td>

                          {/* AMOUNT */}
                          <td className='text-end'>
                            <div className='d-flex justify-content-end'>
                              {statement.amount > 0 ? (
                                <KTIcon
                                  iconName='double-up'
                                  className='svg-icon-2x text-success me-1'
                                />
                              ) : (
                                <KTIcon
                                  iconName='double-down'
                                  className='svg-icon-2x text-danger me-1'
                                />
                              )}
                              {formatMoney(
                                statement.amount,
                                selectedAccountsUsers.account.currency,
                                2
                              )}
                            </div>
                          </td>

                          {/* BALANCE */}
                          <td className='text-end'>
                            {formatMoney(
                              statement.balance,
                              selectedAccountsUsers.account.currency,
                              2
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {eventStatement.length < sPagination.totalResults && (
                  <div className='my-10'>
                    <PaginationControl
                      page={sPagination.page}
                      total={sPagination.totalResults}
                      limit={sPagination.limit}
                      changePage={handleStatementPaginationChange}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export {EventStatementPage}
