import React, {FC} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useEventsStore} from '../events/core/eventsStore'
import toast, {Toaster} from 'react-hot-toast'
import PhoneField from '../../../_components/PhoneField'
import {KTIcon} from '../../../_metronic/helpers'
import SubmitButton from '../../../_components/SubmitButton'

import {ClientModel, useClientStore} from './core/clientStore'

type Props = {
  onLoggedIn?: () => void
  onLoginError?: (error: any) => void
}

const ClientAuth: FC<Props> = ({onLoggedIn}) => {
  const [createNewClient, setCreateNewClient] = React.useState<boolean>(false)
  const [enterOTP, setEnterOTP] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const {publicEvent} = useEventsStore()
  const {currentClient, isClientLoggedIn, postClient, createClientOTP, verifyOTP, unsetClient} =
    useClientStore()

  const [buttonLabel, setButtonLabel] = React.useState<string>('Next')
  const [currentPhone, setCurrentPhone] = React.useState<string>('')
  const [isResendingOTP, setIsResendingOTP] = React.useState<boolean>(false)

  const handleSubmit = async (values: ClientModel, actions) => {
    setIsSubmitting(true)

    if (values.phone.toString().length < 10) {
      toast.error('Please enter a valid phone number')
      setIsSubmitting(false)
      return
    }

    if (createNewClient === false && enterOTP === false) {
      // CHECK CLIENT ACCOUNT
      try {
        setCurrentPhone(values.phone.toString())
        await createClientOTP('phone', values.phone.toString())
        setEnterOTP(true)
        setCreateNewClient(false)
        toast.success('Please check your phone for OTP code.')
        setButtonLabel('Verify OTP')
      } catch (err: any) {
        if (err.response.status === 404) {
          setCreateNewClient(true)
          setEnterOTP(false)
          setButtonLabel('Next')
        } else {
          toast.error(err.response.data.message)
        }
        setIsSubmitting(false)
      }
    } else if (enterOTP === true) {
      // VERIFY OTP
      try {
        if (!values.OTP) return

        await verifyOTP(values.OTP)
        setEnterOTP(false)
        setCreateNewClient(false)
        setButtonLabel('Next')

        if (onLoggedIn) onLoggedIn()

        // rset the form
        actions.resetForm()

        // GO TO THE NEXT PAGE
      } catch (err: any) {
        setIsSubmitting(false)
        toast.error('Please enter the correct OTP code.')
      }
    } else if (createNewClient === true) {
      // CREATE NEW CLIENT
      try {
        if (publicEvent.id === null) throw new Error('Event not found')

        await postClient(publicEvent.id, values)
        setCurrentPhone(values.phone.toString())
        await createClientOTP('phone', values.phone.toString())
        setEnterOTP(true)
        setCreateNewClient(false)
        setButtonLabel('Verify OTP')

        toast.success('Your details were saves successfully.  Please verify your phone number')
      } catch (err: any) {
        setIsSubmitting(false)
        toast.error(err.response.data.message)
      }
    }

    setIsSubmitting(false)
  }

  const handleResendOTP = async () => {
    try {
      if (currentPhone) {
        setIsResendingOTP(true)
        await createClientOTP('phone', currentPhone)
        toast.success('Please check your phone for OTP code.')
        setIsResendingOTP(false)
      }
    } catch (err: any) {
      setIsResendingOTP(false)
      toast.error(err.response.data.message)
    }
  }

  const handleCancelClick = async (form) => {
    if (currentClient.id) {
      await unsetClient()
      setCreateNewClient(false)
      setEnterOTP(false)
      return
    } else if (createNewClient || enterOTP) {
      setCreateNewClient(false)
      setEnterOTP(false)
    }
  }

  return (
    <>
      <Formik initialValues={currentClient} enableReinitialize onSubmit={handleSubmit}>
        {(form) => {
          return (
            <Form>
              <Toaster position='top-center' />

              <div className='d-flex flex-column w-100'>
                {isClientLoggedIn ? (
                  <>
                    <div className='mb-5 fw-bold'>
                      Hello {currentClient.name.split(' ')[0]}, you are are successfully logged in.{' '}
                      <button
                        onClick={handleCancelClick}
                        className='text-primary btn btn-link d-inline'
                      >
                        Use another account?
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=''>
                      <div className='mb-10 fv-row'>
                        <label className='form-label mb-3' aria-required>
                          Phone Number (required)
                        </label>
                        <PhoneField name='phone' />
                        <div className='text-danger mt-2'>{form.errors.phone}</div>
                      </div>

                      {enterOTP && (
                        <>
                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>
                              Please check your phone for an sms and enter the pin below.{' '}
                              <button
                                type='button'
                                onClick={handleResendOTP}
                                className='btn btn-link text-primary'
                              >
                                Resend OTP
                                {isResendingOTP && (
                                  <span className='spinner spinner-border-sm spinner-primary spinner-center spinner-border ms-2 fs-7'></span>
                                )}
                              </button>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg'
                              name='OTP'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='OTP' />
                            </div>
                          </div>
                        </>
                      )}

                      {createNewClient && (
                        <>
                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Email Address (optional)</label>
                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='email'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='email' />
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Your Name (required)</label>

                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='name'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='name' />
                            </div>
                          </div>
                        </>
                      )}

                      <div className='mb-10 fv-row '>
                        <SubmitButton
                          label={buttonLabel}
                          isValid={form.isValid}
                          isSubmitting={isSubmitting}
                          className='btn btn-primary w-100 mb-5'
                        />
                        {(createNewClient || enterOTP) && (
                          <button
                            onClick={() => handleCancelClick(form)}
                            type='button'
                            className='btn btn-light-danger w-100'
                          >
                            <KTIcon iconName='angle-right' className='me-2' />
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {ClientAuth}
