/* eslint-disable jsx-a11y/anchor-is-valid */

function Step0() {
  return (
    <div className='w-100'>
      {/* begin:: Header */}

      <div className='pb-10 '>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Getting started with skybookings
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-600 fw-bold fs-6'>
          We are excited to have you register on our platform. Just a few things to note before you
          get started.
        </div>
      </div>

      <div className='fb-row mb-10 fs-6'>
        <p className='p-5 bg-light'>
          <span className='fw-bold me-3 mb-10'>1. Accurate Info:</span> It's important that you
          provide accurate information, including you email address and phone number so that we can
          contact you when necessary.
        </p>
        <p className='p-5 bg-light'>
          <span className='fw-bold me-3 mb-10'>2. FICA Info:</span>Because we process payments, we
          are required by law to collect your personal information for FICA purposes. This includes
          a bank account confirmation letter, proof of address, and your a copy of your ID or
          passport. Please have them read for step 4 of the registration process.
        </p>
        <p className='p-5 bg-light'>
          <span className='fw-bold me-3 mb-10'>3. Account Verification:</span>After submitting all
          your information, you account will be accessible and you will be able to list your event
          and start selling tickets. However, we will only start paying our funds after verifying
          your details. This takes 24-48 hours after registration. You will be contacted via email
          once verification is complete.
        </p>
        <p className='p-5 bg-light'>
          <span className='fw-bold me-3 mb-10'>4. Currency:</span>All transactions will be account
          in the South African Rand (ZAR). Payments from outside South Africa will be converted to
          ZAR at the prevailing exchange rate.
        </p>
        <p className='p-5 bg-light'>
          <span className='fw-bold me-3 mb-10'>5. Support:</span>At any point in time, please feel
          free to contact us on <a href='https://wa.me/27736898968'>073 689 8967 (WhatsApp)</a> or
          <a href='mailto:support@skybookings.com'> support@skybookings.com</a>
        </p>
      </div>
    </div>
  )
}

export {Step0}
