import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  onSearch: (filter: string) => void // filter: {role, last_login}
  onResetSearch: () => void
  isLoading?: boolean
}

const GuestListSearch: FC<Props> = ({onSearch, onResetSearch, isLoading}) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setIsFiltered(false)
    setSearchTerm('')
    onResetSearch()
  }

  const filterData = () => {
    setIsFiltered(true)

    // do the same for limit
    onSearch(searchTerm)
  }

  const handlePressEnter = (e: React.KeyboardEvent) => {
    // if enter key is pressed

    if (e.key === 'Enter') {
      filterData()
    }
  }

  return (
    <div
      className='border border-2 w-100 w-lg-450px bg-white border-secondary p-3 d-flex'
      style={{borderRadius: '50px'}}
    >
      {/* begin::Filter Button */}
      <input
        type='text'
        placeholder='Search by name, email, phone'
        className='form-control border-0 me-2'
        value={searchTerm}
        onKeyDown={handlePressEnter}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button
        type='button'
        disabled={isLoading}
        onClick={filterData}
        className={'btn btn-link btn-text-active-primary fw-bold me-2 '}
        data-kt-menu-dismiss='true'
        data-kt-user-table-filter='search'
      >
        {isLoading ? (
          <span className='spinner-border spinner spinner-border-sm'></span>
        ) : (
          <KTIcon
            iconName='magnifier'
            className='fs-3 text-hover-primary fw-bold'
            iconType='solid'
          />
        )}
      </button>
      {isFiltered && (
        <button
          type='button'
          disabled={isLoading}
          onClick={resetData}
          className='btn btn-link fw-bold me-2 px-6'
          data-kt-menu-dismiss='true'
          data-kt-user-table-filter='reset'
        >
          Reset
        </button>
      )}
    </div>
  )
}

export {GuestListSearch}
