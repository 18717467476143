import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { pick } from "../../../../_helpers/_helpers"
import { _getBalance, _getStatements } from "./statementsApi"


export type StatementModel = {
    id?: string,
    account?: string,
    transaction?: string,
    amount: number,
    balance: number,
    description: string,
    dateCreated?: Date,
}

export type Pagination = {

    limit: number,
    page: number,
    totalPages: number,
    totalResults: number
    event?: string,
    booking?: string,
    patron?: string,
    sortBy?: string
    status?: string,
    type?: string,
}


export const initialStatement = {
    id: '',
    event: '',
    patron: '',
    booking: '',
    status: 'pending',
    type: 'payment',
    amount: 0,
    description: '',
    gateway: '',
    required: true,
    gatewayStatementId: '0',
    reconciled: false,

}

export const initialPagination: Pagination = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
}


type StatementStore = {
    balance: number,
    pagination: Pagination,
    statements: StatementModel[],
    getBalance: (account: string) => Promise<void>,
    getStatements: (account: string, query: Partial<Pagination>) => Promise<void>,
}


const createStore: StateCreator<StatementStore> = (set, get) => ({
    balance: 0,
    pagination: { ...initialPagination },
    statements: [],
    getBalance: async (account: string) => {
        if (!account) return;
        const response = await _getBalance(account)
        set({ balance: response.data.balance })
    },
    getStatements: async (account: string, query: Partial<Pagination>) => {
        const { pagination } = get()

        // get statements
        const q = pick({ ...pagination, ...query, account }, ['event', 'patron', 'booking', 'status', 'type', 'limit', 'page', 'account'])

        // if status or type are empty remove them from the query
        const response = await _getStatements(q)
        const newStatements: StatementModel[] = response.data.results.map((statement) => {
            return {
                ...initialStatement,
                ...statement,
            }
        })

        const newPagination = {
            ...pagination,
            ...q,
            totalResults: response.data.totalResults,
            totalPages: response.data.totalPages,
        }
        // update currentBookings
        set({
            balance: response.data.balance,
            statements: newStatements,
            pagination: newPagination,
        })
    },
})

export const statementStore = create(devtools(createStore))
export const useStatementStore = statementStore
