import React, {useState, useEffect} from 'react'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'
import {Field} from 'formik'

type MapInputProps = {
  name: string
  zoom: number
  inputClassName?: string
  buttonClassName?: string
  placeholder?: string
  containerWidth?: string
  containerHeight?: string
}

const MapInput = (props: MapInputProps) => {
  const {
    name,
    zoom,
    containerWidth = '100%',
    inputClassName = 'form-control',
    buttonClassName = 'btn btn-light-info',
    containerHeight = '100%',
  } = props

  const [position, setPosition] = useState<any>()
  const [isFetching, setIsFetching] = useState(false)
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API

  return (
    <div className='w-100'>
      {/* bootstrap 5 group for address and search button */}

      <Field name={name}>
        {({field, form, meta}: any) => {
          const handleSearch = () => {
            // get address value from field
            if (field.value) {
              setIsFetching(true)
              fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                  field.value
                )}&key=${apiKey}`
              )
                .then((response) => response.json())
                .then((data) => {
                  if (data.results && data.results.length > 0) {
                    setPosition({
                      lat: data.results[0].geometry.location.lat,
                      lng: data.results[0].geometry.location.lng,
                    })
                  }
                })
                .finally(() => setIsFetching(false))
            }
          }

          return (
            <>
              <div className='input-group mb-5'>
                <input type='text' className={inputClassName} {...field} onBlur={handleSearch} />
                <button
                  type='button'
                  onClick={handleSearch}
                  disabled={isFetching}
                  className={buttonClassName}
                >
                  {/* spinner */}
                  {isFetching && (
                    <span
                      className='spinner-border spinner-border-sm me-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  )}
                  Search
                </button>
              </div>
            </>
          )
        }}
      </Field>

      {position && (
        <div className='w-100 h-250px bg-light' style={{overflow: 'hidden'}}>
          <LoadScript googleMapsApiKey='AIzaSyA6oVsFPUh-BcBugQ0HXLXUXo8j3GPuYak'>
            <GoogleMap
              mapContainerStyle={{width: containerWidth, height: containerHeight}}
              center={position}
              zoom={zoom}
            >
              <Marker position={position} />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </div>
  )
}

export default MapInput
