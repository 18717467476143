import React, {useEffect} from 'react'
import {Field} from 'formik'
import {parse} from 'path'

const CurrencyInput = (props: any) => {
  const {name, currency, ...rest} = props
  const [formattedValue, setFormattedValue] = React.useState('')

  const formatNumber = (num: string) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: any, form: any) => {
    const rawValue = event.target.value.replace(/,/g, '')
    setFormattedValue(formatNumber(rawValue))
    form.setFieldValue(name, rawValue)
  }

  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        return (
          <div className='input-group'>
            <input
              type='text'
              value={formattedValue || formatNumber(field.value)}
              className='form-control'
              onChange={(event) => handleChange(event, field, form)}
            />
            <input {...field} type='hidden' value={field.value} name={name} />
            <div className='input-group-text'>{currency}</div>
          </div>
        )
      }}
    </Field>
  )
}

export default CurrencyInput
