import axios from 'axios'

export const TRANSACTIONS_ENDPOINT = `/transactions`

export function _postTransaction(bookingId, args) {
  return axios.post(`${TRANSACTIONS_ENDPOINT}/`, args)
}

export function _patchTransaction(id, args) {
  return axios.patch(`${TRANSACTIONS_ENDPOINT}/${id}/`, args)
}

export function _getTransactions(query) {
  return axios.get(`${TRANSACTIONS_ENDPOINT}/`, {params: {...query}})
}

export function _getTransactionSummary(query) {
  return axios.get(`${TRANSACTIONS_ENDPOINT}/summary`, {params: {...query}})
}

// STATEMENT
export function _getStatement() {
  return axios.get(`/statement`)
}
