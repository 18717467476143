/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EventsList} from './EventsPagePartials/EventsList'

const EventsPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col'>
        <EventsList className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const EventsPageWrapper: FC = () => {
  return (
    <>
      <EventsPage />
    </>
  )
}

export {EventsPageWrapper}
