import React from 'react'
import {Modal} from 'react-bootstrap'
import {EventModel} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  event: EventModel
  showModal: boolean
  onClose?: () => void
}
const EventOptionsModal: React.FC<Props> = ({event, showModal, onClose}) => {
  return (
    <>
      {/* modal */}
      <Modal className='modal-lg' show={showModal} onHide={onClose}>
        <Modal.Body>
          {/* Icon Grid */}
          <div className='row g-5 g-xl-8'>
            <div
              className='col-6
                col-lg-3'
            >
              <div className='d-flex flex-column flex-center'>
                <KTIcon
                  iconName='calendar-edit'
                  className='fs-1 mb-2 text-active-primary'
                  iconType='duotone'
                />
                <span className='fw-bold fs-6'>Edit</span>
              </div>
            </div>
            <div
              className='col-6
                col-lg-3'
            >
              <div className='d-flex flex-column flex-center'>
                <span className='svg-icon svg-icon-3x svg-icon-primary mb-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M3 3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3ZM5 4V20H19V4H5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                <span className='fw-bold fs-6'>Delete</span>
              </div>
            </div>
            <div
              className='col-6
                col-lg-3'
            >
              <div className='d-flex flex-column flex-center'>
                <span className='svg-icon svg-icon-3x svg-icon-primary mb-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M3 3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3ZM5 4V20H19V4H5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                <span className='fw-bold fs-6'>Duplicate</span>
              </div>
            </div>
            <div
              className='col-6
                col-lg-3'
            >
              <div className='d-flex flex-column flex-center'>
                <span className='svg-icon svg-icon-3x svg-icon-primary mb-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M3 3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3ZM5 4V20H19V4H5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                <span className='fw-bold fs-6'>Share</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {EventOptionsModal}
