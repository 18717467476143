import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {ThemeModeSwitcher} from '../../../partials'
import {usePatronStore} from '../../../../app/modules/patrons/core/patronStore'
import {useClientStore} from '../../../../app/modules/clients/core/clientStore'

const itemClass = 'ms-1 ms-md-4'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {unsetPatron} = usePatronStore()
  const {unsetClient} = useClientStore()
  const unsetUser = () => {
    unsetPatron()
    unsetClient()
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>
*/}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div onClick={unsetUser} className={clsx('btn btn-secondary me-2 btn-sm me-2')}>
          <KTIcon iconName='lock-3' className={btnIconClass} />
          Log Out
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      {/* <div className='app-navbar-item ms-5'>
        <Link
          id='kt_create_event'
          to='/events/create'
          className={'btn btn-primary btn-sm btn-secondary btn-active-primary '}
        >
          <KTIcon iconName='handcart' className={btnIconClass} />R 2,000
        </Link>
      </div> */}
    </div>
  )
}

export {Navbar}
