import clsx from 'clsx'
import {FC} from 'react'
import {EventModel} from '../app/modules/events/core/eventsStore'

type Props = {
  event: EventModel
  className?: string
}

const EventColorCode: FC<Props> = ({event, className}) => {
  return (
    <div
      className={clsx(
        `w-10px h-10px w-md-10px h-md-10px symbol bg-primary rounded-circle me-2 ${className}`,
        {
          'bg-success': event.canBook.canBook === true,
          'bg-danger': event.canBook.canBook === false,
        }
      )}
    ></div>
  )
}
export default EventColorCode
