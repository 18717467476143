import {Field} from 'formik'
import {BaseSyntheticEvent, useState} from 'react'
import {uploadFile} from '../_helpers/_helpers'
import {KTIcon} from '../_metronic/helpers'

export function FileInput(props: any) {
  const {name, ...rest} = props
  const [uploadProgress, setUploadProgress] = useState(0)

  return (
    <Field name={name}>
      {({field, form}: any) => {
        // UPLOAD FILE
        const handleFileUpload = async (selectedFile) => {
          setUploadProgress(0)
          try {
            if (!selectedFile) {
              return
            }
            const url = await uploadFile(selectedFile, (progress) => {
              setUploadProgress(progress)

              if (progress === 100) {
                setTimeout(() => {
                  setUploadProgress(0)
                }, 1000)
              }
            })

            form.setFieldValue(field.name, url)
          } catch (error: any) {
            form.setFieldError(field.name, error.message)
          }
        }

        return (
          <div {...rest}>
            <div className='d-flex justify-content-between align-items-center'>
              <input
                type='file'
                id={field.name}
                className='my-3'
                onChange={(event: BaseSyntheticEvent) => {
                  handleFileUpload(event.currentTarget.files[0])
                }}
              />

              {/* button to download file */}
              {field.value && (
                <a
                  href={field.value}
                  rel='noreferrer'
                  target='_blank'
                  className='btn btn-secondary btn-sm'
                >
                  <KTIcon
                    iconName='cloud-download'
                    iconType='solid'
                    className='me-2 text-primary'
                  />
                  Download File
                </a>
              )}
            </div>

            {/* BS 3:  progress bar */}
            <div
              className='progress d-flex h-3px mb-1'
              style={{
                opacity: uploadProgress === 0 ? 0 : 1,
              }}
            >
              <div
                className='progress-bar'
                role='progressbar'
                style={{width: `${uploadProgress}%`}}
                aria-valuenow={uploadProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </div>
        )
      }}
    </Field>
  )
}
