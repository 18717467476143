import axios from "axios"
export const CLIENTS_ENDPOINT = `/clients`

export type CreateClientType = {
    name: string,
    phone: number,
    email: string,
}

export function _postClient(eventId, args: CreateClientType) {
    return axios.post(`${CLIENTS_ENDPOINT}/${eventId}`, args)
}

export type UpdateClientType = {
    name: string,
    phone: number,
    email: string,
    isBanned: boolean,
}

export const initialUpdateClientType: UpdateClientType = {
    name: '',
    phone: 0,
    email: '',
    isBanned: false,
}

export function _patchClient(id, args: Partial<UpdateClientType>) {
    return axios.patch(`${CLIENTS_ENDPOINT}/${id}/`, args)

}

export function _getClientMe() {
    return axios.get(`${CLIENTS_ENDPOINT}/me`)
}

export function _searchClient(search) {
    return axios.get(`${CLIENTS_ENDPOINT}/search/`, { params: { ...search } })
}

export function _deleteClient(id) {
    return axios.delete(`${CLIENTS_ENDPOINT}/${id}/`)
}

export function _createClientOTP(type, data) {
    return axios.post(`${CLIENTS_ENDPOINT}/otp/`, {
        [type]: data
    })
}

export function _verifyClientOTP(OTP: number) {
    return axios.post(`${CLIENTS_ENDPOINT}/otp/verify/`, {
        OTP
    })
}

export function _getClients(query:any) {
    return axios.get(`${CLIENTS_ENDPOINT}/`, {params: {...query}})
}

// CLIENT ACCOUNT 
export function _postClientAccount(clientId, accountId: string) {
    return axios.post(`${CLIENTS_ENDPOINT}/account/${accountId}`, {account:accountId})
}

// CLIENT EVENT
export function _postClientEvent(clientId, eventId:string) {
    return axios.post(`${CLIENTS_ENDPOINT}/${clientId}/events/`, {event: eventId})
}

export function _deleteClientEvent(clientId:string, eventId:string) {
    return axios.delete(`${CLIENTS_ENDPOINT}/${clientId}/events/${eventId}`)
}

export function _getClientEvents( args) {
    return axios.get(`${CLIENTS_ENDPOINT}/events/`, {params: {...args}})
}