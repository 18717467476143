import axios from "axios"

export const STATEMENTS_ENDPOINT = `/statements`


export function _getStatements(query) {
    return axios.get(`${STATEMENTS_ENDPOINT}/`, { params: { ...query } })
}

export function _getBalance(account: string) {
    return axios.get(`${STATEMENTS_ENDPOINT}/balance`, { params: { account } })
}