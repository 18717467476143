import React, {useState, useEffect} from 'react'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'
import {Field} from 'formik'

type MapInputProps = {
  location: string
  zoom: number
  placeholder?: string
  containerWidth?: string
  containerHeight?: string
}

const MapViewer = (props: MapInputProps) => {
  const {
    location,
    zoom,
    containerWidth = '100%',

    containerHeight = '100%',
  } = props

  const [position, setPosition] = useState<any>()
  const [isFetching, setIsFetching] = useState(false)
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API

  useEffect(() => {
    displayMap()
  }, [])

  const displayMap = () => {
    // get address value from field
    if (location) {
      setIsFetching(true)
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          location
        )}&key=${apiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results.length > 0) {
            setPosition({
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            })
          }
        })
        .finally(() => setIsFetching(false))
    }
  }

  return (
    <div className='w-100'>
      {/* bootstrap 5 group for address and search button */}

      {position && (
        <div className='w-100 h-250px bg-light' style={{overflow: 'hidden'}}>
          <LoadScript googleMapsApiKey='AIzaSyA6oVsFPUh-BcBugQ0HXLXUXo8j3GPuYak'>
            <GoogleMap
              mapContainerStyle={{width: containerWidth, height: containerHeight}}
              center={position}
              zoom={zoom}
            >
              <Marker position={position} />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </div>
  )
}

export default MapViewer
