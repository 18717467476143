// Write Zustand store for auth here
import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _login,
  _selectAccount,
  _verifyToken,
  _forgotPassword,
  _resetPassword,
  _handleExists,
  _emailExists,
  _register,
  _getRights,
} from './authApi'
import axios from 'axios'
import {AccountsUsersModel, accountsUsersStore} from '../../settings/core/accountsUsersStore'
import {jwtDecode} from 'jwt-decode'
import dayjs from 'dayjs'
import {omit} from '../../../../_helpers/_helpers'
import {patronStore} from '../../patrons/core/patronStore'
import {clientStore} from '../../clients/core/clientStore'

// LOCAL STORAGE KEY
const AUTH_LOCAL_STORAGE_KEY = 'sb-auth-key'

export type AuthTokensModel = {
  accessToken: string
  refreshToken: string
  selectedAccount: string | undefined
}

export type RegistrationModel = {
  accountName: string
  accountPlan: string
  accountHandle: string
  accountBankName: string
  accountBankAccountHolder: string
  accountBankAccountNumber: number
  accountBankBranch: string
  accountFileId: string
  accountFileBankLetter: string
  accountFileProofOfAddress: string
  accountCurrency: string
  userFirstName: string
  userLastName: string
  userDisplayName: string
  userEmail: string
  userPassword: string
  userPasswordConfirm?: string
  acceptTerms?: boolean
}

export const initialRegistrationValues: RegistrationModel = {
  accountName: '',
  accountPlan: 'independent',
  accountHandle: '',
  accountBankAccountHolder: '',
  accountBankAccountNumber: 0,
  accountBankBranch: '',
  accountBankName: '',
  accountFileId: '',
  accountFileBankLetter: '',
  accountFileProofOfAddress: '',
  accountCurrency: 'ZAR',
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userDisplayName: '',
  userPassword: '',
  userPasswordConfirm: '',
  acceptTerms: false,
  // nameOnCard: 'Max Doe',
  // cardNumber: '4111 1111 1111 1111',
  // cardExpiryMonth: '1',
  // cardExpiryYear: '2025',
  // cardCvv: '123',
  // saveCard: '1',
}

type AuthStore = {
  interceptor: any
  accessToken: string
  refreshToken: string
  selectedAccount: string | undefined
  redirect: string | null
  setRedirect: (r: string | null) => void
  unsetRedirect: () => void
  retrieveRedirect: () => string | null
  setupAuth: (auth: AuthTokensModel) => Promise<void>
  unsetAuth: () => void
  retrieveAuth: () => Promise<AuthTokensModel | undefined>
  isLoggedIn: () => boolean
  login: (email: string, password: string) => Promise<boolean>
  logout: () => void
  reload: () => Promise<void>
  selectAccount: (accountsUsersId: string) => Promise<boolean>
  deselectAccount: () => void
  forgotPassword: (email: string) => Promise<boolean>
  resetPassword: (password: string, token: string) => Promise<boolean>
  emailExists: (email: string) => Promise<boolean>
  handleExists: (handle: string) => Promise<boolean>
  register: (registration: RegistrationModel) => Promise<boolean>
  verifyEmail: (token: string) => Promise<boolean>
}

const createStore: StateCreator<AuthStore> = (set, get) => ({
  interceptor: undefined,
  accessToken: '',
  refreshToken: '',
  selectedAccount: undefined,
  redirect: '',
  tempTokens: {accessToken: '', refreshToken: '', selectedAccount: undefined},

  setRedirect: (r: string | null) => {
    // set in localstorage
    if (!localStorage) return
    try {
      const lsValue = JSON.stringify(r)
      localStorage.setItem('sb-login-redirect', lsValue)
    } catch (error) {
      console.error('REDIRECT LOCAL STORAGE SAVE ERROR', error)
    }

    set({redirect: r})
  },

  unsetRedirect: () => {
    // remove from localstorage
    if (!localStorage) return
    localStorage.removeItem('sb-login-redirect')

    set({redirect: null})
  },

  retrieveRedirect: () => {
    // get from localstorage
    if (!localStorage) return null
    const lsValue: string | null = localStorage.getItem('sb-login-redirect')

    // if value is found in local storage unstringify and return
    const val = lsValue ? JSON.parse(lsValue) : null

    // set in store
    if (val) {
      set({redirect: val})
      return val
    }

    return get().redirect
  },

  setupAuth: async (auth: AuthTokensModel) => {
    // update the store
    set({...auth})

    // update local stores
    if (!localStorage) return
    try {
      const lsValue = JSON.stringify(auth)
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }

    // Update axiosInstance interceptor with new token

    const myInterceptor = await axios.interceptors.request.use(async (config) => {
      const {refreshToken, accessToken} = get()

      if (!accessToken) return Promise.resolve(config)
      config.headers.Authorization = `Bearer ${accessToken}`

      // decode the jwt token to get the expiry date
      const user = jwtDecode(accessToken)

      // check if token is expired
      if (!user.exp) return Promise.resolve(config)
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

      // if token is not expired, return the request
      if (isExpired === false) return Promise.resolve(config)

      // if token is expired, get new tokens from server
      const baseURL = process.env.REACT_APP_API_URL
      const axiosInstance = axios.create({baseURL: baseURL})
      const response = await axiosInstance.post(`${baseURL}/auth/refresh-tokens`, {
        refreshToken: refreshToken,
      })

      // extract the tokens from the response
      const newAuth: AuthTokensModel = {
        accessToken: response.data.access.token,
        refreshToken: response.data.refresh.token,
        selectedAccount: response.data.selectedAccount,
      }

      // update the store
      set({...newAuth})

      // update local stores with new tokens
      const lsValue = JSON.stringify(newAuth)
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)

      // update header
      config.headers.Authorization = `Bearer ${newAuth.accessToken}`
      console.log('REFRESHED TOKEN', newAuth.accessToken)

      return Promise.resolve(config)
    })

    // store the interceptor
    set({interceptor: myInterceptor})

    return Promise.resolve()
  },

  /**
   *
   * @returns Returns the auth tokens from local storage
   */
  retrieveAuth: async () => {
    // if no local storage, return undefined
    if (!localStorage) return undefined

    // get auth from local storage
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) return undefined

    try {
      let auth: AuthTokensModel = JSON.parse(lsValue) as AuthTokensModel
      if (!auth) return

      // CHECK IF TOKEN HAS EXPIRED. (this must be done here because the interceptor is not yet  updated)
      const userToken = jwtDecode(auth.accessToken)
      if (userToken.exp === undefined) return
      const isExpired = dayjs.unix(userToken.exp).diff(dayjs()) < 1

      // if token is not expired, return the request
      if (isExpired === true) {
        // if token is expired, get new tokens from server
        const baseURL = process.env.REACT_APP_API_URL
        const axiosInstance = axios.create({baseURL: baseURL})
        const response = await axiosInstance.post(`${baseURL}/auth/refresh-tokens`, {
          refreshToken: auth.refreshToken,
        })

        // extract the tokens from the response
        auth = {
          accessToken: response.data.access.token,
          refreshToken: response.data.refresh.token,
          selectedAccount: response.data.selectedAccount,
        }
      }

      const {setupAuth} = get()
      await setupAuth(auth)

      // SETUP ACCOUNTS USERS STORE

      // step 1.  Verify the token (this gets the user and accounts users)

      const response = await _verifyToken(auth.accessToken)
      const rights = await _getRights()

      // step 2.  Update the accounts users store

      const {setupAccountsUsersStore} = accountsUsersStore.getState()
      const accountsUsers: AccountsUsersModel[] = response.data.accountsUsers
      setupAccountsUsersStore(rights.data, accountsUsers)

      // step 3.  Check if selectedAccount is in the accountsUsers array
      if (auth.selectedAccount !== undefined) {
        const {setSelectedAccountsUsers} = accountsUsersStore.getState()
        setSelectedAccountsUsers(auth.selectedAccount)
      }

      // return auth tokens
      return auth
    } catch (error) {
      const {logout} = get()
      logout()
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
      return undefined
    }
  },

  unsetAuth: () => {
    // update the store
    try {
      // remove tokens from store
      set({accessToken: '', refreshToken: '', selectedAccount: undefined})

      // remove tokens from local storage
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)

      // remove tokens from axios instance.
      const {interceptor} = get()
      axios.interceptors.request.eject(interceptor)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }

    // remove tokens from axios instance.
    const {interceptor} = get()
    axios.interceptors.request.eject(interceptor)
  },

  isLoggedIn: () => {
    const {accessToken, refreshToken} = get()
    const loggedIn = accessToken !== '' && refreshToken !== '' ? true : false
    return loggedIn
  },

  login: async (email: string, password: string) => {
    try {
      // login with username and password
      const loginResponse: any = await _login(email, password)
      const rights: any = await _getRights()

      // extract the tokens from the response
      const auth: AuthTokensModel = {
        accessToken: loginResponse.data.tokens.access.token,
        refreshToken: loginResponse.data.tokens.refresh.token,
        selectedAccount: loginResponse.data.tokens.selectedAccount,
      }

      // save token in storage
      const {setupAuth} = get()
      await setupAuth(auth)

      // update accounts users store
      const {setupAccountsUsersStore} = accountsUsersStore.getState()
      const accountsUsers: AccountsUsersModel[] = loginResponse.data.accountsUsers
      setupAccountsUsersStore(rights.data, accountsUsers)

      return true
    } catch (error) {
      return false
    }
  },

  logout: () => {
    // remove tokens from local storage
    const {unsetAuth, unsetRedirect} = get()
    unsetRedirect()

    // unset patron from patronStore
    const {unsetPatron} = patronStore.getState()
    unsetPatron()

    // unset client from clientStore
    const {unsetClient} = clientStore.getState()
    unsetClient()

    // remove tokens from store
    unsetAuth()
  },

  reload: async () => {
    // When the screen is reloaded, check if the token is valid and refresh if it's not. if failed, logout
    const {retrieveAuth} = get()
    retrieveAuth()
  },

  setupAxios: () => {
    // set axios to use json
    axios.defaults.headers.Accept = 'application/json'

    // setup response interceptor to pick up error 401
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const {response} = error
        if (response && response.status === 401) {
          console.log('Unauthorized')
          // Handle unauthorized access, e.g., redirect to login page
        }
        return Promise.reject(error)
      }
    )
  },

  selectAccount: async (accountsUsersId: string) => {
    try {
      // send the selected accountsUsersId to the server and get an updated token
      const response = await _selectAccount(accountsUsersId)

      // Update tokens (server responds with new JWT tokens with account embedded inside)
      const tokens: AuthTokensModel = {
        accessToken: response.data.access.token,
        refreshToken: response.data.refresh.token,
        selectedAccount: response.data.selectedAccount,
      }

      // update axios with new access token
      const {setupAuth} = get()
      setupAuth(tokens)

      // update the accounts users store (set the account)
      const {setSelectedAccountsUsers} = accountsUsersStore.getState()
      setSelectedAccountsUsers(accountsUsersId)
      return true
    } catch (error) {
      return false
    }
  },

  deselectAccount: () => {
    // remove the account from the accounts users store
    set({selectedAccount: undefined})

    // remove the account from the accounts users store
    const {unsetSelectedAccountsUsers} = accountsUsersStore.getState()
    unsetSelectedAccountsUsers()
  },

  forgotPassword: async (email: string) => {
    try {
      await _forgotPassword(email)
      return true
    } catch {
      return false
    }
  },

  resetPassword: async (password: string, token: string) => {
    try {
      await _resetPassword({password, token})
      return true
    } catch {
      return false
    }
  },

  handleExists: async (handle: string) => {
    try {
      const response = await _handleExists(handle)
      if (response.data.exists === true) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  },

  emailExists: async (email: string) => {
    try {
      const response = await _emailExists(email)
      if (response.data.exists === true) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  },

  /**
   * Registers the user
   * @param registration
   */
  register: async (registration: RegistrationModel) => {
    try {
      const payload = omit(registration, ['userPasswordConfirm', 'acceptTerms'])
      await _register(payload)
      return true
    } catch (error) {
      return false
    }
  },

  verifyEmail: async (token: string) => {
    try {
      await _verifyToken(token)
      return true
    } catch {
      return false
    }
  },
})

export const authStore = create(devtools(createStore))
export const useAuthStore = authStore
