/* eslint-disable jsx-a11y/anchor-is-valid */

import {KTIcon} from '../../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import HandleInput from '../../../../../../_components/HandleInput'

function Step1() {
  return (
    <div className='w-100'>
      {/* begin:: Header */}

      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Account Settings
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          All the accounts have the same features. The difference is the level of support you
          recieve. For more information please <a href='https://wa.me/27736898968'>WhatsApp</a> Us.
        </div>
      </div>

      {/* End:: header */}

      {/* BEGIN:: Select Account Type */}
      <div className='mb-10 fv-row'>
        {/* <h2 className='fw-bolder d-flex align-items-center text-dark'> */}
        <h3 className='fw-bolder d-flex align-items-center mb-5'>
          Choose Account Type
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </h3>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='user' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Independent Curator
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  I curate and manage my own events and properties
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                type='radio'
                name='accountPlan'
                value='independent'
              />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='profile-user' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>Agency</span>
                <span className='fs-6 fw-bold text-gray-400'>
                  We curate and manage other people's events and properties
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='agency' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='flag' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Non Profit Company (NGO)
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  We are a non-profit company (or NGO)
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                type='radio'
                name='accountPlan'
                value='nonprofit'
              />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='bank' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Government Department
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  We are a government or state-owned entity
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                type='radio'
                name='accountPlan'
                value='government'
              />
            </span>
          </label>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <h3 className='fw-bolder d-flex align-items-center mb-5'>Account Name</h3>

        <Field
          type='text'
          className='form-control form-control-lg  '
          name='accountName'
          placeholder='E.g. Your company or stage name'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <h3 className='fw-bolder d-flex align-items-center mb-5'>Handle</h3>
        <HandleInput name='accountHandle' placeholder='yourHandle' />

        <div className='text-danger mt-2'>
          <ErrorMessage name='accountHandle' />
        </div>

        <div className='form-text'>
          The handle must only contain letters, numbers, and underscores. It must be at least 3
          characters in length and no more than 50.
        </div>
      </div>
    </div>
  )
}

export {Step1}
