import {useEffect, useState} from 'react'
import {CheckpointModel, CheckpointsQuery, useEventsStore} from '../core/eventsStore'
import {ErrorMessage, Field, Form, Formik} from 'formik'

import {KTIcon} from '../../../../_metronic/helpers'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Checkpoint name is required'),
})

const CheckpointEditor = () => {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState('')

  const {tickets, currentEvent, saveCheckpoint, checkpoints, getCheckpoints, deleteCheckpoint} =
    useEventsStore()

  const [initialCheckpoint] = useState<CheckpointModel>({
    event: currentEvent.id || '',
    color: '',
    name: '',
  })

  const handleSubmit = async (values, action) => {
    // Add your logic here
    setLoading(true)
    try {
      if (!currentEvent.id) return
      await saveCheckpoint(currentEvent.id, values)

      // Reset the form after submission
      action.resetForm()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleDelete = async (event: string, id: string | undefined) => {
    // Add your logic here
    if (!id) return

    setDeleting(id)

    try {
      await deleteCheckpoint(event, id)
    } catch (error) {
      console.log(error)
    }
    setDeleting('')
  }

  const isInUse = (checkpointId) => {
    // check if checkpoint is in use
    if (!tickets) return false

    return tickets.some((ticket) => {
      if (!ticket || !ticket.checkpointSettings) return false
      return ticket.checkpointSettings.some(
        (checkpoint) => checkpoint.checkpoint.id === checkpointId
      )
    })
  }

  useEffect(() => {
    const getCheckpointsQuery: CheckpointsQuery = {
      limit: 100,
      page: 1,
    }

    if (!currentEvent.id) return

    getCheckpoints(currentEvent.id, getCheckpointsQuery)
  }, [currentEvent, getCheckpoints, saveCheckpoint, deleteCheckpoint])

  return (
    <>
      <Formik
        initialValues={initialCheckpoint}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({submitForm}) => (
          <Form>
            <div className='d-flex w-100 flex-column mb-5'>
              <div className='input-group mb-3'>
                <Field
                  type='text'
                  name='name'
                  placeholder='Add Checkpoint e.g. Gate, Door, Parking, Bar'
                  className='form-control form-control-lg'
                />
                <button type='button' className='btn btn-light-info' onClick={submitForm}>
                  {loading ? (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  ) : (
                    <KTIcon iconName='plus' className='me-2' />
                  )}
                  Add Checkpoint
                </button>
              </div>
              <div className='d-flex'>
                <ErrorMessage name='name' component='div' className='text-danger' />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className='d-flex'>
        {checkpoints.map((item, index) => (
          <div key={'item-' + index} className='badge badge-light-primary me-2 ps-7 '>
            <span className=''>
              {item.name}

              {isInUse(item.id) ? (
                <span className='badge badge-light-danger ms-2'>In Use</span>
              ) : (
                <button
                  type='button'
                  onClick={() => handleDelete(item.event, item.id)}
                  className='btn btn-icon btn-sm btn-active-color-danger'
                >
                  {deleting === item.id ? (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  ) : (
                    <KTIcon iconName='trash' />
                  )}
                </button>
              )}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}
export {CheckpointEditor}
