import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {EventDashboardModel, FunnelValueModel, useEventsStore} from './core/eventsStore'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import {Bar} from 'react-chartjs-2'
import 'chart.js/auto'
import moment from 'moment'
import {
  formatMoney,
  formatNumber,
  formatSeconds,
  pluralise,
  trimCharacters,
} from '../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import {getCSSVariableValue, isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import clsx from 'clsx'

type KPIType = {
  title: string
  subTitle: string
  value: string | number
  icon: string
  color: string
}
const EventAnalyticsPage = () => {
  const {eventId} = useParams()
  const {getEventDashboard, getEvent, currentEvent, tickets, getTickets} = useEventsStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState<EventDashboardModel>()
  const [funnelData, setFunnelData] = useState<any>(null)
  const [salesOrBookingsData, setSalesOrBookingsData] = useState<any>(null)
  const [salesOrBookingsMode, setSalesOrBookingsMode] = useState<'sales' | 'bookings'>('sales')
  const [dateRange, setDateRange] = useState<{startDate: string; endDate: string}>({
    startDate: '',
    endDate: '',
  })
  const [funnelPeriod, setFunnelPeriod] = useState<'current' | 'previous'>('current')
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [kpiData, setKpiData] = useState<[KPIType]>()

  // SETUP PAGE
  useEffect(() => {
    if (eventId) {
      const today = moment()
      const startOfThisWeek = today.clone().startOf('week').format('YYYY-MM-DD')
      const endOfThisWeek = today.clone().endOf('week').format('YYYY-MM-DD')
      setDateRange({startDate: startOfThisWeek, endDate: endOfThisWeek})
      setIsLoading(true)
      Promise.all([
        getEventDashboard(eventId, startOfThisWeek, endOfThisWeek),
        getEvent(eventId),
        getTickets(eventId, {page: 1, limit: 999}),
      ])
        .then((data) => {
          setDashboardData(data[0])
        })
        .finally(() => setIsLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  // SET BREADCRUMBS
  useEffect(() => {
    if (!currentEvent.id) return
    const crumbs = [
      {object: 'Events', title: 'All', link: '/events'},
      {object: 'Events', title: trimCharacters(currentEvent.name, 12), link: `/events/${eventId}`},
      {object: 'Insights', title: 'Insights', link: `/events/${eventId}/analytics`},
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Insights')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  // SETUP CHART DATA
  useEffect(() => {
    if (!dashboardData) return

    // funnel data
    const fData = dashboardData?.funnelData?.current
      ? {
          labels: dashboardData.funnelData.current.labels,
          datasets: [
            {
              label: 'Current Period',
              data: dashboardData.funnelData.current.values.map(
                (stage: FunnelValueModel) => stage.conversionRate
              ),
              backgroundColor: getCSSVariableValue(`--bs-dark`), // Black color for current period
            },
            {
              label: 'Previous Period',
              data: dashboardData.funnelData.previous.values.map(
                (stage: FunnelValueModel) => stage.conversionRate
              ),
              backgroundColor: getCSSVariableValue(`--bs-secondary`), // Grey color for previous period
            },
          ],
        }
      : null

    // store in state
    setFunnelData(fData)

    // sales data
    loadSalesOrBookingsData(currentEvent.sellTickets ? 'sales' : 'bookings')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData])

  const loadSalesOrBookingsData = (mode: 'sales' | 'bookings' = 'sales') => {
    if (!dashboardData) return

    let sData: any = {}
    setSalesOrBookingsMode(mode)
    if (mode === 'sales') {
      sData =
        dashboardData.salesChartData?.current || dashboardData.salesChartData?.previous
          ? {
              labels: dashboardData.salesChartData?.current?.labels || [],
              datasets: [
                {
                  label: 'Current Period',
                  data: dashboardData.salesChartData?.current?.series || [],
                  backgroundColor: getCSSVariableValue(`--bs-dark`), // Black color for current period
                },
                {
                  label: 'Previous Period',
                  data: dashboardData.salesChartData?.previous?.series || [],
                  backgroundColor: getCSSVariableValue(`--bs-secondary`), // Grey color for previous period
                },
              ],
            }
          : null
    } else {
      sData =
        dashboardData.bookingsChartData?.current || dashboardData.bookingsChartData?.previous
          ? {
              labels: dashboardData.bookingsChartData?.current?.labels || [],
              datasets: [
                {
                  label: 'Current Period',
                  data: dashboardData.bookingsChartData?.current?.series || [],
                  backgroundColor: getCSSVariableValue(`--bs-dark`), // Black color for current period
                },
                {
                  label: 'Previous Period',
                  data: dashboardData.bookingsChartData?.previous?.series || [],
                  backgroundColor: getCSSVariableValue(`--bs-secondary`), // Grey color for previous period
                },
              ],
            }
          : null
    }

    // store sales data in state
    setSalesOrBookingsData(sData)
  }

  // SETUP KPIS
  useEffect(() => {
    if (!currentEvent.id) return

    const ticketsLabel = currentEvent.sellTickets ? 'Tickets' : 'Spaces'
    const ticketDescription = currentEvent.sellTickets
      ? `${pluralise(currentEvent.stats.bookingsTicketsCount, 'ticket')} issued`
      : `${pluralise(currentEvent.stats.bookingsTicketsCount, 'space')} booked`

    const k: [KPIType] = [
      {
        title: `Total ${ticketsLabel}`,
        subTitle: `Total ${ticketDescription}`,
        value: formatNumber(currentEvent.stats.bookingsTicketsCount, 0, true),
        icon: 'calendar',
        color: 'info',
      },
    ]

    // AVERAGE TICKETS PER BOOKING
    const avgTicketsPerBooking =
      currentEvent.stats.bookingsCount === 0
        ? 0
        : currentEvent.stats.bookingsTicketsCount / currentEvent.stats.bookingsCount
    k.push({
      title: `Avg ${ticketsLabel} per Booking`,
      subTitle: `Average ${ticketsLabel.toLowerCase()} Per booking`,
      value: formatNumber(avgTicketsPerBooking, 2, true),
      icon: 'calendar',
      color: 'info',
    })

    if (currentEvent.sellTickets) {
      k.push({
        title: 'Total Sales',
        subTitle: 'Total sales for the event',
        value: formatMoney(
          currentEvent.stats.bookingsValue,
          selectedAccountsUsers.account.currency,
          0,
          true
        ),
        icon: 'cart',
        color: 'success',
      })

      k.push({
        title: 'Conversion Rate',
        subTitle: `est. ${formatNumber(
          currentEvent.stats.bookingPaymentComplete,
          0
        )} of ${formatNumber(currentEvent.stats.eventViews, 0)} sessions`,
        value: `${formatNumber(currentEvent.stats.conversionRate, 0, false)}%`,
        icon: 'cart',
        color: 'success',
      })
    } else {
      k.push({
        title: 'Conversion Rate',
        subTitle: `est. ${currentEvent.stats.bookingConversionRate} of ${currentEvent.stats.eventViews} sessions`,
        value: `${formatNumber(currentEvent.stats.conversionRate, 0, false)}%`,
        icon: 'cart',
        color: 'success',
      })
    }

    setKpiData(k)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  const showFunnel = (period: 'current' | 'previous') => {
    setFunnelPeriod(period)
  }

  const handleSwitchSalesBookingsData = (mode: 'sales' | 'bookings') => {
    loadSalesOrBookingsData(mode)
  }
  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <div className='container-fluid'>
          {kpiData && (
            <>
              <div className='row mb-10'>
                <div className='col-md-12'>
                  <h1 className='text-dark fw-bold mb-3'>Key Insights</h1>
                  <p className='text-muted'>
                    Key insights do not have a date range and are based on the entire event data.
                    <KTIcon
                      iconType='solid'
                      iconName='information-3'
                      className='mx-1 text-warning'
                    />
                    Insights are still under construction. Please give us feedback on what you would
                    like to see here.
                  </p>
                </div>
              </div>

              {/* KPI Section */}
              <div className='row mb-10'>
                {kpiData.map((kpi, index) => (
                  <div className='col-md-3 col-6 mb-5 mb-md-0' key={index}>
                    <div
                      className={`card card-flush border-0 shadow-none bg-gray-200 bg-hover-secondary`}
                    >
                      <div className='card-header'>
                        <div className='card-title'>{kpi.title}</div>
                      </div>
                      <div className='card-body'>
                        <h3 className='text-dark fw-bold'>{kpi.value}</h3>
                        <p className='text-muted'>{kpi.subTitle}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* SALES & CONVERSION HEADER */}
          <div className='row mb-10'>
            <div className='col-md-12'>
              <h1 className='text-dark fw-bold mb-3'>Weekly Sales & Conversion Insights</h1>
              <p className='text-muted'>
                Sales and conversion insights from{' '}
                <span className='fw-bold'>{dateRange.startDate}</span> to{' '}
                <span className='fw-bold'>{dateRange.endDate}</span>
              </p>
            </div>
          </div>

          {/* SALES && FUNNEL CHARTS */}
          <div className='row mb-10'>
            {/* SALES CHART */}
            <div className='col-12 col-md-6 mb-5'>
              <div className='card bg-gray-200 border-0 shadow-none card-flush h-100'>
                <div className='card-header'>
                  <div className='d-flex justify-content-between w-100'>
                    <div className='d-flex flex-column pt-5'>
                      <h4 className='card-title'>
                        {currentEvent.sellTickets ? 'Sales' : 'Bookings'} Summary
                      </h4>
                      <div className='text-muted fw-semibold'>
                        <span className='fw-bold'>{dateRange.startDate}</span>
                        {' to '}
                        <span className='fw-bold'>{dateRange.endDate}</span>
                      </div>
                    </div>
                    {currentEvent.sellTickets && (
                      <div className='d-flex align-items-center'>
                        <div className='btn-group btn-group-sm '>
                          <button
                            type='button'
                            onClick={() => handleSwitchSalesBookingsData('sales')}
                            className={clsx(
                              'btn btn-light btn-sm btn-active-secondary btn-outline',
                              {
                                active: salesOrBookingsMode === 'sales',
                              }
                            )}
                          >
                            Sales
                          </button>
                          <button
                            type='button'
                            onClick={() => handleSwitchSalesBookingsData('bookings')}
                            className={clsx(
                              'btn btn-light btn-sm btn-active-secondary btn-outline',
                              {
                                active: salesOrBookingsMode === 'bookings',
                              }
                            )}
                          >
                            Bookings
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='card-body'>
                  {salesOrBookingsData ? (
                    <Bar
                      data={salesOrBookingsData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            grid: {
                              display: false, // This will remove the grid lines for the x-axis
                              lineWidth: 0, // This will remove the line at the x-axis
                            },
                            border: {
                              display: true, // This will remove the border around the chart
                            },
                            ticks: {
                              display: true, // This will remove the tick labels for the x-axis
                              callback: function (value, index, values) {
                                const date = new Date(this.getLabelForValue(index))
                                const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                                return days[date.getDay()]
                              },
                            },
                          },
                          y: {
                            grid: {
                              display: false, // This will remove the grid lines for the y-axis
                              lineWidth: 0, // This will remove the line at the y-axis
                              drawOnChartArea: false, // This will remove the y-axis line
                            },
                            border: {
                              display: false, // This will remove the border around the chart
                            },
                            ticks: {
                              display: false, // This will remove the tick labels for the y-axis
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 12,
                              },
                            },
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                let label = context.dataset.label || ''
                                if (label) {
                                  label += ': '
                                }
                                if (salesOrBookingsMode === 'sales') {
                                  label += formatMoney(
                                    context.parsed.y,
                                    selectedAccountsUsers.account.currency,
                                    0,
                                    true
                                  )
                                } else {
                                  label += formatNumber(context.parsed.y, 0, false)
                                }

                                return label
                              },
                            },
                          },
                        },
                      }}
                      height={isMobileDevice() ? 150 : 250}
                    />
                  ) : (
                    <p className='text-muted'>No sales data available.</p>
                  )}
                </div>
              </div>
            </div>

            {/* FUNNEL CHART */}
            <div className='col-12 col-md-6'>
              <div className='card bg-gray-200 bard-0 shadow-none card-flush'>
                <div className='card-header'>
                  <div className='d-flex flex-column pt-5'>
                    <h4 className='card-title'>Traffic Conversion Funnel</h4>
                    <div className='text-muted fw-semibold'>
                      <span className='fw-bold'>{dateRange.startDate}</span>
                      {' to '}
                      <span className='fw-bold'>{dateRange.endDate}</span>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  {funnelData ? (
                    <Bar
                      data={funnelData}
                      options={{
                        indexAxis: 'y',
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            grid: {
                              display: false, // This will remove the grid lines for the x-axis
                              lineWidth: 0, // This will remove the line at the x-axis
                            },
                            border: {
                              display: true, // This will remove the border around the chart
                            },
                            ticks: {
                              display: true, // This will remove the tick labels for the x-axis
                            },
                          },
                          y: {
                            grid: {
                              display: false, // This will remove the grid lines for the y-axis
                              lineWidth: 0, // This will remove the line at the y-axis
                              drawOnChartArea: false, // This will remove the y-axis line
                            },
                            border: {
                              display: false, // This will remove the border around the chart
                            },
                            ticks: {
                              display: true, // This will remove the tick labels for the y-axis
                              callback: function (value, index, values) {
                                const label = this.getLabelForValue(index)
                                // remove underscores and capitalise each word
                                return label
                                  .replace(/_/g, ' ')
                                  .replace(/\b\w/g, (c) => c.toUpperCase())
                              },
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 12,
                              },
                            },
                          },
                          tooltip: {
                            callbacks: {
                              title: function (context) {
                                const label = context[0].label || ''

                                // remove underscores and capitalise each word
                                return label
                                  .replace(/_/g, ' ')
                                  .replace(/\b\w/g, (c) => c.toUpperCase())
                              },
                              label: function (context) {
                                let label = ''

                                if (context.parsed.x !== null && funnelData) {
                                  label += `Conversion Rate: ${formatNumber(
                                    context.parsed.x,
                                    0,
                                    false
                                  )}%`
                                }
                                return label
                              },
                            },
                          },
                        },
                      }}
                      height={250}
                    />
                  ) : (
                    <p className='text-muted'>No funnel data available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* FUNNEL TABLE */}
          <div className='row mb-10'>
            <div className='col-12 '>
              {/* Funnel Table */}

              <div className='card card-flush border-0 shadow-none bg-gray-200 bg-hover-gray-400'>
                <div className='card-header w-100'>
                  <div className='d-flex w-100 justify-content-between mt-5'>
                    <div className='d-flex flex-column'>
                      <h4 className='card-title'>
                        <KTIcon iconType='solid' iconName='information-3' className='mx-1' />
                        Detailed Funnel Data
                      </h4>
                      <p className='text-muted'>
                        Detailed funnel data from{' '}
                        <span className='fw-bold'>{dateRange.startDate}</span> to{' '}
                        <span className='fw-bold'>{dateRange.endDate}</span>
                      </p>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='btn-group btn-group-sm '>
                        <button
                          type='button'
                          onClick={() => showFunnel('current')}
                          className={clsx('btn btn-light btn-sm btn-active-secondary btn-outline', {
                            active: funnelPeriod === 'current',
                          })}
                        >
                          This Week
                        </button>
                        <button
                          type='button'
                          onClick={() => showFunnel('previous')}
                          className={clsx('btn btn-light btn-sm btn-active-secondary btn-outline', {
                            active: funnelPeriod !== 'current',
                          })}
                        >
                          Last Week
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body table-responsive'>
                  {dashboardData?.funnelData?.current ? (
                    <table className='table table-hover  '>
                      <thead className='fw-bold'>
                        <tr>
                          <th>Stage</th>
                          <th className='text-center'>Sessions</th>
                          <th className='text-center'>
                            <KTIcon iconName='information-3' iconType='solid' className='fw-4' />{' '}
                            Avg. Duration
                          </th>
                          <th className='text-center'>Abandoned</th>
                          <th className='text-center'>Converted</th>
                          <th className='text-center'>Abandoned Rate (%)</th>
                          <th className='text-center'>Conversion Rate (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {funnelPeriod === 'current' ? (
                          <>
                            {dashboardData.funnelData.current.values.map(
                              (stage: any, index: number) => (
                                <tr key={index}>
                                  <td className='fw-bold text-start min-w-150px'>
                                    {index + 1}.{' '}
                                    {dashboardData.funnelData?.current.labels[index]
                                      .replace(/_/g, ' ')
                                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                                  </td>
                                  <td className='text-center'>{stage.value}</td>
                                  <td className='text-center'>
                                    {formatSeconds(stage.duration, true)}
                                  </td>
                                  <td className='text-center'>{stage.abandoned}</td>
                                  <td className='text-center'>{stage.converted}</td>
                                  <td className='text-center'>
                                    {parseFloat(stage.abandonedRate).toFixed(0)}%
                                  </td>
                                  <td className='text-center'>
                                    {parseFloat(stage.conversionRate).toFixed(0)}%
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {dashboardData.funnelData.previous.values.map(
                              (stage: any, index: number) => (
                                <tr key={index}>
                                  <td className='fw-bold text-start min-w-150px'>
                                    {index + 1}.{' '}
                                    {dashboardData.funnelData?.previous.labels[index]
                                      .replace(/_/g, ' ')
                                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                                  </td>

                                  <td className='text-center'>{stage.value}</td>
                                  <td className='text-center'>
                                    {formatSeconds(stage.duration, true)}
                                  </td>
                                  <td className='text-center'>{stage.abandoned}</td>
                                  <td className='text-center'>{stage.converted}</td>
                                  <td className='text-center'>
                                    {parseFloat(stage.abandonedRate).toFixed(0)}%
                                  </td>
                                  <td className='text-center'>
                                    {parseFloat(stage.conversionRate).toFixed(0)}%
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p className='text-muted'>No funnel data available.</p>
                  )}

                  {/* notes */}
                  <div className='d-flex flex-column text-muted'>
                    <div>
                      <KTIcon iconType='solid' iconName='information-3' className='mx-1 ' />
                      Funnel data can be delayed by up to 24 hours.
                    </div>
                    <div>
                      <KTIcon iconType='solid' iconName='information-3' className='mx-1 ' />
                      Avg. Duration is calculated as the avg user engagement duration per stage.
                      Engagement considers several factors including time, scrolling, swipes, clicks
                      etc.
                    </div>
                    <div>
                      <KTIcon iconType='solid' iconName='information-3' className='mx-1 ' />
                      Conversion rate is calculated as the percentage of sessions that completed the
                      final stage compared to the first stage.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TICKET SALES / BOOKINGS TABLE */}
          <div className='d-row mb-10'>
            <div className='col-12'>
              <div className='card card-flush border-0 shadow-none bg-gray-200 bg-hover-400'>
                <div className='card-header'>
                  <div className='d-flex flex-column mt-5'>
                    <h4 className='card-title'>
                      Ticket {currentEvent.sellTickets ? 'Sales' : 'Bookings'}
                    </h4>
                    <p className='text-muted'>
                      Detailed ticket {currentEvent.sellTickets ? 'sales' : 'bookings'} throughout
                      the whole event
                    </p>
                  </div>
                </div>
                <div className='card-body table-responsive'>
                  <table className='table table-hover '>
                    <thead className='fw-bold'>
                      <tr>
                        <th>Ticket</th>
                        <th className='text-center'>Issued</th>
                        <th className='text-end'>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets
                        .sort((a, b) => b.bookedTickets - a.bookedTickets)
                        .map((ticket, index) => (
                          <tr key={index}>
                            <td className='fw-bold text-start'>
                              {/*  */}
                              {ticket.name}
                            </td>
                            <td className='text-center'>{ticket.bookedTickets}</td>
                            <td className='text-end'>
                              {formatMoney(
                                ticket.bookedTickets * ticket.price,
                                selectedAccountsUsers.account.currency,
                                0,
                                false
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {EventAnalyticsPage}
