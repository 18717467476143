import {Form, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {ChangeEvent, useEffect, useState} from 'react'
import {ErrorMessage, Field, FieldArray, Formik} from 'formik'
import SubmitButton from '../../../../_components/SubmitButton'
import DateInput from '../../../../_components/DateInput'
import {
  CheckpointSettingsModel,
  TicketModel,
  initialTicket,
  useEventsStore,
} from '../core/eventsStore'

import NumberInput from '../../../../_components/NumberInput'
import {CheckpointSettingsItem} from './CheckpointSettingsItem'
import {useAppSettingsStore} from '../../settings/core/appSettingsStore'
import {formatDateDMY, formatNumber} from '../../../../_helpers/_helpers'
import * as Yup from 'yup'
import {TicketItem} from './TicketItem'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import toast, {Toaster} from 'react-hot-toast'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

// create validation schema
const validationSchema = Yup.object({
  name: Yup.string().required().label('Ticket Name'),
  description: Yup.string().label('Ticket Description'),

  // test that start date is less than end date
  startDate: Yup.string()
    .test('startDate', 'Start Date must be less than End Date', function (value) {
      if (!value) return true
      const {endDate} = this.parent
      return new Date(value) <= new Date(endDate)
    })
    .label('Start Date'),

  endDate: Yup.string()
    .test('endDate', 'End Date must be greater than Start Date', function (value) {
      if (!value) return true
      const {startDate} = this.parent
      return new Date(value) >= new Date(startDate)
    })
    .label('End Date'),

  qty: Yup.number().required().label('Ticket quantities'),

  // test that maxQTY is greater than minQTY
  minQty: Yup.number()
    .test('minQTY', 'Max Qty must be greater than Min Selection', function (value) {
      if (!value) return true
      const {maxQty} = this.parent

      return value <= maxQty
    })
    .test('minQTY', 'Minimum 1 selection', function (value) {
      if (!value) return true
      return value >= 1
    })

    .required()
    .label('Min Selection'),

  maxQty: Yup.number()
    .test('maxQTY', 'Max Qty must be greater than Min Selection', function (value) {
      if (!value) return true
      const {minQty} = this.parent
      return value >= minQty
    })
    .required()
    .label('Max Selection'),

  // check that checkpointSettings have at least one item with a qty greater than 0
  checkpointSettings: Yup.array()

    .test('checkpointSettings', 'Select at least one checkpoint', function (value) {
      if (!value) return false

      // check if at least one item has a qty greater than 0
      return value.some((item) => item && item.qty > 0)
    })
    .label('Checkpoint Settings'),

  price: Yup.number().required().label('Ticket Price'),
})

type PayoutCalculations = {
  price: number
  feeAmount: number
  gatewayFeeAmount: number
  payoutAmount: number
  clientAmount: number
  finalAmount: number
}

const TicketEditor: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSubmittingClient, setIsSubmittingClient] = useState(false)
  const {
    checkpoints,
    currentEvent,
    ticketsPagination,
    saveTicket,
    saveTickets,
    getTickets,
    deleteTicket,
    tickets,
  } = useEventsStore()
  const [checkpointSettings, setCheckpointSettings] = useState<CheckpointSettingsModel[]>([])
  const [currentTicket, setCurrentTicket] = useState<TicketModel | null>(null)
  const {appSettings} = useAppSettingsStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [payoutCalculation, setPayoutCalculation] = useState<PayoutCalculations>({
    price: 0,
    feeAmount: 0,
    gatewayFeeAmount: 0,
    payoutAmount: 0,
    clientAmount: 0,
    finalAmount: 0,
  })

  useEffect(() => {
    // CALCULATE PAYOUTS
    calculatePayouts(currentTicket?.price || 0)

    // eslint-disable-next-line
  }, [currentTicket])

  useEffect(() => {
    if (currentEvent.id) getTickets(currentEvent.id, ticketsPagination)

    // SYNC CHECKPOINT SETTINGS
    if (checkpoints) {
      const newCheckpointSettings: any = checkpoints.map((checkpoint) => {
        if (currentTicket) {
          const checkpointSettings = currentTicket.checkpointSettings.find(
            (setting) => setting.checkpoint.id === checkpoint.id
          )
          if (checkpointSettings) {
            return {
              checkpoint: checkpoint,
              qty: checkpointSettings.qty,
              id: checkpointSettings.id,
            }
          }
        }

        return {
          checkpoint: checkpoint,
          qty: 0,
          id: '',
        }
      })

      // remove undefined items
      setCheckpointSettings(newCheckpointSettings)
    }
    // eslint-disable-next-line
  }, [currentEvent.id, checkpoints])

  const handleSave = async (values: TicketModel) => {
    setIsSubmittingClient(true)

    if (!currentEvent.id) return

    try {
      await saveTicket(currentEvent.id, values)
      setShowModal(false)
    } catch (err) {
      console.error(err)
    }
    setIsSubmittingClient(false)
  }

  const calculatePayouts = (price: number) => {
    const fee = price * (appSettings.fee / 100)
    const vat = fee * (appSettings.vat / 100)
    const feeAmount = fee + vat

    const gatewayFeeAmount =
      price * (appSettings.gatewayFeePercentage / 100) + appSettings.gatewayFee
    const payoutAmount = price - feeAmount - gatewayFeeAmount

    // if this is a managed service, check the client commission and calculate the client amount
    if (currentEvent.billingModel === 'managed') {
      const clientAmount = payoutAmount - payoutAmount * (currentEvent.commission / 100)
      const finalAmount = payoutAmount - clientAmount
      setPayoutCalculation({
        price,
        feeAmount,
        payoutAmount,
        gatewayFeeAmount,
        clientAmount,
        finalAmount,
      })
    } else {
      setPayoutCalculation({
        price,
        feeAmount,
        gatewayFeeAmount,
        payoutAmount,
        clientAmount: 0,
        finalAmount: payoutAmount,
      })
    }
  }

  const handlePriceChange = (form: any, e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    const price = parseFloat(val)
    calculatePayouts(price)
    form.handleChange(e) // Call the form's handleChange method to update the form's values
  }

  const handleEditTicket = (id: string) => {
    const ticket = tickets.find((t) => t.id === id)
    if (ticket) {
      setCurrentTicket(ticket)
      setShowModal(true)
    }
  }

  const handleAddTicket = () => {
    setCurrentTicket(null)
    setShowModal(true)
  }

  const handleDeleteTicket = async (id: string) => {
    try {
      if (currentEvent.id) await deleteTicket(currentEvent.id, id)
    } catch (err: any) {
      toast.error(err.response.data.message)
    }
  }

  const handlePagination = async (page: number) => {
    if (!currentEvent.id) return

    await getTickets(currentEvent.id, {...ticketsPagination, page})
  }

  const handleOnDragEnd = async (result) => {
    if (!result.destination || !currentEvent.id) return

    const updatedTickets = Array.from(tickets)
    const [reorderedItem] = updatedTickets.splice(result.source.index, 1)
    updatedTickets.splice(result.destination.index, 0, reorderedItem)

    // Prepare the tickets with the new order
    const updatedTicketsWithOrder = updatedTickets.map((item, index) => ({
      ...item,
      order: index,
    }))

    try {
      setIsSubmitting(true)

      // Assuming `saveTicketsOrder` is an API call that saves the updated order for all tickets at once
      await saveTickets(currentEvent.id, updatedTicketsWithOrder)

      // Optionally update local state after successful save
      setIsSubmitting(false)

      toast.success('Tickets order updated successfully!')
    } catch (err) {
      setIsSubmitting(false)
      toast.error('There was a problem saving the new order')
    }
  }

  return (
    <>
      <Toaster position='top-center' />

      <div className='d-flex flex-column mb-10'>
        <div className='d-flex justify-content-end mb-10'>
          <button
            className='btn btn-light-info btn-sm w-150px '
            type='button'
            onClick={handleAddTicket}
          >
            <KTIcon iconName='plus' className='me-2' />
            Add Ticket
          </button>
        </div>
        <div className='fs-7 mb-10 '>
          {/* note you can reorder tickets */}
          <KTIcon iconName='information-2' iconType='outline' className='me-1' />
          You can reorder tickets by dragging and dropping them. Tickets will appear on the booking
          page in the order they are listed here.
        </div>
        <div className='d-flex flex-column w-100'>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='tickets'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {tickets?.map((ticket, index) => (
                    <Draggable key={ticket.id} draggableId={ticket.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TicketItem
                            ticket={ticket}
                            onEdit={handleEditTicket}
                            onDelete={handleDeleteTicket}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* pagination */}
          {ticketsPagination.totalResults > ticketsPagination.limit && (
            <div className='d-flex justify-content-center'>
              <PaginationControl
                total={ticketsPagination.totalResults}
                page={ticketsPagination.page}
                limit={ticketsPagination.limit}
                changePage={handlePagination}
              />
            </div>
          )}
        </div>
      </div>

      <Modal className='modal-lg' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={currentTicket || initialTicket}
            enableReinitialize={true}
            onSubmit={handleSave}
          >
            {(form) => (
              <Form
                onSubmit={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  form.submitForm()
                }}
              >
                <>
                  <div className='modal-header'>
                    <h2 className='fw-bolder'>
                      {currentTicket?.id ? 'Update ' : 'Add New '} Ticket
                    </h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                    >
                      <KTIcon iconName='x' className='fs-2' />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <>
                      {/* flush accordion */}
                      <div className='accordion accordion-flush' id='ticketAccordion'>
                        {/* #1. Basic Ticket Info */}
                        <div className='accordion-item mb-3'>
                          <h2 className='accordion-header' id='flush-basic'>
                            <button
                              className='accordion-button collapsed bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-basic-collapse'
                              aria-expanded='false'
                              aria-controls='flush-basic-collapse'
                            >
                              {/* create a circle that matches valitation status for fields in item */}

                              <span className='me-3'>
                                {(form.errors.name || form.errors.description) && (
                                  <KTIcon iconName='information-2' className='text-danger fs-2' />
                                )}
                                {!form.errors.name && !form.errors.description && (
                                  <KTIcon iconName='like' className='text-success fs-2' />
                                )}
                              </span>
                              <div className='d-flex flex-column'>
                                <span className='fw-bold'>#1 Basic Ticket Info</span>
                                <span className='mt-1 text-muted fw-normal fs-6'>
                                  {form.values.name}
                                </span>
                              </div>
                            </button>
                          </h2>
                          <div
                            id='flush-basic-collapse'
                            className='accordion-collapse collapse'
                            aria-labelledby='flush-basic-collapse'
                            data-bs-parent='#ticketAccordion'
                          >
                            <div className='accordion-body pt-15'>
                              <div className='fv-row mb-10'>
                                <div className='col mb-10'>
                                  <label className='form-label fw-bolder'>Ticket Name</label>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg '
                                    name='name'
                                  />
                                  <ErrorMessage
                                    name='name'
                                    component='div'
                                    className='text-danger'
                                  />
                                </div>
                                <div className='col mb-10'>
                                  <label className='form-label fw-bolder'>Ticket Description</label>
                                  <Field
                                    name='description'
                                    className='form-control form-control-lg '
                                  />
                                  <ErrorMessage
                                    name='description'
                                    component='div'
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                              <div className='separator separator-dashed mb-10'></div>
                              <div className='fv-row mb-10'>
                                <div className='row'>
                                  <div className='col'>
                                    <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                                      <Field
                                        className='form-check-input me-3'
                                        type='checkbox'
                                        name='isPrivate'
                                      />

                                      <span className='form-check-label d-flex flex-column align-items-start'>
                                        <span className='fw-bolder fs-5 text-dark mb-0'>
                                          This is a private ticket
                                        </span>
                                        <span className='text-muted fs-6'>
                                          Private tickets are not listed on the booking page. This
                                          makes it possible to issue complimentary tickets or
                                          reserve tickets for special guests and staff.
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* #2. Availability (start and end date) */}
                        <div className='accordion-item mb-3'>
                          <h2 className='accordion-header' id='flush-availability'>
                            <button
                              className='accordion-button collapsed bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-availability-collapse'
                              aria-expanded='false'
                              aria-controls='flush-availability-collapse'
                            >
                              <span className='me-3'>
                                {(form.errors.startDate || form.errors.endDate) && (
                                  <KTIcon iconName='information-2' className='text-danger fs-2' />
                                )}
                                {!form.errors.startDate && !form.errors.endDate && (
                                  <KTIcon iconName='like' className='text-success fs-2' />
                                )}
                              </span>

                              <div className='d-flex flex-column'>
                                <span className='fw-bold'>#2 Ticket Availability</span>
                                {new Date(form.values.startDate).toISOString() &&
                                  new Date(form.values.endDate).toISOString() && (
                                    <span className='text-muted fw-normal fs-6 mt-1'>
                                      From{' '}
                                      {formatDateDMY(new Date(form.values.startDate).toISOString())}{' '}
                                      to{' '}
                                      {formatDateDMY(new Date(form.values.endDate).toISOString())}
                                    </span>
                                  )}
                              </div>
                            </button>
                          </h2>
                          <div
                            id='flush-availability-collapse'
                            className='accordion-collapse collapse'
                            aria-labelledby='flush-availability-collapse'
                            data-bs-parent='#ticketAccordion'
                          >
                            <div className='accordion-body pt-15'>
                              <div className='fv-row mb-10'>
                                <div className='row'>
                                  <div className='col col-xl-6 mb-10'>
                                    <label className='form-label fw-bolder'>Start Date</label>
                                    <DateInput name='startDate' data-enable-time />

                                    {/* manually display the error message if validation fails */}
                                    {form.errors.startDate && (
                                      <div className='text-danger'>
                                        {String(form.errors.startDate)}
                                      </div>
                                    )}
                                  </div>
                                  <div className='col col-xl-6 mb-10'>
                                    <label className='form-label fw-bolder'>End Date</label>
                                    <DateInput name='endDate' data-enable-time />
                                    {form.errors.endDate && (
                                      <div className='text-danger'>
                                        {String(form.errors.startDate)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* #3. Quantities */}
                        <div className='accordion-item mb-3'>
                          <h2 className='accordion-header' id='flush-quantities'>
                            <button
                              className='accordion-button collapsed bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-quantities-collapse'
                              aria-expanded='false'
                              aria-controls='flush-quantities-collapse'
                            >
                              <span className='me-3'>
                                {(form.errors.qty || form.errors.minQty || form.errors.maxQty) && (
                                  <KTIcon iconName='information-2' className='text-danger fs-2' />
                                )}
                                {!form.errors.qty && !form.errors.minQty && !form.errors.maxQty && (
                                  <KTIcon iconName='like' className='text-success fs-2' />
                                )}
                              </span>
                              <div className='d-flex flex-column'>
                                <span className='fw-bold'>#3 Quantities</span>
                                <span className='mt-1 text-muted fw-normal fs-6'>
                                  {form.values.qty} ticket{form.values.qty === 1 ? '' : 's'} will be
                                  available
                                </span>
                              </div>
                            </button>
                          </h2>
                          <div
                            id='flush-quantities-collapse'
                            className='accordion-collapse collapse'
                            aria-labelledby='flush-quantities-collapse'
                            data-bs-parent='#ticketAccordion'
                          >
                            <div className='accordion-body pt-15'>
                              <div className='col col-xl-12 mb-15'>
                                <div className='d-flex justify-content-between'>
                                  <div className='d-flex flex-column h-25 w-100'>
                                    <label className='form-label fw-bolder'>Quantity</label>
                                    <span className='text-muted'>
                                      How many <span className='text-dark'>{form.values.name}</span>{' '}
                                      tickets do you want to make available?
                                    </span>
                                  </div>
                                  <div className='d-flex w-50'>
                                    <NumberInput name='qty' min={1} max={99999} />
                                  </div>
                                </div>
                                <ErrorMessage name='qty' component='div' className='text-danger' />
                              </div>

                              <div className='fv-row mb-10'>
                                <div className='row'>
                                  <div className='col col-xl-6'>
                                    <label className='form-label fw-bolder'>
                                      Minimum Qty Per Booking
                                    </label>
                                    <NumberInput name='minQty' min={1} max={100} />
                                    <ErrorMessage
                                      name='minQty'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </div>
                                  <div className='col col-xl-6'>
                                    <label className='form-label fw-bolder'>
                                      Maximum Qty Per Booking
                                    </label>
                                    <NumberInput name='maxQty' min={1} max={100} />
                                    <ErrorMessage
                                      name='maxQty'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* FORCE MINIMUM QUANTITY */}
                              <div className='separator separator-dashed'></div>
                              <div className='fv-row mt-10 mb-10'>
                                <div className='row'>
                                  <div className='col'>
                                    <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                                      <Field
                                        className='form-check-input me-3'
                                        type='checkbox'
                                        name='forceMinQty'
                                      />

                                      <span className='form-check-label d-flex flex-column align-items-start'>
                                        <span className='fw-bolder fs-5 text-dark mb-0'>
                                          Enforce Minimum Qty
                                        </span>
                                        <span className='fw-normal text-muted'>
                                          Make it compulsory for patrons to book the minimum Qty
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* FORCE MINIMUM QUANTITY form */}
                            </div>
                          </div>
                        </div>

                        {/* $4. Checkpoints  */}

                        <div className='accordion-item mb-3'>
                          <h2 className='accordion-header' id='flush-checkpoints'>
                            <button
                              className='accordion-button collapsed bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-checkpoints-collapse'
                              aria-expanded='false'
                              aria-controls='flush-checkpoints-collapse'
                            >
                              <span className='me-3'>
                                {form.errors.checkpointSettings && (
                                  <KTIcon iconName='information-2' className='text-danger fs-2' />
                                )}
                                {!form.errors.checkpointSettings && (
                                  <KTIcon iconName='like' className='text-success fs-2' />
                                )}
                              </span>
                              <div className='d-flex flex-column'>
                                <span className='fw-bold'>#4 Check points</span>
                                {/* count quantities in each checkpointSettings */}
                                <span className='mt-1 text-muted fw-normal fs-6'>
                                  {form.values.checkpointSettings &&
                                    form.values.checkpointSettings.reduce(
                                      (acc, item) => acc + item.qty,
                                      0
                                    )}{' '}
                                  checkpoints assigned to this ticket
                                </span>
                              </div>
                            </button>
                          </h2>
                          <div
                            id='flush-checkpoints-collapse'
                            className='accordion-collapse collapse'
                            aria-labelledby='flush-checkpoints-collapse'
                            data-bs-parent='#ticketAccordion'
                          >
                            <div className='accordion-body pt-15'>
                              <div className='fv-row mb-10'>
                                <div className='row'>
                                  <div className='col'>
                                    <label className='form-label fw-bolder'>Checkpoints</label>
                                    <span className='text-muted d-flex mb-5'>
                                      Checkpoints specify where tickets will be scanned at your
                                      event. You can add multiple checkpoints to a ticket. e.g. You
                                      can assign a parking checkpoint and a gate checkpoint to a
                                      ticket, making this ticket valid for both parking and gate
                                      access.
                                    </span>
                                    <div className='d-flex flex-wrap'>
                                      <FieldArray name='checkpointSettings'>
                                        {({push, remove, form}) => {
                                          return (
                                            <>
                                              {checkpointSettings &&
                                                checkpointSettings.map(
                                                  (item: CheckpointSettingsModel, index) => (
                                                    <div key={`cp-${index}`}>
                                                      <CheckpointSettingsItem
                                                        name={`checkpointSettings[${index}]`}
                                                        checkpointSetting={item}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                            </>
                                          )
                                        }}
                                      </FieldArray>
                                    </div>
                                    <ErrorMessage
                                      name='checkpointSettings'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* #5. Pricing */}
                        {currentEvent.sellTickets && (
                          <>
                            <div className='accordion-item mb-3'>
                              <h2 className='accordion-header' id='flush-pricing'>
                                <button
                                  className='accordion-button collapsed bg-light fs-4 text-dark'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#flush-pricing-collapse'
                                  aria-expanded='false'
                                  aria-controls='flush-pricing-collapse'
                                >
                                  <span className='me-3'>
                                    {form.errors.price && (
                                      <KTIcon
                                        iconName='information-2'
                                        className='text-danger fs-2'
                                      />
                                    )}
                                    {!form.errors.price && (
                                      <KTIcon iconName='like' className='text-success fs-2' />
                                    )}
                                  </span>
                                  <div className='d-flex flex-column'>
                                    <span className='fw-bold'>#5 Pricing </span>
                                    {form.values.price > 0 && (
                                      <span className='mt-2 text-muted fw-normal fs-6'>
                                        {form.values.enableVariablePrice === true
                                          ? 'Variable Pricing. Min '
                                          : ''}
                                        {currentEvent.currency} {formatNumber(form.values.price)}
                                      </span>
                                    )}
                                  </div>
                                </button>
                              </h2>
                              <div
                                id='flush-pricing-collapse'
                                className='accordion-collapse collapse'
                                aria-labelledby='flush-pricing-collapse'
                                data-bs-parent='#ticketAccordion'
                              >
                                <div className='accordion-body pt-15'>
                                  {/* FORCE MINIMUM QUANTITY */}
                                  <div className='fv-row '>
                                    <div className='row'>
                                      <div className='col'>
                                        <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                                          <Field
                                            className='form-check-input me-3'
                                            disabled
                                            type='checkbox'
                                            name='enableVariablePrice'
                                          />

                                          <span className='form-check-label d-flex flex-column align-items-start'>
                                            <span className='fw-bolder fs-5 text-dark mb-0'>
                                              Enable Variable Pricing{' '}
                                              <span className='fw-normal fs-5 text-dark mb-0'>
                                                (coming soon)
                                              </span>
                                            </span>
                                            <span className='fw-normal text-muted'>
                                              This allows patrons to pay anything they want. Works
                                              best for fundraising.
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='separator separator-dashed mb-10'></div>
                                  {/* FORCE MINIMUM QUANTITY form */}

                                  <div className='fv-row mb-10'>
                                    <div className='row'>
                                      <div className='col col-xl-12 mb-10'>
                                        <div className='d-flex justify-content-between'>
                                          <div className='d-flex flex-column w-100'>
                                            <label className='form-label fw-bolder'>
                                              {form.values.enableVariablePrice === true
                                                ? 'Min'
                                                : ''}{' '}
                                              Ticket Price
                                            </label>
                                            <span className='text-muted'>
                                              {form.values.enableVariablePrice === true
                                                ? 'Minimum amount that can be paid by patron/guest'
                                                : 'Amount paid by patron/guest'}
                                            </span>
                                          </div>
                                          <div className='d-flex w-50'>
                                            <div className='input-group'>
                                              <div className='input-group-text'>
                                                {currentEvent.currency}
                                              </div>
                                              <Field
                                                type='number'
                                                className='form-control form-control text-end'
                                                name='price'
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                  handlePriceChange(form, e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          name='price'
                                          component='div'
                                          className='text-danger'
                                        />
                                      </div>
                                    </div>

                                    {/*  */}
                                    <div className='row'>
                                      <div className='d-flex justify-content-between mb-5'>
                                        <label className='form-label '>
                                          Skybookings fees ({appSettings.fee}% + VAT)
                                        </label>
                                        <label className='form-label fw-bolder'>
                                          {currentEvent.currency}{' '}
                                          {formatNumber(payoutCalculation.feeAmount)}
                                        </label>
                                      </div>
                                    </div>

                                    {/* GATEWAY FEE AMOUNT */}
                                    <div className='row'>
                                      <div className='d-flex justify-content-between mb-5'>
                                        <label className='form-label '>
                                          Online Payment Fees (Paystack) (
                                          {appSettings.gatewayFeePercentage}% +{' R'}
                                          {appSettings.gatewayFee} )
                                        </label>
                                        <label className='form-label fw-bolder'>
                                          {currentEvent.currency}{' '}
                                          {formatNumber(payoutCalculation.gatewayFeeAmount)}
                                        </label>
                                      </div>
                                    </div>

                                    <div className='row'>
                                      <div className='d-flex justify-content-between mb-5'>
                                        <label className='form-label '>Total payout amount</label>
                                        <label className='form-label fw-bolder'>
                                          {currentEvent.currency}{' '}
                                          {formatNumber(payoutCalculation.payoutAmount)}
                                        </label>
                                      </div>
                                    </div>

                                    {currentEvent.billingModel === 'managed' && (
                                      <>
                                        <div className='row'>
                                          <div className='col'>
                                            <div className='separator w-25 border mb-5'></div>
                                          </div>
                                        </div>

                                        {/* FEES */}
                                        <div className='row'>
                                          <div className='d-flex justify-content-between mb-5'>
                                            <label className='form-label '>
                                              Amount Payable to your client (
                                              {100 - appSettings.fee - currentEvent.commission}%)
                                            </label>
                                            <label className='form-label fw-bolder'>
                                              {currentEvent.currency}{' '}
                                              {formatNumber(payoutCalculation.clientAmount)}
                                            </label>
                                          </div>

                                          {/* YOUR SHARE */}
                                          <div className='d-flex justify-content-between mb-5'>
                                            <label className='form-label '>
                                              Amount retained by you ({currentEvent.commission}%)
                                            </label>
                                            <label className='form-label fw-bolder'>
                                              {currentEvent.currency}{' '}
                                              {formatNumber(payoutCalculation.finalAmount)}
                                            </label>
                                          </div>
                                          <div className='d-flex'>
                                            {/* give a notice  */}
                                            <span className='text-muted fs-6'>
                                              <span className='fas fa-info-circle'> </span>
                                              All amounts payable will be paid to the bank account
                                              you specified when registering, and you can forward
                                              payments to your client. Note that your client will
                                              also have access to this information.
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                  <div className='modal-footer'>
                    <span className='fw-bolder text-danger'>
                      {Object.keys(form.errors).length > 0 &&
                        String(
                          form.errors.name ||
                            form.errors.description ||
                            form.errors.startDate ||
                            form.errors.endDate ||
                            form.errors.minQty ||
                            form.errors.maxQty ||
                            form.errors.checkpointSettings ||
                            form.errors.price
                        )}
                    </span>
                    <button
                      type='button'
                      className='btn btn-light btn-active-light-primary me-3'
                      data-bs-dismiss='modal'
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={isSubmittingClient}
                      label='Save Ticket'
                    />
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TicketEditor
