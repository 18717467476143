import axios from "axios"

export const EVENT_ENDPOINT = `/events`
export const SCANNER_ENDPOINT = `scanners`

export function _postScanner(eventId, scanner) {
    return axios.post(`${EVENT_ENDPOINT}/${eventId}/${SCANNER_ENDPOINT}`, scanner)
}

export function _patchScanner(eventId, scannerId, scanner) {
    return axios.patch(`${EVENT_ENDPOINT}/${eventId}/${SCANNER_ENDPOINT}/${scannerId}`, scanner)
}

export function _getScanner(eventId: string, scannerId: any) {
    return axios.get(`${EVENT_ENDPOINT}/${eventId}/${SCANNER_ENDPOINT}/${scannerId}`)
}

export function _getScanners(eventId: string, query: any) {
    return axios.get(`${EVENT_ENDPOINT}/${eventId}/${SCANNER_ENDPOINT}/`, { params: { ...query } })
}

export function _deleteScanner(eventId: string, scannerId: string) {
    return axios.delete(`${EVENT_ENDPOINT}/${eventId}/${SCANNER_ENDPOINT}/${scannerId}`)
}   