import * as Yup from 'yup'

interface IFileValidation {
    message?: string
    maxSize?: number
    minSize?: number
    types?: string[]
}

/**
 * Validates file size and type.  Defaults to 5MB and application/pdf, image/jpeg, image/png, image/gif
 * @param args <IFileValidation>
 * @returns
 */
// const fileValidation = (args: IFileValidation) => {
//     const { message, maxSize, minSize, types } = args
//     return Yup.mixed()
//         .required(message ? message : 'File is required')
//         .test('fileSize', 'File size is too large', (value: any) => {
//             if (!value) {
//                 return true // No file, no validation needed
//             }

//             const maxSize = 5 * 1024 * 1024 // 5 MB in bytes
//             return value.size <= maxSize
//         })
//         .test('fileType', 'Only the following files allowed: pdf, .jpg, .png', (value) => {
//             // Define the allowed file types
//             const file = value as File
//             const allowedTypes = types
//                 ? types
//                 : ['application/pdf', 'image/jpeg', 'image/png', 'image/gif']
//             return !file || allowedTypes.includes(file.type)
//         })
// }

const registrationSchema = [
    Yup.object({}), // step 0
    Yup.object({
        accountPlan: Yup.string().required().label('Account Plan'),
        accountName: Yup.string().required().label('Account Name'),
        accountHandle: Yup.string()
            .test('handle', 'only alphanumeric values and _ allowed', (val) => {
                if (!val) return true
                const pattern = /^[a-zA-Z0-9_]+$/
                return pattern.test(val)
            })
            .required()
            .label('handle'),
    }),

    Yup.object({
        userFirstName: Yup.string().required().label('First Name'),
        userLastName: Yup.string().required().label('Last Name'),
        userDisplayName: Yup.string().required().label('Display Name'),
        userEmail: Yup.string().required().email('Wrong email format').label('Email Address'),
        userPassword: Yup.string().required().label('Password'),
        userPasswordConfirm: Yup.string().oneOf(
            [Yup.ref('userPassword')],
            "Password and Confirm Password didn't match"
        ),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept terms & Conditions')
            .required('Accept Terms & Conditions is required'),
    }),

    Yup.object({
        accountBankName: Yup.string().required().label('Bank Name'),
        accountBankAccountHolder: Yup.string().required().label('Account Holder'),
        accountBankAccountNumber: Yup.number().required().label('Bank Account'),
        accountBankBranch: Yup.number().label('Branch Code'),
    }),

    Yup.object({
        accountFileId: Yup.string().required(),
        accountFileBankLetter: Yup.string().required(),
        accountFileProofOfAddress: Yup.string().required()
    }),

    Yup.object({
        nameOnCard: Yup.string().required().label('Name On Card'),
        cardNumber: Yup.string().required().label('Card Number'),
        cardExpiryMonth: Yup.string().required().label('Expiration Month'),
        cardExpiryYear: Yup.string().required().label('Expiration Year'),
        cardCvv: Yup.string().required().label('CVV'),
    }),
]


export { registrationSchema }
