import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Payout Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Pleas enter your bank details for payouts. Please note we will verify this information.
          For more information please <a href='https://wa.me/27736898968'>WhatsApp</a> Us
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Bank Name</label>

        <Field
          as='select'
          name='accountBankName'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='Absa Bank'>Absa Bank</option>
          <option value='African Bank'>African Bank</option>
          <option value='Bidvest'>Bidvest</option>
          <option value='Capitec Bank'>Capitec Bank</option>
          <option value='Discovery Bank'>Discovery Bank</option>
          <option value='First National Bank'>First National Bank</option>
          <option value='Grindrod Bank'>Grindrod Bank</option>
          <option value='Imperial Bank'>Imperial Bank</option>
          <option value='Investec Bank'>Investec Bank</option>
          <option value='Mercantile Bank'>Mercantile Bank</option>
          <option value='Nedbank'>Nedbank</option>
          <option value='Sasfin Bank'>Sasfin Bank</option>
          <option value='Standard Bank'>Standard Bank</option>
          <option value='Ubank'>Ubank</option>
          <option value='Tyme Bank'>Tyme Bank</option>
        </Field>
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountBankName' />
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Account Holder</label>

        <Field name='accountBankAccountHolder' className='form-control form-control-lg  ' />
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountBankAccountHolder' />
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Account Number</span>
        </label>

        <Field
          as='input'
          name='accountBankAccountNumber'
          className='form-control form-control-lg  '
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='accountBankAccountNumber' />
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>
    </div>
  )
}

export {Step3}
