/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {ScannerModel} from '../core/scannerStore'
import {Button, Modal} from 'react-bootstrap'

type Props = {
  scanner: ScannerModel
  eventId: string
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
  onEditScanner: (scannerId: string) => void
  onDeleteScanner: (scannerId: string) => void
}

const ScannerOptions: FC<Props> = ({
  scanner,
  eventId,
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
  onEditScanner,
  onDeleteScanner,
}) => {
  const [scannerLink, setScannerLink] = useState<string>('')
  const [showShareScanner, setShowShareScanner] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleScanTickets = async (scannerId) => {
    if (!eventId || !scannerId) return

    // open scanner in new tab
    navigate(`/event/${eventId}/scanners/${scannerId}/scan`, {replace: true})
  }

  const handleCopy = (e) => {
    navigator.clipboard.writeText(scannerLink)

    // show copy effect

    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const handleShareScanner = async (scannerId) => {
    if (!eventId || !scannerId) return
    setShowShareScanner(true)
  }

  useEffect(() => {
    if (!eventId || !scanner.id) return

    setScannerLink(`${window.location.origin}/event/${eventId}/scanners/${scanner.id}/scan`)

    //  eslint-disable-next-line
  }, [])

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <KTIcon
          iconName='setting-4'
          iconType='outline'
          className={clsx('theme-light-hide me-3', toggleBtnIconClass)}
        />
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item bg-hover-light px-3 my-0'>
          <div
            className={clsx('menu-link text-end px-3 py-3')}
            onClick={() => {
              handleScanTickets(scanner.id)
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='barcode' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Scan Tickets</span>
          </div>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item bg-hover-light px-3 my-0'>
          <div
            className={clsx('menu-link text-end px-3 py-3')}
            onClick={() => {
              handleShareScanner(scanner.id)
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='exit-right-corner' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Share Scanner</span>
          </div>
        </div>
        {/* end::Menu item */}

        <div className='separator my-2'></div>

        {/* begin::Menu item */}
        <div className='menu-item bg-hover-light px-3 my-0'>
          <div
            className={clsx('menu-link px-3 py-3')}
            onClick={() => {
              onEditScanner(scanner.id)
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='pencil' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Edit</span>
          </div>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item bg-hover-light px-3 my-0'>
          <div
            className={clsx('menu-link px-3 py-3')}
            onClick={() => {
              onDeleteScanner(scanner.id)
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='trash' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Delete</span>
          </div>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      {/* scanner link */}
      <Modal show={showShareScanner} centered onHide={() => setShowShareScanner(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            <label className='form-label'>Copy Link</label>
            <div className='mb-3 d-flex'>
              <input
                type='text'
                className='form-control me-2'
                value={scannerLink}
                readOnly={true}
              />

              <button
                type='button'
                onClick={handleCopy}
                className={`btn ${copied ? 'btn-success' : 'btn-light-info'}`}
                id='basic-addon2'
              >
                {}
                <KTIcon iconType='outline' iconName={copied ? 'check' : 'copy'} className='fs-2' />
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowShareScanner(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ScannerOptions}
