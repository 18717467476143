import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field, Formik, Form, FormikValues} from 'formik'
import SubmitButton from '../../../../_components/SubmitButton'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useLocation} from 'react-router-dom'
import {useAuthStore} from '../core/authStore'

const ResetPassword = () => {
  // validation Schma
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(3).max(12).required().label('Password'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'Repeat your password'),
  })

  const initialValues = {
    password: '',
    passwordConfirm: '',
    token: '',
  }

  interface IResetPassword {
    password: string
    passwordConfirm: string
    token?: string
  }

  const location = useLocation()
  const [resetFailed, setResetFailed] = useState<boolean | undefined>(undefined)
  const {resetPassword} = useAuthStore()
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleSubmit = (values: IResetPassword, formik: FormikValues) => {
    // get token from the url
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token') || ''

    // prepare formik functions
    const {setSubmitting} = formik

    setSubmitting(true)
    setResetFailed(undefined)

    resetPassword(values.password, token)
      .then((response) => {
        if (response === true) {
          setResetFailed(false)
        } else {
          setResetFailed(true)
        }
      })

      .catch((err) => {
        setResetFailed(true)
      })

      .finally(() => {
        setSubmitting(false)
      })
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(form: FormikValues) => {
        return (
          <Form
            noValidate
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            id='kt_login_password_reset_form'
          >
            {/* show form is reset has not failed or is undefined  */}
            {resetFailed === false ? (
              <>
                <div className='text-center mb-10'>
                  {/* begin::Title */}
                  <h1 className='text-dark fw-bolder mb-3'>Password reset done</h1>
                  {/* end::Title */}

                  {/* begin::Link */}
                  <div className='text-gray-500 fw-semibold fs-6'>
                    Sign in with your new password
                  </div>
                  {/* end::Link */}
                </div>
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <Link to='/auth/login'>
                    <button className='btn btn-success'>Sign In</button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className='text-center mb-10'>
                  {/* begin::Title */}
                  <h1 className='text-dark fw-bolder mb-3'>Enter your new password</h1>
                  {/* end::Title */}

                  {/* begin::Link */}
                  <div className='text-gray-500 fw-semibold fs-6'>
                    Please enter your new password below.
                  </div>
                  {/* end::Link */}
                </div>
                {resetFailed !== undefined && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      Sorry, it looks like there was a problem resetting your password
                    </div>
                  </div>
                )}

                {/* begin::Form group Password */}
                <div className='fv-row mb-10' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='position-relative mb-3'>
                      <Field
                        type='password'
                        placeholder='Password'
                        name='password'
                        autoComplete='off'
                        className='form-control form-control-lg'
                      />
                    </div>
                    {/* begin::Meter */}
                    <div
                      className='d-flex align-items-center mb-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password' />
                  </div>
                  <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                  <Field
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    name='passwordConfirm'
                    className='form-control form-control-lg '
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='passwordConfirm' />
                  </div>
                </div>
                {/* end::Form group */}

                {/* begin::submit */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <SubmitButton
                    isSubmitting={form.isSubmitting}
                    isValid={form.isValid}
                    label='Reset Password'
                  />
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-light'
                      disabled={form.isSubmitting || !form.isValid}
                    >
                      Cancel
                    </button>
                  </Link>{' '}
                </div>
                {/* end::Submit group */}
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export {ResetPassword}
