import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuthStore} from '../core/authStore'

const initialValues = {}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function VerifyEmail() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const {verifyEmail} = useAuthStore()

  useEffect(() => {
    //1. get token from url
    setLoading(true)

    const token = new URLSearchParams(window.location.search).get('token')
    if (!token) {
      // token is not present, fire up an error
      setHasErrors(true)
    } else {
      verifyEmail(token)
        .then((response) => {
          if (response === true) {
            setHasErrors(false)
          } else {
            setHasErrors(false)
          }
        })
        .catch((err) => {
          console.log('err', err)
          setHasErrors(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    // 2. check if token is valid

    // 3. update state
  }, [verifyEmail])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {},
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Email Verification</h1>
        {/* end::Title */}
      </div>

      {/* begin:: show progress bar if loading is true */}
      {loading === true && (
        <div className='d-flex flex-wrap mb-lg-15 alert alert-primary'>
          <div className='alert-text font-weight-bold'>
            Please wait, we are sending password reset link to your email...
          </div>
        </div>
      )}
      {/* end:: show progress bar if loading is true */}

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, it looks like something went wrong. Resetting your password can resolve this
            issue.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <>
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Your email was successfully verified. You can sign in.</div>
          </div>
        </>
      )}

      {hasErrors !== null && (
        <>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login' className='btn btn-primary me-4'>
              <span className='indicator-label'>Sign in</span>
            </Link>
            <Link to='/auth/forgot-password' className='btn btn-danger'>
              <span className='indicator-label'>Reset Password</span>
            </Link>
          </div>
        </>
      )}

      {/* end::Title */}
    </form>
  )
}
